import { createSlice } from "@reduxjs/toolkit";

import {
  createVehicleService,
  editVehicleService,
  getVehicleTypeListCall,
  inspectionCall,
  vehicleCheckCall,
  vehicleDataCall,
  vehicleImageCall,
  vehicleRemove,
  vehicleRemoveService,
  vehicleSearchCall,
  vehicleServiceTypeCall,
} from "../../utils/services/vehicleService";

import notify from "devextreme/ui/notify";
import BaseHelper from "../../helper/BaseHelper";
import { styled } from "@mui/material";
export const slice = createSlice({
  name: "vehicle",
  initialState: {
    vehicleData: null,
    vehicleSearchData: null,
    vehicleInspectionData: null,
    trailerInspectionData: null,
    vehicleTypeListData: null,
    vehicleCheckData: null,
    vehicleServiceTypeList: null,
    filter: {},
    isLoading: false,
  },

  reducers: {
    setVehicleInspectionData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleInspectionData = payload;
    },
    setTrailerInspectionData: (state, { payload }) => {
      // console.log(payload);
      state.trailerInspectionData = payload;
    },
    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setVehicleSearchData: (state, { payload }) => {
      // console.log(payload);
      state.vehicleSearchData = payload;
    },
    setVehicleData: (state, { payload }) => {
      // console.log(payload);
      state.vehicleData = payload;
    },
    setVehicleTypeListData: (state, { payload }) => {
      // console.log(payload);
      state.vehicleTypeListData = payload;
    },
    setVehicleCheckData: (state, { payload }) => {
      // console.log(payload);
      state.vehicleCheckData = payload;
    },
    setVehicleServiceType: (state, { payload }) => {
      //console.log(payload.length);
      if (payload.length > 0) {
        payload.unshift({
          id: 0,
          code: "",
          name: "Бүгд",
          note: null,
          style: null,
        });
        //console.log(payload);
        state.vehicleServiceTypeList = payload;
      }

      //  console.log(state.vehicleServiceTypeList);
    },
    setCreateVehicle: (state, { payload }) => {
      // console.log(payload);
      state.vehicleData.unshift(payload);
    },
    setEditVehicle: (state, { payload }) => {
      // console.log(payload);
      let listCopy = [...state.vehicleData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.garage_no = payload.garage_no;
          item.cabin_no = payload.cabin_no;
          item.color_name = payload.color_name;
          item.company_name = payload.company_name;
          item.company_regnum = payload.company_regnum;
          item.mark_name = payload.mark_name;
          item.model_name = payload.model_name;
        }
      });
    },
    setRemoveVehicle: (state, { payload }) => {
      // console.log(payload);

      let index = state.vehicleData.findIndex(({ id }) => id === payload);
      state.vehicleData.splice(index, 1);
    },
  },
});

export const {
  setVehicleInspectionData,
  setTrailerInspectionData,
  setLoading,
  setVehicleSearchData,
  setVehicleData,
  setCreateVehicle,
  setRemoveVehicle,
  setEditVehicle,
  setVehicleTypeListData,
  setVehicleCheckData,
  setFilter,
  setVehicleServiceType,
} = slice.actions;

export const getVehicleCheckFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await vehicleCheckCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setVehicleCheckData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      /// console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getVehicle = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await vehicleDataCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setVehicleData(responseData.data));
      // dispatch(setLoading(false));

      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setVehicleData(1));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setVehicleData(1));
    console.log(error);
  }
};
export const getVehicleServiceType = (data) => async (dispatch, getState) => {
  try {
    const responseData = await vehicleServiceTypeCall(data);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setVehicleServiceType(responseData.data));
      // dispatch(setLoading(false));

      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setVehicleServiceType(1));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setVehicleData(1));
    console.log(error);
  }
};
export const getVehicleTypeListFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getVehicleTypeListCall();

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setVehicleTypeListData(responseData.data));

      //  dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export const createVehicle = (data, type) => async (dispatch, getState) => {
  try {
    const responseData = await editVehicleService(data);
    //  console.log(responseData);
    if (responseData.status.code === 200) {
      if (type === 2) {
        // dispatch(setEditVehicle(responseData.data[0]));
        dispatch(getVehicle({}));
      } else {
        // dispatch(setCreateVehicle(responseData.data[0]));
        dispatch(getVehicle({}));
      }

      BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const removeVehicleList = (data) => async (dispatch, getState) => {
  try {
    const responseData = await vehicleRemoveService(data);
    if (responseData.status.code === 200) {
      dispatch(setRemoveVehicle(data.id));
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleSearch = (plate_no) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const plateNo = { plate_no: plate_no };
    const responseData = await vehicleSearchCall({ plateNo });
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log("ffffffffffffffff");
      // console.log(responseData.data);
      dispatch(setVehicleSearchData(responseData.data));
      dispatch(setLoading(false));

      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setLoading(false));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setLoading(false));

    console.log(error);
  }
};
export const getVehicleInspectionData =
  (data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      // console.log(data);
      const plate_no = { plate_no: data };
      const responseData = await inspectionCall({ plate_no });
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setLoading(false));
        dispatch(setVehicleInspectionData(responseData.data));
      } else {
        // BaseHelper.nodifyFun(responseData.status.message, "error");
      }
      //console.log(alert);
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };
export const getTrailerInspectionData =
  (data) => async (dispatch, getState) => {
    try {
      const cabin_no = { cabin_no: data };
      const responseData = await inspectionCall({ cabin_no });
      if (responseData.success) {
        // console.log(responseData.data);
        // dispatch(setLoading(false));
        dispatch(setTrailerInspectionData(responseData.data));
      }
      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export default slice.reducer;
