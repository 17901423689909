import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  inspectorSetFun,
  removeRequestList,
  requestIsApprovedConfirm,
  requestIsCheckedConfirm,
  setFilter,
} from "../../../../store/reducers/requestDriver";
import { DataGridConfig } from "../../../../config";
import { blue } from "@mui/material/colors";
import { DropDownBox, TextArea, TextBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import BaseHelper from "../../../../helper/BaseHelper";
import { getUserListData } from "../../../../store/reducers/user/auth";
import { createDriver } from "../../../../store/reducers/driver";
const gridBoxDisplayExpr2 = (item) => {
  // console.log(item);
  return item
    ? `${item.lastname !== null ? item.lastname.substring(0, 1) : ""}.${
        item.firstname
      }`
    : "";
};
const RequestInData = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const requestDriverDataList = useSelector(
    (state) => state.requestDriver.requestData
  );
  const filter = useSelector((state) => state.requestDriver.filterAdmin);
  const userListData = useSelector((state) => state.auth.userListData);
  const [driverIdcode, setDriverIdcode] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isAproved, setIsApproved] = useState(false);
  const [isCheckedNote, setIsCheckedNote] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const dispatch = useDispatch();

  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState("");
  const [isGridBoxOpenedType, setIsGridBoxOpenedType] = useState(false);
  const gridColumns = ["lastname", "firstname"];
  const [typeDate, setTypeDate] = useState([
    {
      id: 1,
      name: "Дорж",
    },
    { id: 3, name: "Дондог" },
  ]);
  // console.log(typeDateGridBoxValue);
  const dataTypeRender = () => {
    return (
      <DataGrid
        dataSource={userListData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          // console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpenedType(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  useEffect(() => {
    if (requestDriverDataList == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(getRequest(filter));
    }
  }, [requestDriverDataList, filter, dispatch]);
  useEffect(() => {
    if (userListData == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(
        getUserListData({
          search_value: "",
          user_type_id: 12,
        })
      );
    }
  }, [userListData, dispatch]);

  useEffect(() => {
    //  console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getRequest(filter));
    }
  }, [filter, dispatch]);

  useEffect(() => {
    if (requestDriverDataList) {
      setFormData(requestDriverDataList);
      // gridRef.current.instance.endCustomLoading();
    }
  }, [requestDriverDataList]);

  //console.log(props);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    setIsChecked(false);
    setIsDelete(false);
    setTypeDateGridBoxValue("");

    // setFormEditData("");
  };

  const setStoreDriver = (data) => {
    gridRef.current.instance.refresh();
    setIsPop(false);
  };
  function deleteRecords() {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();
    getSelectedData.map((e) => dispatch(removeRequestList(e.id)));
    setIsDelete(false);

    gridRef.current.instance.refresh();
  }
  // console.log(logedUser);
  //console.log(BaseHelper.permitionCheck(39, 73, "is_update", logedUser));
  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    //  gridRef.current.instance.refresh();
                    dispatch(getRequest({ is_approved: 0, is_requested: 1 }));
                    //gridRef.current.instance.endCustomLoading();
                    //   gridRef.current.instance.beginCustomLoading();
                    // console.log(gridRef.current.instance.getSelectedRowsData());
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>
            {/* 
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"check"}
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      39,
                      73,
                      90,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  //  style={{ "background-color": "yellow", color: "white" }}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      setIsChecked1(true);

                      //setIsChecked(true);
                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах жолооч сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Байцаагчид хувиарлах"
                />
              </Box>
            </Item> */}
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      39,
                      73,
                      93,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  icon={"check"}
                  style={{ "background-color": "orange" }}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (getSelectedData.length > 0) {
                      if (getSelectedData[0].is_checked === 1) {
                        notify(
                          {
                            message: "Байцаагч баталсан байна!!!",
                            width: 450,
                          },
                          "error"
                        );
                      } else if (
                        logedUser.id !== getSelectedData[0].checked_by
                      ) {
                        notify(
                          {
                            message:
                              "Та уг жолоочийг батлах эрхгүй өөр байцаагчид хувиарласан байна   !!!",
                            width: 450,
                          },
                          "error"
                        );
                      } else {
                        setIsChecked(true);
                      }
                      //setIsChecked(true);
                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах жолооч сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Байцаагч батлах"
                />
              </Box>
            </Item> */}

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      39,
                      73,
                      94,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  icon={"check"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      // if (getSelectedData[0].is_checked !== 1) {
                      //   notify(
                      //     {
                      //       message: "Байцаагч батлаагүй байна!!!",
                      //       width: 450,
                      //     },
                      //     "error"
                      //   );
                      // } else {
                      setIsApproved(true);
                      // }

                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах жолооч сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Мэргэжилтэн батлах"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={(e) => {
                    // console.log(e.row);

                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      setIsDelete(true);
                    } else {
                      notify(
                        {
                          message: "Устгах хүсэлтээ сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    //gridRef.current.instance.deselectAll();
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item>
            <Item location="after">
              <Box>
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
            {/* <Item location="center">
              <Box sx={{ mb: 2 }}>
                <Typography variant="h3">
                  Тээврийн хэрэгслийн хүсэлтүүд жагсаалт
                </Typography>
              </Box>
            </Item> */}
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}

            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={420}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            onSelectionChanged={onSelectionChanged}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={false}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "90%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                  <Item dataField="official_letter_number" />
                  <Item dataField="official_letter_date" />
                </Item>
                <Item
                  itemType="group"
                  caption="Жолоочийн мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="surname" />
                  <Item dataField="lastname" />
                  <Item dataField="firstname" />
                  <Item dataField="register" />
                  <Item dataField="gender" />

                  <Item dataField="eye_color_name" />
                  <Item dataField="blood_type_name" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="dl_number" />
                  <Item dataField="dll" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="pdl_number" />
                  <Item dataField="class_b" />
                  <Item dataField="class_c" />
                  <Item dataField="class_e" />
                  <Item dataField="class_d" />
                  <Item dataField="class_inter_passenger" />
                  <Item dataField="class_inter_transport" />
                  <Item dataField="class_tourist" />
                  <Item dataField="class_danger" />
                </Item>
                <Item dataField="created" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                width={130}
                format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"ААНБ-н регистер"}
              />
              <Column
                dataField="company_name"
                format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"ААНБ-н нэр"}
              />
            </Column>
            <Column
              dataField="official_letter_number"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              format="fixedPoint"
              caption={"Албан бичиг дугаар"}
            />
            <Column
              dataField="official_letter_date"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              format="fixedPoint"
              caption={"Албан бичиг огноо"}
            />

            {/* <Column
              allowSorting={true}
              width={200}
              dataField="is_checked"
              caption="Байцаагч төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Байцаагч баталгаажуулсан"
                      size="small"
                      color="success"
                    />
                  );
                } else if (p.value === 0 && p.data.checked_by === null) {
                  return <Chip label="Байцаагчид хувиарлаагүй" size="small" />;
                } else if (p.data.checked_by !== null && p.value === 0) {
                  return (
                    <Chip
                      label="Байцаагчид хувиарласан"
                      size="small"
                      color="warning"
                    />
                  );
                } else {
                  return (
                    <span className="status status-warning">{p.value}</span>
                  );
                }
              }}
            /> */}

            <Column
              dataField="surname"
              format="fixedPoint"
              caption={"Ургийн овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            {/* <Column
              dataField="checked"
              format="fixedPoint"
              caption={"Байцаагч"}
            /> */}
            <Column
              dataField="lastname"
              format="fixedPoint"
              caption={"Овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="firstname"
              format="fixedPoint"
              caption={"Нэр"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="register"
              format="fixedPoint"
              caption={"Регистер"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="gender"
              format="fixedPoint"
              caption={"Хүйс"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="eye_color_name"
              format="fixedPoint"
              caption={"Нүдний өнгө"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />

            <Column
              dataField="blood_type_name"
              format="fixedPoint"
              caption={"Цусны бүлэг"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column caption="Жолооны үнэмлэх">
              <Column
                dataField="dl_number"
                //format="fixedPoint"
                caption={"Үнэмлэх №"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="dll"
                //format="fixedPoint"
                caption={"Ангилал"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                calculateCellValue={(v) => {
                  var data = [];
                  if (v.dl_a !== null) {
                    data.push("A,");
                  }
                  if (v.dl_b !== null) {
                    data.push("B,");
                  }
                  if (v.dl_c !== null) {
                    data.push("C,");
                  }
                  if (v.dl_d !== null) {
                    data.push("D,");
                  }
                  if (v.dl_e !== null) {
                    data.push("E,");
                  }
                  // console.log(data);
                  return data.map((e) => e);
                }}
              />
              <Column
                dataField="is_deleted"
                format="fixedPoint"
                caption={"Жолоочийн төлөв"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  if (v.value === 0) {
                    return (
                      <Chip label="Идэвхтэй" size="small" color="success" />
                    );
                  } else {
                    return <Chip label="Идэвхгүй" size="small" />;
                  }
                }}
              />
            </Column>
            <Column position="center" caption="Мэрэгшсэн жолооны үнэмлэх">
              <Column
                dataField="pdl_number"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                //  format="fixedPoint"
                caption={"Үнэмлэх №"}
              />
              <Column
                dataField="class_b"
                //  format="fixedPoint"
                caption={"В хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_c"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                // format="date"
                caption={"С хүчинтэй огноо"}
                cellRender={(v) => {
                  // console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />

              <Column
                dataField="class_d"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                // format="fixedPoint"
                caption={"D хүчинтэй огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_e"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                // format="fixedPoint"
                caption={"E хүчинтэй огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_passenger"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                format="fixedPoint"
                caption={"ОУ-н зорчигч огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_transport"
                format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"ОУ-н тээвэр"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_tourist"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                format="fixedPoint"
                caption={"Жуулчин тээвэр"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_danger"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                format="fixedPoint"
                caption={"Аюултай тээвэр"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
            </Column>
            <Column
              dataField="created"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              format="fixedPoint"
              caption={"Хүсэлт илгээсэн"}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              caption={"Тайлбар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Бүртгэсэн огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
          {/* <LoadPanel
            visible={true}
            shadingColor="rgba(0,0,0,0.4)"
            shading={true}
            showPane={true}
            message="Уншиж байна түр хүлээнэ үү"
          /> */}
          {/* <Popup
            visible={isPop}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            position={"center"}
            title={
              isType === 2
                ? "Тээврийн хэрэгсэл засах"
                : "Тээврийн хэрэгсэл бүртгэх"
            }
            showTitle={true}
            width={900}
            //height={600}
          >
            sdfdsfds
          </Popup> */}

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked}
            showTitle={true}
            title="Байцаагч батлах хэсэг"
            onHiding={hideInfo}
            width={350}
            height={280}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "start" }}>
                Та уг жолоочийг батлах уу ?
              </Typography>
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <TextArea
                  height={100}
                  value={isCheckedNote}
                  // maxLength={this.state.maxLength}
                  // defaultValue={this.state.value}
                  autoResizeEnabled={true}
                  placeholder="Тайлбар оруулах...."
                  onKeyUp={(e) => {
                    //  if (e.event.keyCode === 13) {
                    e.event.preventDefault();
                    setIsCheckedNote(e.event.target.value);
                    //console.log(e.event.target.value);
                    //}
                    // console.log(e.event.target.value);
                  }}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (isCheckedNote !== "") {
                      // console.log(getSelectedData[0].id);
                      getSelectedData.map((e) =>
                        dispatch(requestIsCheckedConfirm(e.id, isCheckedNote))
                      );
                      setIsChecked(false);
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked1}
            showTitle={true}
            title="Байцаагчид хувиарлах хэсэг"
            onHiding={hideInfo}
            width={350}
            height={180}
          >
            <Stack direction={"column"} justifyContent={"start"} spacing={2}>
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <DropDownBox
                  height={30}
                  value={typeDateGridBoxValue}
                  opened={isGridBoxOpenedType}
                  valueExpr="id"
                  deferRendering={true}
                  displayExpr={gridBoxDisplayExpr2}
                  placeholder="Байцаагч сонгох..."
                  showClearButton={false}
                  dataSource={userListData}
                  onValueChanged={(e) => {
                    setTypeDateGridBoxValue(e.value);
                    // console.log(e.value);
                    // props.getFilters("source_id", e.value);
                    // if (e.value === null) {
                    //   props.getFilters(null);
                    // }
                    // console.log(e.value);
                  }}
                  onOptionChanged={(e) => {
                    // console.log(e.name);
                    if (e.name === "opened") {
                      setIsGridBoxOpenedType(e.value);
                      // props.filterCompany(null);
                    }
                  }}
                  contentRender={dataTypeRender}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked1(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (typeDateGridBoxValue !== "") {
                      // console.log(getSelectedData[0].id);
                      getSelectedData.map((e) =>
                        dispatch(
                          inspectorSetFun({
                            id: e.id,
                            inspector_id: typeDateGridBoxValue,
                          })
                        )
                      );
                      setIsChecked1(false);
                      setIsCheckedNote("");
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isAproved}
            showTitle={true}
            title="Мэргэжилтэн батлах хэсэг"
            onHiding={hideInfo}
            width={300}
            height={170}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              {/* <Typography sx={{ pt: 1, pb: 2, textAlign: "center" }}>
                {formEditData["company_vehicle_service_type_id"]}
              </Typography> */}
              {/* {formEditData !== "" ? (
                formEditData["company_vehicle_service_type_id"] > 1 ? (
                  //  formEditData.company_vehicle_service_type_id > 1 ? (
                  <Typography sx={{ pt: 1, pb: 2, textAlign: "center" }}>
                    Та уг жолоочийг батлах уу ?
                  </Typography>
                ) : ( */}
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <Stack
                  direction="row"
                  sx={{ pb: 0, mt: 1 }}
                  justifyContent={"space-evenly"}
                >
                  <Typography sx={{ textAlign: "center" }}>ID code</Typography>
                  <TextBox
                    value={driverIdcode}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setDriverIdcode(e.event.target.value);
                    }}
                    placeholder="Id code оруулна уу."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Stack>
              </Box>
              {/* )
              ) : (
                ""
              )} */}
              <Stack
                direction="row"
                sx={{ pb: 2, mt: 1 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsApproved(false);
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //console.log(getSelectedData[0].id);
                    //  console.log(getSelectedData);
                    //  if (driverIdcode !== "") {
                    // console.log(getSelectedData);

                    // getSelectedData.map((e) => {
                    //   const data = {
                    //     id: e.vehicle_id,
                    //     smart_bus_id: driverIdcode,
                    //   };

                    //   dispatch(requestIsApprovedConfirm(e.id));
                    //   dispatch(createVehicle(data));
                    // });

                    //console.log(getSelectedData[0].id);
                    // console.log(
                    //   getSelectedData[0]["company_vehicle_service_type_id"]
                    // );
                    if (formEditData !== "") {
                      if (formEditData["company_vehicle_service_type_id"] > 1) {
                        getSelectedData.map((e) => {
                          const data = {
                            id: e.driver_id,
                            tbox_id: null,
                          };
                          dispatch(requestIsApprovedConfirm(e.id)).then((e) => {
                            dispatch(createDriver(data));
                            setIsApproved(false);
                          });
                        });
                      } else {
                        if (driverIdcode !== "") {
                          getSelectedData.map((e) => {
                            const data = {
                              id: e.driver_id,
                              tbox_id: driverIdcode,
                            };
                            // console.log(e);
                            dispatch(requestIsApprovedConfirm(e.id)).then(
                              (e) => {
                                dispatch(createDriver(data));
                                setIsApproved(false);
                              }
                            );
                          });
                        }
                        // setIsApproved(false);
                        else {
                          notify(
                            {
                              message: "ID code оруулаагүй байна!!!",
                              width: 450,
                            },
                            "error"
                          );
                        }
                      }
                    }
                  }}
                />
              </Stack>
            </Stack>
            {/* <Stack direction={"column"} justifyContent={"start"}>
              <Typography sx={{ pt: 1, pb: 2, textAlign: "center" }}>
                Та уг жолоочийг батлах уу ?
              </Typography>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsApproved(false);
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //console.log(getSelectedData[0].id);
                    getSelectedData.map((e) =>
                      dispatch(requestIsApprovedConfirm(e.id)).then((e) => {
                        setIsApproved(false);
                      })
                    );
                  }}
                />
              </Stack>
            </Stack> */}
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isDelete}
            showTitle={false}
            title="Устгах"
            onHiding={hideInfo}
            width={250}
            height={100}
          >
            <Stack direction={"column"} justifyContent={"stretch"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "center" }}>
                Та уг хүсэлтийг устгах уу ?
              </Typography>
              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                />

                <Button
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    deleteRecords();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
        </Box>
      </Box>
    </>
  );
};

export default RequestInData;
