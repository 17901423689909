import { createSlice } from "@reduxjs/toolkit";

import {
  createEmployeService,
  inspectionCall,
  employeDataCall,
  employeImageCall,
  employeRemove,
  employeSearchCall,
  branchTreeListCall,
  branchAddCall,
  branchDeleteCall,
  employeTrainingListCall,
  employeTrainingEditCall,
  employeTrainingDeleteCall,
  employeTrainingTypeEditCall,
  employeTrainingTypeDeleteCall,
  employeTrainingTypeListCall,
} from "../../utils/services/employeService";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
import { employeTreeListCall } from "../../utils/services/employeService";
import BaseHelper from "../../helper/BaseHelper";
export const slice = createSlice({
  name: "employe",
  initialState: {
    employeTreeListData: null,
    employeData: null,
    employeSearchData: null,
    employeTrainingListData: null,
    employeTrainingTypeListData: null,

    branchTreeListData: null,
    filter: {},
    branchFilter: {},
    isLoading: false,
  },

  reducers: {
    setBranchTreeListData: (state, { payload }) => {
      // console.log(payload);
      state.branchTreeListData = payload;
    },
    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setBranchFilter: (state, { payload }) => {
      //console.log(payload);
      state.branchFilter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setEmployeSearchData: (state, { payload }) => {
      // console.log(payload);
      state.employeSearchData = payload;
    },
    setEmployeTrainingTypeListData: (state, { payload }) => {
      // console.log(payload);
      state.employeTrainingTypeListData = payload;
    },
    setEmployeTrainingListData: (state, { payload }) => {
      // console.log(payload);
      state.employeTrainingListData = payload;
    },
    setEmployeData: (state, { payload }) => {
      // console.log(payload);
      state.employeData = payload;
    },
    setEmployeTreeListData: (state, { payload }) => {
      // console.log(payload);
      state.employeTreeListData = payload;
    },
    setCreateEmploye: (state, { payload }) => {
      // console.log(payload);
      state.employeData.unshift(payload);
    },
    setEditEmploye: (state, { payload }) => {
      // console.log(payload);
      let listCopy = [...state.employeData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.phone_number = payload.phone_number;
        }
      });
    },
    setRemoveEmploye: (state, { payload }) => {
      // console.log(payload);

      let index = state.employeData.findIndex(({ id }) => id === payload);
      state.employeData.splice(index, 1);
    },
  },
});

export const {
  setLoading,
  setEmployeSearchData,
  setEmployeData,
  setCreateEmploye,
  setRemoveEmploye,
  setFilter,
  setEditEmploye,
  setEmployeTreeListData,
  setBranchTreeListData,
  setBranchFilter,
  setEmployeTrainingListData,
  setEmployeTrainingTypeListData,
} = slice.actions;

export const employeTrainingListFun = () => async (dispatch, getState) => {
  try {
    const responseData = await employeTrainingListCall();
    //console.log("ttgtttttttttt");
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setEmployeTrainingListData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
    } else {
      dispatch(setEmployeTrainingListData(null));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const employeTrainingEditFun = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const responseData = await employeTrainingEditCall(data);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setLoading(false));
      dispatch(employeTrainingListFun());
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      dispatch(setLoading(false));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const employeTrainingDeleteFun =
  (data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const responseData = await employeTrainingDeleteCall(data);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setLoading(false));
        dispatch(employeTrainingListFun());
        BaseHelper.nodifyFun(responseData.status.message, "success");
      } else {
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const employeTrainingTypeListFun = () => async (dispatch, getState) => {
  try {
    const responseData = await employeTrainingTypeListCall();
    //console.log("ttgtttttttttt");
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setEmployeTrainingTypeListData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
    } else {
      dispatch(setEmployeTrainingListData(null));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export const employeTrainingTypeEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await employeTrainingTypeEditCall(data);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(employeTrainingTypeListFun());
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const employeTrainingTypeDeleteFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await employeTrainingTypeDeleteCall(data);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(employeTrainingTypeListFun());
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getEmployeTreeList = (data) => async (dispatch, getState) => {
  try {
    const responseData = await employeTreeListCall(data);
    //console.log("ttgtttttttttt");
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setEmployeTreeListData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
    } else {
      dispatch(setEmployeTreeListData(null));
      // BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const branchTreeListFun =
  (branchFilter) => async (dispatch, getState) => {
    try {
      const responseData = await branchTreeListCall(branchFilter);
      //console.log("ttgtttttttttt");
      if (responseData.status.code === 200) {
        // console.log(responseData.data);

        dispatch(setBranchTreeListData(responseData.data));
        // dispatch(setLoading(false));
        // dispatch(setEmployeInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
        // notify(
        //   {
        //     message: responseData.status.message,
        //     width: 450,
        //   },
        //   "error"
        // );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const branchAddFun = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    dispatch(setLoading(true));
    const responseData = await branchAddCall(data);
    //console.log("ttgtttttttttt");
    if (responseData.status.code === 200) {
      dispatch(setLoading(false));
      // console.log(responseData.data);
      //dispatch(setBranchTreeListData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
      BaseHelper.nodifyFun(responseData.status.message, "success");
      dispatch(setBranchFilter({ company_id: data.company_id }));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const branchDeleteFun = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const responseData = await branchDeleteCall(data);
    //console.log("ttgtttttttttt");
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      //dispatch(setBranchTreeListData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
      BaseHelper.nodifyFun(responseData.status.message, "success");
      dispatch(setBranchFilter({ company_id: data.company_id }));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getEmploye = (filter) => async (dispatch, getState) => {
  try {
    // console.log("fdvfdvfdvfdvfdvfdvvdf");
    const responseData = await employeDataCall(filter);
    // console.log("fdvfdvfdvfdvfdvfdvvdf");
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setEmployeData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setEmployeInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export const createEmploye =
  (formData, config) => async (dispatch, getState) => {
    try {
      const responseData = await createEmployeService(formData);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        dispatch(setCreateEmploye(responseData.data));
        // console.log(responseData.data);
        dispatch(setEmployeSearchData(null));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setEmployeInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const removeEmployeList = (employeId) => async (dispatch, getState) => {
  try {
    const responseData = await employeRemove(employeId);
    if (responseData.success) {
      dispatch(setRemoveEmploye(employeId));
      dispatch(setSuccessSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }
  } catch (error) {
    console.log(error);
  }
};
export const getEmployeSearch = (reg) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const regnum = { regnum: reg };
    const responseData = await employeSearchCall({ regnum });
    // console.log(responseData);
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setEmployeSearchData(responseData.data));
      // dispatch(setEmployeInspectionData(responseData.data));
    } else {
      //console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getEmployeData = (getDaata) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const data = { plate_no: getDaata };
    const responseData = await employeSearchCall({ data });

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setLoading(false));
      //   dispatch(setEmployeInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;
