import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useRef, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Form,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Texts,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import {
  getTransportRouteFun,
  getTransportRouteStopDeleteFun,
  getTransportRouteStopEditFun,
  getTransportRouteStopFun,
  getTransportStopListEditFun,
  getTransportStopListFun,
  transportRouteAddEditFun,
} from "../../store/reducers/metaCrud";
import { TextBox } from "devextreme-react";

const TransportRouteStop = (props) => {
  const gridRef = useRef();
  const getTransportRouteStopList = useSelector(
    (state) => state.metaCrud.transportRouteStopList
  );
  const getTransportStopListData = useSelector(
    (state) => state.metaCrud.transportStopListData
  );
  const filter = useSelector((state) => state.feedBack.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [editRowKey, setEditRowKey] = useState(-1);
  const [selectRowIndex, setSelectRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const [routeId, setRouteId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getTransportStopListData === null) {
      dispatch(getTransportStopListFun());
    }
  }, [getTransportStopListData, dispatch]);

  useEffect(() => {
    if (getTransportRouteStopList !== null) {
      setFormData(getTransportRouteStopList.stop);
      setRouteId(getTransportRouteStopList.id);
      //  console.log(props.routeStopData);
    }
  }, [getTransportRouteStopList, props]);

  //console.log(getTransportRouteStopList.stop);

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    console.log(selectedRowsData[0]);

    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };
  //console.log(props);

  //console.log(props);
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;
      //console.log(e.changes[0].type);

      // const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          const data = e.changes[0].data;
          if (Object.keys(data).length > 0) {
            data["id"] = 0;
            data["route_id"] = routeId;

            data["is_active"] = 1;
          }

          //  console.log(data);
          dispatch(getTransportRouteStopEditFun(data));
          const dataId = { id: routeId };
          dispatch(getTransportRouteStopFun(dataId));
          // console.log(data);
          e.cancel = true;
          gridRef.current.instance.cancelEditData();

          // e.component.cancelEditData();
          //gridRef.current.instance.cancelEditData();
        } else if (e.changes[0].type === "remove") {
          // console.log(e.changes[0].key);
          const data = { id: e.changes[0].key };
          // console.log(data);
          dispatch(getTransportRouteStopDeleteFun(data));

          const dataId = { id: routeId };
          dispatch(getTransportRouteStopFun(dataId));
          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }
      // e.component.cancelEditData();
    },
    [routeId, dispatch]
  );

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    // console.log(editRowKey);
    setEditRowKey(editRowKey);
  }, []);

  // function onEditorPreparing(e) {
  //   // treeListRef.current.instance.selectedRowKeys();
  //   console.log(e.row.data);
  //   e.allowEditing === true ? setEditRowData(e.row.data) : setEditRowData(null);
  // }
  return (
    <Box m="20px">
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            {/* <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    const data = { id: routeId };
                    dispatch(getTransportRouteStopFun(data));
                    gridRef.current.instance.refresh();
                    //   console.log(gridRef.current.instance.getSelectedRowsData());
                    // setIsPopUpEdit(true);
                    // console.log(isPopUpEdit);
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item> */}
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"add"}
                  onClick={() => {
                    if (props.routeStopData === null) {
                      notify(
                        {
                          message: "Чиглэлээ сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    } else {
                      //   console.log(routeId);

                      gridRef.current.instance.addRow();
                    }
                  }}
                  type={"default"}
                  text="Шинэ"
                />
              </Box>
            </Item>
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"edit"}
                  onClick={(e) => {
                    // if (selectIndex > -1) {
                    //   gridRef.current.instance.editRow(selectIndex);
                    // } else {
                    //   console.log("ffff");
                    // }
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //   const getSelectedKey =
                    //   gridRef.current.instance.getSelectedRowKeys();
                    //  console.log(selectRowIndex[0]);
                    if (getSelectedData.length > 0) {
                      gridRef.current.instance.editRow(selectRowIndex[0] - 1);
                    } else {
                      notify(
                        {
                          message: "Засах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    //gridRef.current.instance.instance.deselectAll();
                  }}
                  type={"success"}
                  text="Засах"
                />
              </Box>
            </Item> */}
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      for (let i = 0; i < getSelectedData.length; i++) {
                        const element = getSelectedData[i];

                        console.log(element);
                      }
                    } else {
                      notify(
                        {
                          message: "Устгах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item> */}
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
            rowAlternationEnabled={true}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={400}
            // onRowDblClick={(row) => {
            //   setFormEditData(row.data);
            //   // console.log(row);
            //   gridRef.current.instance.editRow(row.rowIndex);
            // }}
            //   defaultSelectionFilter={selectionFilter}
            onSelectionChanged={onSelectionChanged}
            onSaving={onSaving}
            // onEditorPreparing={onEditorPreparing}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              //  allowAdding={true}
              allowDeleting={true}
              // changes={changes}
              useIcons={true}
              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popup
                title="Чиглэлд зогсоол нэмэх"
                showTitle={true}
                width={"40%"}
                height={300}
              />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                saveRowToNode={"ffff"}
                cancelRowChanges={"Цуцлах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="stop_id" />
                  <Item dataField="rank" caption="Эрэмбэ" />

                  {/* <Item dataField="code">
                    <TextBox dataField="code" readOnly value={routeId} />
                  </Item> */}
                </Item>

                {/* <Item
                  itemType="group"
                  caption="Home Address"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="StateID" />
                  <Item dataField="Address" />
                </Item> */}
              </Form>
            </Editing>

            <FilterRow visible={true} />
            <ColumnChooser enabled={false} />

            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            {/* <Column dataField="name" format="fixedPoint" caption={"Нэр"} /> */}
            <Column dataField="stop_id" caption="Нэр" width={220}>
              <Lookup
                dataSource={getTransportStopListData}
                valueExpr="id"
                displayExpr="name"
              />
              <RequiredRule />
            </Column>

            <Column dataField="code" format="fixedPoint" caption={"Код"} />
            <Column dataField="rank" format="fixedPoint" caption={"Эрэмбэ"}>
              <RequiredRule />
            </Column>

            <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Огноо"}
            />

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default TransportRouteStop;
