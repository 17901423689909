import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import SidebarMenu from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/user";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Login from "./scenes/login";
import Vehicle from "./scenes/vehicle";

import ReactDOM from "react-dom";
// import "devextreme/dist/css/dx.common.css";
//import "devextreme/dist/css/dx.light.css";
// import "devextreme/dist/css/dx.greenmist.compact.css"; /// ligth
// import "devextreme/dist/css/dx.darkmoon.compact.css"; /// ligth

import themes from "devextreme/ui/themes";
import { Button, LoadPanel } from "devextreme-react";
import Driver from "./scenes/driver";
import User from "./scenes/user";
import Company from "./scenes/company";
import { useDispatch, useSelector } from "react-redux";

import SuccessSnackbar from "./components/successSnackbar";
import Employe from "./scenes/employe";
import RequestVehicle from "./scenes/request/admin/vehicleReq";
import RequestDriver from "./scenes/request/admin/driverReq";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import { getUserData } from "./store/reducers/user/auth";
import Missing from "./components/Missing";

import FeedBack from "./scenes/feedBack";
import RequestCompanyVehicle from "./scenes/request/company/vehicleReq";
import RequestCompanyDriver from "./scenes/request/company/driverReq";
import EmployeeSettings from "./scenes/settingsPage";
import CompanyTypeCrud from "./scenes/metaData/companyTypeCrud";
import UserTypeCrud from "./scenes/metaData/userTypeCrud";
import JobPositionTypeCrud from "./scenes/metaData/jobPositionTypeCrud";
import UserSocietyCrud from "./scenes/metaData/userSocietyCrud";
import UserSexCrud from "./scenes/metaData/userSexCrud";
import UserNationalityCrud from "./scenes/metaData/userNationalityCrud";
import TransportRouteCrud from "./scenes/metaData/transportRouteCrud";
import TransportZoneCrud from "./scenes/metaData/transportZoneCrud";
import Penalty from "./scenes/violation";
import Violation from "./scenes/violation";
import ViolationCitizenTypeCrud from "./scenes/metaData/violationCitizenTypeCrud";
import ViolationTypeCrud from "./scenes/metaData/violationTypeCrud";
import ViolationDecisionTypeCrud from "./scenes/metaData/violationDecisionTypeCrud";
import ViolationDetectionTypeCrud from "./scenes/metaData/violationDetectionTypeCrud";
import ViolationLegalProvision from "./scenes/metaData/violationLegalPenaltyCrud";
import ViolationLegalPenalty from "./scenes/metaData/violationLegalPenaltyCrud";
import ViolationLegalPenaltyCrud from "./scenes/metaData/violationLegalPenaltyCrud";
import ViolationServiceTypeCrud from "./scenes/metaData/violationServiceTypeCrud";
import TransportStopListCrud from "./scenes/metaData/transportStopListCrud";
import ViolationLegalProvisionCrud from "./scenes/metaData/violationLegalProvisionCrud";
import ViolationOffenderCrud from "./scenes/metaData/violationOffenderCrud";
import ViolationDriverCrud from "./scenes/metaData/violationVehicleCrud";
import ViolationVehicleCrud from "./scenes/metaData/violationVehicleCrud";
import ViolationCompanyCrud from "./scenes/metaData/violationCompanyCrud";
import PorkurorReport from "./scenes/reports/porkuror_report";
import ServiceTypeReport from "./scenes/reports/service_type_report";
import InspectorReport from "./scenes/reports/inspector_report";
import PenaltyReport from "./scenes/reports/penalty_report";
import LegalProvisionReport from "./scenes/reports/legal_provision_report";
import ViolationTypeReport from "./scenes/reports/violation_type_report";
import ViolationDetectionReport from "./scenes/reports/violation_detection_report";
import ViolationCompanyReport from "./scenes/reports/violation_company_report";
import ViolationSubjectReport from "./scenes/reports/violation_subject_report";
import ViolationAanTypeReport from "./scenes/reports/violation_aan_type_report";
import ViolationInspectorDetectReport from "./scenes/reports/violation_inspector_detect_report";
import ViolationInspectorTypeReport from "./scenes/reports/violation_inspector_type_report";
import EmployeeTrainingCrud from "./scenes/metaData/employeTrainingCrud";
import EmployeeTrainingList from "./components/employee/employeeTrainingList";
import VehicleCountBuildRepoprt from "./scenes/reports/vehicle_count_build_report";
import VehicleCountMarkRepoprt from "./scenes/reports/vehicle_count_mark_report";
import VehicleCountAgeRepoprt from "./scenes/reports/vehicle_count_age_report";
import VehicleDriverReport from "./scenes/reports/vehicle_driver_report";
import DriverReportList from "./scenes/reports/driver_report";
import DriverReport from "./scenes/reports/driver_report";
import FooterBar from "./scenes/global/footer";
import FaqHuman from "./scenes/publicPages/human";
import FaqFeedback from "./scenes/publicPages/feedback";
import FaqViolation from "./scenes/publicPages/faqViolation";
import FaqDriver from "./scenes/publicPages/faqDriver";
import FaqVehicle from "./scenes/publicPages/faqVehicle";
import FaqVehicleRequest from "./scenes/publicPages/faqVehicleRequest";
import FaqDriverRequest from "./scenes/publicPages/faqDriverRequest";
import FaqMetaData from "./scenes/publicPages/faqMetaData";
import FaqReport from "./scenes/publicPages/faqReport";
import FaqSettings from "./scenes/publicPages/faqSettings";
import GrievanceConfirmedReport from "./scenes/reports/grievance/grievance_report_confirmed";
import GrievanceReport2 from "./scenes/reports/grievance/grievance_report2";
import GrievanceReport3 from "./scenes/reports/grievance/grievance_report3";
import HumanReport1 from "./scenes/reports/human/humanReport1";
import HumanReport2 from "./scenes/reports/human/humanReport2";
import HumanReport3 from "./scenes/reports/human/humanReport3";
import ViolationInspectorWorkStatic from "./scenes/reports/violation_inspector_work_static";
import DriverVehicleActivityReport from "./components/reports/driverVehicleActivityReport/driver_vehicle_activity_report";
import DriverVehActivityReport from "./scenes/reports/driver_veh_activity_report.js";
const ROLES = {
  User: "user",
  Editor: "editor",
  Admin: "admin",
};
function App() {
  let { slug } = useParams();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  //DevExpress.ui.themes.current("devextreme/dist/css/dx.darkmoon.css");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getRole = useSelector((state) => state.auth.role);
  const logedUser = useSelector((state) => state.auth.user);
  const getRole = useSelector((state) => state.auth.role);
  const loading = useSelector((state) => state.auth.loading);
  // ===== or when using modules =====

  // console.log(logedUser);
  useEffect(() => {
    document.title = "Нийтийн тээвэр";
    // document.images = "images/logo.png";
    //console.log(logedUser);
  }, []);
  //console.log(logedUser);
  useEffect(() => {
    if (logedUser === null) {
      dispatch(getUserData());
    }
  }, [logedUser, dispatch]);
  // useEffect(() => {
  //   if (!loading) {
  //     navigate("/");
  //   }
  // }, [loading]);
  useEffect(() => {
    //console.log(themes.current());
    themes.current(
      themes.current() === "dx.greenmist.compact"
        ? "dx.darkmoon.compact"
        : "dx.greenmist.compact"
    );
  }, [theme]);
  if (loading) {
    return (
      <LoadPanel
        visible={true}
        shadingColor="rgba(0,0,0,0.4)"
        shading={true}
        showPane={true}
        message="Уншиж байна түр хүлээнэ үү"
      />
    );
  }
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app">
          {logedUser !== null ? (
            logedUser.role ? (
              <SidebarMenu isSidebar={isSidebar} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {/* <Container maxWidth="xl"> */}
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route>
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="/login" element={<Login />} />
                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />
                  }
                >
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/vehicle" element={<Vehicle />} />
                  <Route path="/driver" element={<Driver />} />
                  <Route path="/users" element={<User />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/feedBack" element={<FeedBack />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/geography" element={<Geography />} />
                  <Route path="/employe" element={<Employe />} />
                  <Route path="/vehicleReq" element={<RequestVehicle />} />
                  <Route path="/driverReq" element={<RequestDriver />} />
                  <Route path="/settings" element={<EmployeeSettings />} />
                  <Route path="/company" element={<Company />} />
                  <Route
                    path="/compDriverReq"
                    element={<RequestCompanyDriver />}
                  />
                  <Route
                    path="/compVehicleReq"
                    element={<RequestCompanyVehicle />}
                  />

                  <Route
                    path="/organization_type"
                    element={<CompanyTypeCrud />}
                  />
                  <Route path="/user_type" element={<UserTypeCrud />} />
                  <Route path="/society" element={<UserSocietyCrud />} />
                  <Route
                    path="/job_position"
                    element={<JobPositionTypeCrud />}
                  />
                  <Route path="/sex" element={<UserSexCrud />} />
                  <Route
                    path="/userNationality"
                    element={<UserNationalityCrud />}
                  />
                  <Route
                    path="/transport_route"
                    element={<TransportRouteCrud />}
                  />
                  <Route
                    path="/transport_zone"
                    element={<TransportZoneCrud />}
                  />
                  <Route path="/violation" element={<Violation />} />
                  <Route
                    path="/violationCitizenType"
                    element={<ViolationCitizenTypeCrud />}
                  />
                  <Route
                    path="/violation_type"
                    element={<ViolationTypeCrud />}
                  />
                  <Route
                    path="/violation_decision_type"
                    element={<ViolationDecisionTypeCrud />}
                  />
                  <Route
                    path="/violation_ detection_type"
                    element={<ViolationDetectionTypeCrud />}
                  />
                  <Route
                    path="/violation_ legal_provision"
                    element={<ViolationLegalPenaltyCrud />}
                  />
                  <Route
                    path="/violation_ service_type"
                    element={<ViolationServiceTypeCrud />}
                  />
                  <Route
                    path="/transport_stop"
                    element={<TransportStopListCrud />}
                  />
                  <Route
                    path="/violation_legal_penalty"
                    element={<ViolationLegalProvisionCrud />}
                  />
                  <Route
                    path="/violation_offender"
                    element={<ViolationOffenderCrud />}
                  />
                  <Route
                    path="/violation_vehicle"
                    element={<ViolationVehicleCrud />}
                  />
                  <Route
                    path="/violation_company"
                    element={<ViolationCompanyCrud />}
                  />
                  <Route
                    path="/report_violation1"
                    element={<PorkurorReport />}
                  />
                  <Route
                    path="/report_violation2"
                    element={<ServiceTypeReport />}
                  />
                  <Route
                    path="/report_violation3"
                    element={<InspectorReport />}
                  />
                  <Route
                    path="/report_violation4"
                    element={<PenaltyReport />}
                  />
                  <Route
                    path="/report_violation5"
                    element={<LegalProvisionReport />}
                  />
                  <Route
                    path="/report_violation6"
                    element={<ViolationTypeReport />}
                  />
                  <Route
                    path="/report_violation7"
                    element={<ViolationDetectionReport />}
                  />
                  <Route
                    path="/report_violation8"
                    element={<ViolationCompanyReport />}
                  />
                  <Route
                    path="/report_violation9"
                    element={<ViolationSubjectReport />}
                  />
                  <Route
                    path="/report_violation10"
                    element={<ViolationAanTypeReport />}
                  />
                  <Route
                    path="/report_violation11"
                    element={<ViolationInspectorDetectReport />}
                  />
                  <Route
                    path="/report_violation12"
                    element={<ViolationInspectorTypeReport />}
                  />
                  <Route
                    path="/report_violation13"
                    element={<ViolationInspectorWorkStatic />}
                  />
                  <Route
                    path="/training_type"
                    element={<EmployeeTrainingCrud />}
                  />
                  <Route path="/training" element={<EmployeeTrainingList />} />
                  <Route
                    path="/report_1"
                    element={<VehicleCountBuildRepoprt />}
                  />
                  <Route
                    path="/report_2"
                    element={<VehicleCountMarkRepoprt />}
                  />
                  <Route
                    path="/report_3"
                    element={<VehicleCountAgeRepoprt />}
                  />
                  <Route path="/report_4" element={<VehicleDriverReport />} />
                  <Route path="/report_5" element={<DriverReport />} />
                  <Route
                    path="/report_6"
                    element={<DriverVehActivityReport />}
                  />
                  <Route
                    path="/report_grievance1"
                    element={<GrievanceConfirmedReport />}
                  />
                  <Route
                    path="/report_grievance2"
                    element={<GrievanceReport2 />}
                  />
                  <Route
                    path="/report_grievance3"
                    element={<GrievanceReport3 />}
                  />
                  <Route path="/report_hr1" element={<HumanReport1 />} />
                  <Route path="/report_hr2" element={<HumanReport2 />} />
                  <Route path="/report_hr3" element={<HumanReport3 />} />
                  <Route path="/faq" element={<FAQ />} />
                </Route>
                <Route path="/faqHuman" element={<FaqHuman />} />
                <Route path="/faqFeedback" element={<FaqFeedback />} />
                <Route path="/faqViolation" element={<FaqViolation />} />

                <Route path="/faqDriver" element={<FaqDriver />} />
                <Route path="/faqVehicle" element={<FaqVehicle />} />
                <Route
                  path="/faqVehicleRequest"
                  element={<FaqVehicleRequest />}
                />
                <Route
                  path="/faqDriverRequest"
                  element={<FaqDriverRequest />}
                />
                <Route path="/faqMetaData" element={<FaqMetaData />} />
                <Route path="/faqReport" element={<FaqReport />} />
                <Route path="/faqSettings" element={<FaqSettings />} />
                {/* <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />
                  }
                >
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/vehicle" element={<Vehicle />} />
                  <Route path="/driver" element={<Driver />} />
                  <Route
                    path="/compDriverReq"
                    element={<RequestCompanyDriver />}
                  />
                  <Route
                    path="/compVehicleReq"
                    element={<RequestCompanyVehicle />}
                  />
                </Route> */}
                {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/vehicle" element={<Vehicle />} />
                  <Route path="/driver" element={<Driver />} />
                  <Route
                    path="/compDriverReq"
                    element={<RequestCompanyDriver />}
                  />
                  <Route
                    path="/compVehicleReq"
                    element={<RequestCompanyVehicle />}
                  />
                </Route> */}
                <Route path="*" element={<Missing />} />
              </Route>
            </Routes>
            <FooterBar sx={{}} />
            <SuccessSnackbar />
          </main>

          {/* </Container> */}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
  // }
}

export default App;
