import { Box, useTheme, Button as Btn, Stack, Typography } from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";

import "./style.css";

import useMediaQuery from "@mui/material/useMediaQuery";

import CompanyEdit from "./companyEdit";
import CompanyAdd from "./companyAdd";
import { useDispatch, useSelector } from "react-redux";
import StelektonListData from "../../utils/skeletonList copy";
import {
  editCompany,
  getCompany,
  removeCompanyList,
} from "../../store/reducers/company";
import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import BaseHelper from "../../helper/BaseHelper";
let dataGrid;
const selectionFilter = ["regnum", "=", "2117428"];
const CompanyList = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [displayMode, setDisplayMode] = useState("full");
  const [showPageSizeSelector, setShowPageSizeSelector] = useState(true);
  const [showInfo, setShowInfo] = useState("full");
  const [showNavButtons, setShowNavButtons] = useState("full");
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const filter = useSelector((state) => state.company.filter);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const logedUser = useSelector((state) => state.auth.user);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  // console.log(props.companyList);
  useEffect(() => {
    gridRef.current.instance.beginCustomLoading();
    if (props.companyList) {
      setFormData(props.companyList);
      gridRef.current.instance.endCustomLoading();
    }
  }, [props]);
  // console.log(formData);
  const gridRef = useRef();
  //const [state, dispatch] = React.useReducer(reducer, initialState);
  //const vehicleDataList = useSelector((state) => state.vehicle.vehicleData);
  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  //   const onSelectionChanged = (e) => {
  //     //const data = selectedRowsData[0];
  //     const data = e.selectedRowsData;

  //     console.log(e.component);
  //     setSelectIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  //     setIsType(2);
  //     setFormEditData(data[0]);

  //     //console.log(formEditData.ID);
  //   };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    // console.log(selectedRowsData[0]);
    props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
  };
  const hideInfo = () => {
    setIsPop(false);
    setFormEditData(null);
    setIsConfirm(false);
  };
  const setStoreCompany = (formData) => {
    // dispatch(editCompany(data));
    // console.log(data);

    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  //   const onSaving = (e) => {
  //     console.log(e.changes);
  //     //gridRef.current.instance.saveEditData();
  //   };
  function deleteRecords() {
    gridRef.current.instance.getSelectedRowsData().forEach((key) => {
      console.log(key);
      dispatch(removeCompanyList(key.id));
    });
    // this.setState({
    //   selectedItemKeys: [],
    // });
    gridRef.current.instance.refresh();
  }
  function validateRemove(e) {
    const isCanceled = new Promise((resolve, reject) => {
      //console.log(e.key);
      const getSelectedData = gridRef.current.instance.getSelectedRowsData();
      if (getSelectedData.length > 0) {
        getSelectedData.forEach((key) => {
          console.log(key.id);
        });
      }
      //console.log(gridRef.current.instance.getSelectedRowsData());
      //   console.log(e.data);
      //   console.log(e.data.ID);
      resolve(true);
      // fetch(`https://url/to/your/validation/service/${e.key}`)
      //     .then((validationResult) => {
      //         if (validationResult.errorText) {
      //             reject(validationResult.errorText);
      //         } else {
      //             resolve(false);
      //         }
      //     });
    });
    e.cancel = isCanceled;
  }

  //   function updateRow(e) {
  //     const isCanceled = new Promise((resolve, reject) => {
  //       const promptPromise = confirm(
  //         "Та энэ үйлдлийг хййх үү?",
  //         "Тээврийн хэрэгсэл засах"
  //       );
  //       promptPromise.then((dialogResult) => {
  //         if (dialogResult) {
  //           console.log(dialogResult);
  //           console.log(e.oldData.ID);
  //           // gridRef.current.instance.cellValue(selectIndex, 2, e.newData);
  //           // gridRef.current.instance.saveEditData();
  //           console.log(e.newData);

  //           resolve(true);
  //           // let params = new HttpParams();
  //           //   for (let key in e.newData) {
  //           //     params = params.set(key, e.newData[key]);
  //           //   }
  //           //   fetch(`https://url/to/your/validation/service${params}`).then(
  //           //     (validationResult) => {
  //           //       if (validationResult.errorText) {
  //           //         reject(validationResult.errorText);
  //           //       } else {
  //           //         resolve(false);
  //           //       }
  //           //     }
  //           //   );
  //         } else {
  //           return resolve(true);
  //         }
  //       });
  //     });
  //     e.cancel = isCanceled;
  //   }

  // const companyEditSearch = () => {
  //   setFormEditData({ ID: 777, register: "33333" });
  // };
  //console.log(isLoad);
  // if (!isLoad) {
  //   return (
  //     <>
  //       <StelektonListData data={12} />
  //     </>
  //   );
  // } else {
  function onInitialized(e) {
    dataGrid = e.component;
    // this.calculateStatistics();
  }
  // console.log(logedUser);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getCompany(filter));
                gridRef.current.instance.refresh();
                //   console.log(gridRef.current.instance.getSelectedRowsData());
                // setIsPopUpEdit(true);
                // console.log(isPopUpEdit);
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(41, 2, "is_create", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"add"}
              onClick={() => {
                // console.log(props.isCompanyFilter);

                setIsType(1);
                setIsPop(true);

                //const dataSource = gridRef.current.instance.getDataSource();
                //   dataSource.filter(["register", ">", 18]);
                //   dataSource.load();
                // const store = dataSource.store();
                //  console.log(store);

                // setFormData([
                //   ...formData,
                //   {
                //     ID: 32,
                //     CompanyName: "test",
                //     Address: "test",
                //     City: "test",
                //     register: "test",
                //   },
                // ]);

                // console.log(...formData);
                // gridRef.current.instance.addRow();
                // gridRef.current.instance.deselectAll();
              }}
              type={"default"}
              text="Шинэ"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(41, 2, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"edit"}
              onClick={() => {
                // if (selectIndex > -1) {
                //   gridRef.current.instance.editRow(selectIndex);
                // } else {
                //   console.log("ffff");
                // }
                const getSelectedData =
                  gridRef.current.instance.getSelectedRowsData();
                console.log(getSelectedData.length);
                if (getSelectedData.length > 0 && getSelectedData.length < 2) {
                  if (formEditData === "" && isType === 1) {
                    alert("dfgdfg");
                  } else {
                    setIsType(2);
                    setIsPop(true);
                    console.log(isPop);
                  }
                } else {
                  notify(
                    {
                      message: "Засах талбар 1 ширхэгийг сонгон уу!!!",
                      width: 450,
                    },
                    "error"
                  );
                }

                //gridRef.current.instance.instance.deselectAll();
              }}
              type={"success"}
              text="Засах"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(41, 2, "is_delete", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"remove"}
              onClick={(e) => {
                // console.log(e.row);
                // console.log(selectIndex);
                // for (let i = 0; i < selectIndex.length; i++) {
                //   gridRef.current.instance.deleteRow(selectIndex[i]);
                // }
                gridRef.current.instance.deleteRow(selectIndex);
                //  deleteRecords();
                setIsConfirm(true);
                //gridRef.current.instance.deselectAll();
              }}
              type={"danger"}
              text="Устгах"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box>
            {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        // showRowLines={true}
        //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
        remoteOperations={true}
        dataSource={formData}
        keyExpr="id"
        height={420}
        onRowDblClick={(row) => {
          if (
            isType === 2 &&
            formEditData !== "" &&
            BaseHelper.permitionCheck(41, 2, "is_update", logedUser)
          ) {
            setFormEditData(row.data);
            setIsPop(true);
          } else {
            // alert("no");
          }
        }}
        selectedRowKeys={1}
        defaultSelectionFilter={selectionFilter}
        onSelectionChanged={onSelectionChanged}
        onInitialized={onInitialized}
      >
        <Editing
          mode="popup"
          //  changes={changes}
          //  onChangesChange={setChanges}
        >
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Хэрэглэгч устгах"}
            saveRowChanges={"Хадгалах"}
            cancelRowChanges={"Цуцлах"}
          />
        </Editing>
        <Selection mode="multiple" deferred={true} />
        <FilterRow visible={true} />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        <Column dataField="id" width={50} caption="#" />

        <Column
          dataField="regnum"
          width={130}
          format="fixedPoint"
          caption={"ААНБ-н регистер"}
          fixed={true}
        />
        <Column
          dataField="name"
          format="fixedPoint"
          caption={"ААНБ-н нэр"}
          fixed={true}
        />

        <Column
          dataField="organization_type_id"
          format="fixedPoint"
          caption={"Төрөл"}
        />
        <Column
          dataField="director_info"
          format="fixedPoint"
          caption={"Удирдлагын нэр"}
        />
        <Column dataField="address" format="fixedPoint" caption={"Хаяг"} />
        <Column dataField="phone_number" format="fixedPoint" caption={"Утас"} />
        <Column dataField="email" format="fixedPoint" caption={"И-мэйл"} />

        <Column
          dataField="direction_info"
          format="fixedPoint"
          caption={"Үйл ажилгааны чиглэл"}
        />
        <Column dataField="note" format="fixedPoint" caption={"Нэмэлт"} />
        <Column
          dataField="created_at"
          format="yyyy-MM-dd H:mm"
          dataType="date"
          caption={"Огноо"}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
        {/* <Scrolling rowRenderingMode="virtual"></Scrolling> */}
        {/* <Paging defaultPageSize={10}
        defaultPageIndex={1}
        //infoText="Хуудас #{0}. Total: {1} ({2} items)" 
        displayMode={"compact"}
        infoText='dd #{0} of #{1} (#{2} xxx)'
        showNavigationButtons={true}
        /> */}

        <LoadPanel enabled={true} />
      </DataGrid>

      <Popup
        visible={isPop}
        onHiding={hideInfo}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        position={"center"}
        title={
          isType === 2 ? "Байгууллагын мэдээлэл засах" : "Байгууллага бүртгэх"
        }
        showTitle={true}
        width={900}
        height={650}
      >
        {/* {formEditData ? formEditData.ID : "fff"} */}
        {isType === 1 ? (
          <CompanyAdd storeCompany={setStoreCompany} />
        ) : (
          <CompanyEdit
            companyEditData={formEditData}
            storeCompany={setStoreCompany}
            // searchVehBtn={companyEditSearch}
          />
        )}
      </Popup>
      <Popup
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        visible={isConfirm}
        showTitle={false}
        title="Устгах"
        onHiding={hideInfo}
        width={250}
        height={100}
      >
        <Stack direction={"column"} justifyContent={"stretch"}>
          <Typography sx={{ pt: 2, pb: 2, textAlign: "center" }}>
            устгах уу ?
          </Typography>
          <Stack direction="row" sx={{ pb: 2 }} justifyContent={"space-evenly"}>
            <Button
              text="Болих"
              icon="remove"
              onClick={() => {
                setIsConfirm(false);
              }}
            />

            <Button
              position="end"
              icon="check"
              text="Тийм"
              type="default"
              onClick={() => {
                deleteRecords();
              }}
            />
          </Stack>
        </Stack>
      </Popup>
    </>
  );
  // }
};

export default CompanyList;
