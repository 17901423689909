//import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { Box } from "devextreme-react";

import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Legend,
  Series,
} from "devextreme-react/chart";
import { populationData } from "../data/chartData";
import { useEffect, useState } from "react";
import { Export, Format } from "devextreme-react/bar-gauge";
const LineChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props != null) {
      setData(props.dashboardData);
    }
  }, [props]);
  // console.log(data);
  return (
    <>
      <Chart
        style={{
          height: "370px",
          margin: "18px",
        }}
        //title="World Population by Decade"
        // dataSource={populationData}

        dataSource={data != null ? data.violation.chart : []}
        id="chart"
      >
        <CommonSeriesSettings argumentField="state" type="stackedbar" />
        <ArgumentAxis tickInterval={10}>
          <Label format="decimal" />
        </ArgumentAxis>
        <CommonSeriesSettings
          argumentField="state"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <Label visible={true}>
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>
        <Series
          type="bar"
          valueField="total_count"
          argumentField="violation_month"
        />

        <Legend visible={false} />

        <Export enabled={false} />
      </Chart>
    </>
  );
};

export default LineChart;
