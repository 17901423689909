import { createSlice } from "@reduxjs/toolkit";

import {
  createDriverService,
  inspectionCall,
  driverDataCall,
  driverImageCall,
  driverRemove,
  driverSearchCall,
} from "../../utils/services/driverService";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
import BaseHelper from "../../helper/BaseHelper";
export const slice = createSlice({
  name: "driver",
  initialState: {
    driverData: null,
    driverSearchData: null,
    driverInspectionData: null,
    trailerInspectionData: null,
    filter: {},
    isLoading: false,
  },

  reducers: {
    setDriverInspectionData: (state, { payload }) => {
      //console.log(payload);
      state.driverInspectionData = payload;
    },
    setTrailerInspectionData: (state, { payload }) => {
      // console.log(payload);
      state.trailerInspectionData = payload;
    },
    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setDriverSearchData: (state, { payload }) => {
      // console.log(payload);
      state.driverSearchData = payload;
    },
    setDriverData: (state, { payload }) => {
      // console.log(payload);
      state.driverData = payload;
    },
    setCreateDriver: (state, { payload }) => {
      // console.log(payload);
      state.driverData.unshift(payload);
    },
    setEditDriver: (state, { payload }) => {
      // console.log(payload);
      let listCopy = [...state.driverData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.phone_number = payload.phone_number;
        }
      });
    },
    setRemoveDriver: (state, { payload }) => {
      //console.log(payload);

      let index = state.driverData.findIndex(({ id }) => id === payload);
      state.driverData.splice(index, 1);
    },
  },
});

export const {
  setDriverInspectionData,
  setTrailerInspectionData,
  setLoading,
  setDriverSearchData,
  setDriverData,
  setCreateDriver,
  setRemoveDriver,
  setFilter,
  setEditDriver,
} = slice.actions;

export const getDriver = (filter) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const responseData = await driverDataCall(filter);
    //console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setDriverData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setDriverInspectionData(responseData.data));
    } else {
      dispatch(setDriverData(null));
      dispatch(setLoading(false));
      dispatch(setDriverData(1));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
    dispatch(setDriverData(1));
  }
};

export const createDriver = (data, config) => async (dispatch, getState) => {
  try {
    const responseData = await createDriverService(data);
    //console.log(responseData);
    if (responseData.status.code === 200) {
      dispatch(setCreateDriver(responseData.data[0]));
      //console.log(responseData);
      dispatch(getDriver({ company_id: data.company_id }));
      dispatch(setDriverSearchData(null));

      BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setDriverInspectionData(responseData.data));
    } else {
      //console.log(responseData.status.message);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const removeDriverList = (data) => async (dispatch, getState) => {
  try {
    const responseData = await driverRemove(data);
    // console.log(responseData.data);
    if (responseData.status.code === 200) {
      dispatch(setRemoveDriver(data.id));
      BaseHelper.nodifyFun(responseData.status.message, "success");

      // dispatch(getDriver());
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }
  } catch (error) {
    console.log(error);
  }
};
export const getDriverSearch = (reg) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const regnum = { regnum: reg };
    const responseData = await driverSearchCall({ regnum });
    // console.log(responseData);
    if (responseData.status.code === 200) {
      //   console.log("---------------");
      //  console.log(responseData.data);
      dispatch(setLoading(false));
      dispatch(setDriverSearchData(responseData.data));

      // dispatch(setDriverInspectionData(responseData.data));
    } else {
      //console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setLoading(false));
    console.log(error);
    BaseHelper.nodifyFun(error, "error");
  }
};
export const getDriverData = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const plate_no = { plate_no: data };
    const responseData = await driverSearchCall({ plate_no });

    if (responseData.status.code === 200) {
      //  console.log(responseData.data);
      dispatch(setLoading(false));
      dispatch(setDriverInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;
