import axios from "../../api/axiosInctance";
import { guest } from "../../store/reducers/user/auth";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
//const getToken1 = localStorage.getItem("token");

export const feedBackDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    // console.log(newFilter);
    const getFeedBackData = await axios
      .post("/grievance/list", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          //  console.log(res.data);
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getFeedBackData;
  } catch (error) {
    console.log(error);
  }
};
export const getFeedBackTypeCall = async () => {
  try {
    // let newFilter = {};
    // Object.keys(filter).map((key) => {
    //   if (filter[key] !== null) {
    //     newFilter[key] = filter[key];
    //   }
    // });
    const getFeedBackData = await axios
      .post("grievance/type/tree")
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getFeedBackData;
  } catch (error) {
    console.log(error);
  }
};
// export const feedBackDataCall = async (filter) => {
//   try {
//     //console.log(axios);
//     let newFilter = {};
//     Object.keys(filter).map((key) => {
//       if (filter[key] !== null) {
//         newFilter[key] = filter[key];
//       }
//     });
//     // console.log(JSON.stringify(newFilter));
//     const getFeedBackData = await axios
//       .get("/feedBack/getFeedBacks", {
//         params: {
//           pagesize: 1000,
//           filter: JSON.stringify(newFilter),
//         },
//       })
//       .then((res) => {
//         //console.log(res.data);
//         return res.data;
//       })
//       .catch((err) => {
//         console.log("Aldaaa");
//         let message =
//           typeof err.response !== "undefined"
//             ? err.response.data.message
//             : err.message;
//         console.warn("error", message);
//       })
//       .finally(() => {
//         // console.log("ddd");
//       });
//     return getFeedBackData;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const feedBackRemove = async (feedBackId) => {
  try {
    console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("/feedBack/removeFeedBack", { id: feedBackId })
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};

export const createFeedBackService = async (formData) => {
  try {
    //   console.log(plateNo);

    const getInspection = await axios
      .post("/company/feedBack/edit", formData)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const feedBackDataEditCall = async (formData) => {
  try {
    //   console.log(plateNo);

    const getInspection = await axios
      .post("/grievance/edit", formData)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const feedBackDataClosedCall = async (formData) => {
  try {
    //   console.log(plateNo);

    const getInspection = await axios
      .post("/grievance/close", formData)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const inspectionCall = async ({ plate_no }) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("/feedBack/inspection", plate_no)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const feedBackSearchCall = async ({ regnum }) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("feedBack/data", regnum)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};

export const getGrievanceDecisionListCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/grievance/decision/list", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const backDecisionCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("grievance/decision/back", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getGrievanceDecisionTypeListCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/grievance/decision/type/list")
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const grievanceMoveCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/grievance/decision/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
