import { Box, Button, TextField, Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../../store/reducers/company";
import { DropDownBox } from "devextreme-react";
import { getFeedBackTypeList } from "../../../store/reducers/feedBack";
import { setFilter } from "../../../store/reducers/report";
import {
  getViolationLegalPenaltyFun,
  getViolationLegalProvisionFun,
  getViolationTypeDataFun,
  getviolationServiceTypeCrudFun,
} from "../../../store/reducers/metaCrud";
import { getVehicleServiceType } from "../../../store/reducers/vehicle";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };

  const violationTypeDataList = useSelector(
    (state) => state.metaCrud.violationTypeData
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const logedUser = useSelector((state) => state.auth.user);
  const vehicleServiceTypeList = useSelector(
    (state) => state.vehicle.vehicleServiceTypeList
  );
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState(0);
  const [serviceTypeGridBoxValue, setServiceTypeGridBoxValue] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isGridServiceTypeBoxOpened, setIsGridServiceTypeBoxOpened] =
    useState(false);

  const [serviceTypeData, setServiceTypeData] = useState(null);

  // const [typeDate, setTypeDate] = useState([
  //   {
  //     id: 1,
  //     name: "Үндсэн",
  //   },
  //   { id: 2, name: " Ажилчдын " },
  //   { id: 3, name: "Такси" },
  //   { id: 4, name: "Хүүхдийн" },
  //   { id: 5, name: "Ачаа тээвэр" },
  //   { id: 12, name: "Буухиа" },
  // ]);
  const gridColumns = ["name"];
  const filter = useSelector((state) => state.report.filter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (violationTypeDataList === null) {
      dispatch(getViolationTypeDataFun());
    } else {
      setServiceTypeData(violationTypeDataList);
    }
  }, [violationTypeDataList, dispatch]);
  useEffect(() => {
    if (vehicleServiceTypeList === null) {
      dispatch(
        getVehicleServiceType({
          is_active: 1,
        })
      );
    }
  }, [vehicleServiceTypeList, dispatch]);
  useEffect(() => {
    if (typeDateGridBoxValue === "") {
      setTypeDateGridBoxValue("month");
    }
  }, [typeDateGridBoxValue]);

  // console.log(feedBackType);
  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={vehicleServiceTypeList}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  // const dataServiceTypeGridRender = () => {
  //   return (
  //     <DataGrid
  //       dataSource={serviceTypeData}
  //       columns={gridColumns}
  //       hoverStateEnabled={true}
  //       selectedRowKeys={serviceTypeGridBoxValue}
  //       onSelectionChanged={(e) => {
  //         //  console.log(e.selectedRowsData);
  //         const selValue = e.selectedRowKeys.map((ex) => {
  //           return ex.id;
  //         });
  //         // console.log(selValue);
  //         setServiceTypeGridBoxValue(selValue[0]);
  //         //  console.log(selValue[0]);
  //         setIsGridServiceTypeBoxOpened(false);
  //       }}
  //       height="100%"
  //     >
  //       <Selection mode="single" />
  //       <Scrolling mode="virtual" />
  //       <Paging enabled={true} pageSize={10} />
  //       <FilterRow visible={true} />
  //     </DataGrid>
  //   );
  // };
  return (
    <>
      <div style={{ margin: "10px" }} className="caption">
        Шүүлт
      </div>
      <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
        <Typography>Төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          value={typeDateGridBoxValue}
          opened={isGridBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Төрөл сонгох..."
          showClearButton={false}
          dataSource={vehicleServiceTypeList}
          onValueChanged={(e) => {
            setTypeDateGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("service_type_id", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataCompNameGridRender}
        />
        {/* <Typography>Зөрчил төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          width={250}
          value={serviceTypeGridBoxValue}
          opened={isGridServiceTypeBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Зөрчил төрөл сонгох..."
          showClearButton={true}
          dataSource={serviceTypeData}
          onValueChanged={(e) => {
            setServiceTypeGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("type_id", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridServiceTypeBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataServiceTypeGridRender}
        /> */}
        {/* <Typography>Эхлэх:</Typography>

        <DateBox
          height={30}
          defaultValue={startDate}
          displayFormat="yyyy-M-dd"
          onValueChanged={(e) => {
            //  console.log(e.value);
            props.getFilters("start_date", e.value);
          }}
          type="date"
        />
        <Typography>Дуусах:</Typography>
        <DateBox
          height={30}
          defaultValue={endDate}
          displayFormat="yyyy-M-dd"
          onValueChanged={(e) => {
            // console.log(e.value);
            props.getFilters("end_date", e.value);
          }}
          type="date"
        /> */}
      </Stack>
    </>
  );
};

export default FilterBox;
