import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "uiReducer",
  initialState: {
    successSnackbarOpen: false,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
    successSnackbarMessage: null,
    position: "",
  },

  reducers: {
    setSuccessSnackbarOpen: (state, { payload }) => {
      // console.log(payload);
      state.successSnackbarOpen = payload;
    },
    setErrorSnackbarOpen: (state, { payload }) => {
      // console.log(payload);
      state.errorSnackbarOpen = payload;
    },
    setInfoSnackbarOpen: (state, { payload }) => {
      // console.log(payload);
      state.infoSnackbarOpen = payload;
    },
    setSuccessSnackbarMessage: (state, { payload }) => {
      // console.log(payload);
      state.successSnackbarMessage = payload;
    },
    setPosition: (state, { payload }) => {
      // console.log(payload);
      state.position = payload;
    },
  },
});

export const {
  setSuccessSnackbarOpen,
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setSuccessSnackbarMessage,
  setPosition,
} = slice.actions;

export const snackbarData =
  ({ alert }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setSuccessSnackbarOpen(true));
      console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const clearSnackbarData = () => async (dispatch) => {
  try {
    dispatch(setSuccessSnackbarOpen(false));
    dispatch(setErrorSnackbarOpen(false));
    dispatch(setInfoSnackbarOpen(false));
    dispatch(setSuccessSnackbarMessage(null));
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;
