import { createSlice } from "@reduxjs/toolkit";

import {
  login as loginService,
  guestToken,
  getUser,
  requestPusher,
  loginDanService,
  loginDanVerifyService,
  userDataListCall,
  userEditCall,
  userProfileDataCall,
  getUserLocationCall,
  getUserUserNationalityCall,
  getUserSocietyCall,
  getUserSexCall,
  getEmployeeInfoCall,
  getUserJobpositionCall,
  getUserModuleCall,
  setUserPermissionCall,
  userProfileEditDataCall,
  userEmployeeEditCall,
  userDeleteCall,
  getUserTypeListCall,
  getUserTypeModuleCall,
  setUserTypePermissionCall,
  getUserLocationDistCall,
  getUserLocationHorooCall,
  createEmployeeCall,
  updateEmployeeCall,
  deleteEmployeeCall,
} from "../../../utils/services/authService";
import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "../uiReducer";
import notify from "devextreme/ui/notify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BaseHelper from "../../../helper/BaseHelper";
//import { dotenv } from "dotenv";

const userToken = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
export const slice = createSlice({
  name: "auth",
  initialState: {
    userListData: null,
    role: null,
    user: null,
    userProfileData: null,
    userLocationData: null,
    userLocationDistData: null,
    userLocationHorooData: null,
    userNationalityData: null,
    userSocietyData: null,
    userSexData: null,
    employeeDataList: null,
    userJobposition: null,
    userModuleData: null,
    userTypeModuleData: null,
    userToken,
    loading: true,
    loadingDan: false,
    alert: null,
    loginDanUrl: "",
    isLogged: false,
    filter: {},
    isUserEmployeeEdit: false,
    newUserId: null,
    newEmployeeData: null,
    // userTypeListData: null,
  },

  reducers: {
    setUser: (state, { payload }) => {
      // console.log(payload);
      state.user = payload;
    },
    setNewEmployeeData: (state, { payload }) => {
      // console.log(payload);
      state.newEmployeeData = payload;
    },
    setRemoveUser: (state, { payload }) => {
      // console.log(payload);

      let index = state.userListData.findIndex(({ id }) => id === payload);
      state.userListData.splice(index, 1);
    },
    setCreateUser: (state, { payload }) => {
      // console.log(payload);
      state.userListData = [];
      state.userListData.push(payload);
    },
    setUserProfile: (state, { payload }) => {
      // console.log(payload);
      state.userProfileData = payload;
    },
    setUserLocation: (state, { payload }) => {
      // console.log(payload);
      state.userLocationData = payload;
    },
    setUserLocationDist: (state, { payload }) => {
      // console.log(payload);
      state.userLocationDistData = payload;
    },
    setUserLocationHoroo: (state, { payload }) => {
      // console.log(payload);
      state.userLocationHorooData = payload;
    },
    setUserNationalityData: (state, { payload }) => {
      // console.log(payload);
      state.userNationalityData = payload;
    },
    setUserSocietyData: (state, { payload }) => {
      // console.log(payload);
      state.userSocietyData = payload;
    },
    setUserSexData: (state, { payload }) => {
      // console.log(payload);
      state.userSexData = payload;
    },
    setEmployeeDataList: (state, { payload }) => {
      // console.log(payload);
      state.employeeDataList = payload;
    },
    setUserJobposition: (state, { payload }) => {
      // console.log(payload);
      state.userJobposition = payload;
    },
    setUserModuleData: (state, { payload }) => {
      // console.log(payload);
      state.userModuleData = payload;
    },
    setUserTypeModuleData: (state, { payload }) => {
      // console.log(payload);
      state.userTypeModuleData = payload;
    },

    setToken: (state, { payload }) => {
      // console.log(payload);
      state.token = payload;
    },

    setRole: (state, { payload }) => {
      // console.log(payload);
      state.role = payload;
    },
    setUerToken: (state, { payload }) => {
      // console.log(payload);
      state.userToken = payload;
    },
    setUserListData: (state, { payload }) => {
      // console.log(payload);

      state.userListData = payload;
    },
    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setLoadingDan: (state, { payload }) => {
      state.loadingDan = payload;
    },
    setAlert: (state, { payload }) => {
      state.alert = payload;
    },
    setIsLogged: (state, { payload }) => {
      state.isLogged = payload;
    },
    setNewUserId: (state, { payload }) => {
      state.newUserId = payload;
    },
    setLoginDanUrl: (state, { payload }) => {
      state.loginDanUrl = payload;
    },
    setIsUserEmployeeEdit: (state, { payload }) => {
      state.isUserEmployeeEdit = payload;
    },
    // setUserTypeListData: (state, { payload }) => {
    //   state.userTypeListData = payload;
    // },
  },
});

export const {
  setUser,
  setLoading,
  setAlert,
  setRole,
  setFilter,
  setLoadingDan,
  setLoginDanUrl,
  setIsLogged,
  setToken,
  setUserListData,
  setUerToken,
  setUserProfile,
  setUserLocation,
  setUserLocationDist,
  setUserLocationHoroo,
  setUserNationalityData,
  setUserSocietyData,
  setUserSexData,
  setEmployeeDataList,
  setUserJobposition,
  setUserModuleData,
  setIsUserEmployeeEdit,
  setNewUserId,
  setRemoveUser,
  setCreateUser,
  setNewEmployeeData,
  setUserTypeListData,
  setUserTypeModuleData,
} = slice.actions;
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const signIn =
  ({ data }) =>
  async (dispatch, getState) => {
    try {
      const responseData = await loginService({
        data,
      });

      if (responseData.status.code === 200) {
        // dispatch(setIsLogged(true));
        dispatch(setLoading(false));
        dispatch(setUerToken(responseData.token));
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("role", responseData.role);
        dispatch(setUser(responseData.user));
        dispatch(setRole(responseData.role));
        dispatch(setSuccessSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage("Системд амжилттай нэвтэрлээ"));
        // navigate("/");
        // console.log(responseData);
      } else {
        dispatch(setLoading(false));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          success: false,
          message: "Нэвтрэх явцад алдаа гарлаа",
        })
      );

      return null;
    }
  };

export const logout = () => async (dispatch) => {
  // login service call then

  dispatch(setUser(null));
  dispatch(setRole(null));
  dispatch(setIsLogged(false));
  dispatch(setLoading(false));

  localStorage.removeItem("token");
  localStorage.removeItem("role");
  window.location = "/";
  // navigate("/login");
  // dispatch(setSuccessSnackbarOpen(true));
  // dispatch(setSuccessSnackbarMessage("Системээс гарлаа"));
};
export const getUserData = () => async (dispatch, getState) => {
  //console.log(getToken);
  //console.log(role);

  const getUserData = await getUser();

  if (getUserData.status.code === 200) {
    dispatch(setUser(getUserData.data));
    dispatch(setRole(getUserData.data.role));
    dispatch(setLoading(false));
  } else {
    dispatch(setLoading(false));
    if (getUserData.status.code === 401) {
      notify(
        {
          message: getUserData.status.message,
          width: 450,
        },
        "error"
      );
      dispatch(setLoading(false));
      dispatch(logout());
    }
  }

  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserListData = (filter) => async (dispatch, getState) => {
  try {
    // console.log("---------");
    //console.log("dffddfdffff");
    const responseData = await userDataListCall(filter);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setUserListData(responseData.data));
      // dispatch(setLoading(false));
      dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setUserListData(1));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
// export const getUserTypeList = () => async (dispatch, getState) => {
//   try {
//     const responseData = await getUserTypeListCall();

//     if (responseData.status.code === 200) {
//       dispatch(setUserTypeListData(responseData.data));
//     } else {
//       dispatch(setUserListData(1));
//       notify(
//         {
//           message: responseData.status.message,
//           width: 450,
//         },
//         "error"
//       );
//     }

//     //console.log(alert);
//   } catch (error) {
//     console.log(error);
//   }

//   dispatch(setLoading(false));
//   // dispatch(setUser(null));
//   // localStorage.removeItem("token");
// };
export const getUserProfileData = (userId) => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await userProfileDataCall(userId);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      //  console.log(responseData.data);
      dispatch(setUserProfile(responseData.data));
      dispatch(setIsUserEmployeeEdit(false));
      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setIsUserEmployeeEdit(false));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const userProfileEditData =
  (formData, branchId) => async (dispatch, getState) => {
    try {
      // console.log("---------");
      // console.log(filter);
      const responseData = await userProfileEditDataCall(formData);
      //  console.log("responseData);
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setIsUserEmployeeEdit(false));
        dispatch(setFilter({ ...getState.filter, branch_id: branchId }));
        notify(
          {
            message: "Ажилтаны профайл амжилттай засагдлаа",
            width: 450,
          },
          "success"
        );
      } else {
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const editUser = (formData, config) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await userEditCall(formData);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      if (config === 1) {
        dispatch(setCreateUser(responseData.data));
      }
      dispatch(setNewUserId(responseData.data.id));
      dispatch(setNewEmployeeData(responseData.data.employee[0]));
      dispatch(setIsUserEmployeeEdit(false));
      //dispatch(getUserListData(getState.filter));
      BaseHelper.nodifyFun(responseData.status.message, "success");
      //  console.log(responseData.data);
      // dispatch(setCompanySearchData(responseData.data));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setIsUserEmployeeEdit(false));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const createEmployee = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await createEmployeeCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      BaseHelper.nodifyFun(responseData.status.message, "success");
      dispatch(setUserListData(responseData.data));
      dispatch(
        setFilter({
          company_id: responseData.data["employee"][0]["company_id"],
        })
      );
      return true;
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      return false;
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const updateEmployee = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await updateEmployeeCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setUserListData(responseData.data));
      // console.log(responseData.data["employee"][0]["company_id"]);
      dispatch(
        setFilter({
          company_id: responseData.data["employee"][0]["company_id"],
        })
      );
      //  dispatch(getUserListData({}));
      return true;
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      return false;
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const deleteEmployee = (data, id) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await deleteEmployeeCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      BaseHelper.nodifyFun(responseData.status.message, "success");
      // console.log(responseData.data);
      dispatch(
        setFilter({
          company_id: id,
        })
      );
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const deleteUser = (data, config) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await userDeleteCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setRemoveUser(data.id));
      //  dispatch(setIsUserEmployeeEdit(false));
      BaseHelper.nodifyFun(responseData.status.message, "success");
      //  console.log(responseData.data);
      // dispatch(setCompanySearchData(responseData.data));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      // dispatch(setIsUserEmployeeEdit(false));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const PusherCall = (data) => async (dispatch, getState) => {
  try {
    const responsePusher = await requestPusher({ data });
    dispatch(setAlert(responsePusher));
    // console.log(process.env.REACT_APP_PUSHER_KEY);
  } catch (err) {
    console.log(err);
    dispatch(setLoading(false));
    dispatch(
      setAlert({
        success: false,
        message: "Нэвтрэх явцад алдаа гарлаа",
      })
    );
    return null;
  }
};
export const getUserLocation = (data) => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserLocationCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      dispatch(setUserLocation(responseData.data));
      //   console.log(responseData.data);
      // if (isParent === 1) {
      //   dispatch(setUserLocation(responseData.data));
      // } else if (isParent === 2) {
      //   dispatch(setUserLocationDist(responseData.data));
      // } else {
      //   dispatch(setUserLocationHoroo(responseData.data));
      // }

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserLocationDist = (data) => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserLocationDistCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      dispatch(setUserLocationDist(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserLocationHoroo = (data) => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserLocationHorooCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      dispatch(setUserLocationHoroo(responseData.data));
      //   console.log(responseData.data);
      // if (isParent === 1) {
      //   dispatch(setUserLocation(responseData.data));
      // } else if (isParent === 2) {
      //   dispatch(setUserLocationDist(responseData.data));
      // } else {
      //   dispatch(setUserLocationHoroo(responseData.data));
      // }

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserUserNationalityData = () => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserUserNationalityCall();
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setUserNationalityData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserSocietyData = () => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserSocietyCall();
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setUserSocietyData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserSexData = () => async (dispatch, getState) => {
  try {
    // console.log("---------");
    // console.log(filter);
    const responseData = await getUserSexCall();
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setUserSexData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getEmployeeInfoData = (data) => async (dispatch, getState) => {
  try {
    const responseData = await getEmployeeInfoCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setEmployeeDataList(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const userEmployeeEditData = (data) => async (dispatch, getState) => {
  try {
    const responseData = await userEmployeeEditCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      dispatch(setIsUserEmployeeEdit(false));

      BaseHelper.nodifyFun("Ажилтны мэдээлэл амжилттай засагдлаа", "success");
    } else {
      dispatch(setIsUserEmployeeEdit(false));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserJobposition = () => async (dispatch, getState) => {
  try {
    const responseData = await getUserJobpositionCall();
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setUserJobposition(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserModuleData = (userId) => async (dispatch, getState) => {
  try {
    const responseData = await getUserModuleCall(userId);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setUserModuleData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const getUserTypeModuleDataFun =
  (userTypeId) => async (dispatch, getState) => {
    try {
      // console.log(userTypeId);
      const responseData = await getUserTypeModuleCall(userTypeId);
      //  console.log("responseData);
      if (responseData.status.code === 200) {
        //  console.log(responseData.data);

        dispatch(setUserTypeModuleData(responseData.data));

        // dispatch(setLoading(false));
        // dispatch(setRole(responseData.role));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setUserTypeModuleData(null));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const setUserPermission = (data) => async (dispatch, getState) => {
  try {
    const responseData = await setUserPermissionCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      //  console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setUserModuleData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const setUserTypePermission = (data) => async (dispatch, getState) => {
  try {
    const responseData = await setUserTypePermissionCall(data);
    //  console.log("responseData);
    if (responseData.status.code === 200) {
      //  console.log(responseData);

      BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setUserModuleData(responseData.data));

      // dispatch(setLoading(false));
      // dispatch(setRole(responseData.role));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export default slice.reducer;
