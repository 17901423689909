import { Box, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
  ColumnFixing,
  ColumnChooser,
  LoadPanel,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";
import {
  getInspectorReportFun,
  getLegalProvisionReportFun,
  getPenaltyReportFun,
  getVehicleCountBuildReportFun,
  getVehicleCountMarkReportFun,
  getVehicleDriverReportFun,
  getViolationDetectionReportFun,
  getViolationTypeReportFun,
  setFilter,
  setRemFilter,
  setVehicleCountBuildReportData,
} from "../../../store/reducers/report";
import { getPorkurorReportFun } from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";
import moment from "moment";

const VehicleDriverReportList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getVehicleDriverReportData = useSelector(
    (state) => state.report.vehicleDriverReportData
  );
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const start = moment().add(-4, "y");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  // console.log(start);
  // console.log(moment().year() - 1);
  useEffect(() => {
    gridRef.current.instance.beginCustomLoading();
    if (getVehicleDriverReportData == null) {
      dispatch(getVehicleDriverReportFun(filter));
    } else {
      gridRef.current.instance.endCustomLoading();
      setFormData(getVehicleDriverReportData);
      // dispatch(setRemFilter());
    }
  }, [getVehicleDriverReportData, filter, dispatch]);
  //console.log(getVehicleDriverReportData);
  // useEffect(() => {
  //   if (getPorkurorReportData == null && Object.keys(filter).length > 0) {
  //     dispatch(getPorkurorReportFun(filter));
  //   }
  // }, [getPorkurorReportData, filter, dispatch]);
  //console.log(getInspectorReportData);

  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getVehicleDriverReportFun(filter));
    }
  }, [filter, dispatch]);

  //console.log(props);
  // useEffect(() => {
  //   gridRef.current.instance.beginCustomLoading();
  //   if (props.porkurorReportListData) {
  //     setFormData(props.porkurorReportListData);
  //     gridRef.current.instance.endCustomLoading();
  //   }
  // }, [props]);
  // console.log(props.feedBackListData);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  // const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
  //   // console.log("selectedRowKeys");
  //   // console.log(selectedRowsData[0].id);
  //   setSelectIndex(selectedRowKeys[0]);
  //   setIsType(2);
  //   setFormEditData(selectedRowsData[0]);
  // };
  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }

  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        wordWrapEnabled={false}
        height={600}
        //summary={}
        // columns={gridColumns}
      >
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
          fixed={true}
        />
        <Column caption="Байгуулллага" fixed={true} alignment={"center"}>
          <Column
            dataField="company_regnum"
            width={130}
            format="fixedPoint"
            caption={"ААНБ-н регистер"}
          />
          <Column
            dataField="company_name"
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          />
        </Column>
        <Column caption="Тээврийн хэрэгсэл" alignment={"center"}>
          <Column
            dataField="plate_no"
            width={130}
            format="fixedPoint"
            caption={"Улсын дугаар"}
          />

          <Column
            dataField="garage_no"
            format="fixedPoint"
            caption={"Гарааш дугаар"}
          />
          <Column dataField="mark_name" format="fixedPoint" caption={"Марк"} />
          <Column
            dataField="model_name"
            format="fixedPoint"
            caption={"Загвар"}
          />
          <Column dataField="color_name" format="fixedPoint" caption={"Өнгө"} />
          <Column
            dataField="engine_model_name"
            format="fixedPoint"
            caption={"Моторын загвар"}
          />
          <Column
            dataField="fuel_type_name"
            format="fixedPoint"
            caption={"Шатхууны төрөл"}
          />
        </Column>
        <Column caption="Жолооч" alignment={"center"}>
          <Column
            dataField="lastname"
            width={130}
            format="fixedPoint"
            caption={"Оврг"}
          />
          <Column dataField="firstname" format="fixedPoint" caption={"Нэр"} />
          <Column
            dataField="register"
            format="fixedPoint"
            caption={"Регистер"}
          />
          <Column dataField="age" format="fixedPoint" caption={"Нас"} />
          <Column dataField="sex" format="fixedPoint" caption={"Хүйс"} />
          <Column
            dataField="dl_b"
            format="fixedPoint"
            caption={"Жолоо ангилал"}
            cellRender={(v) => {
              var data = [];
              if (v.row.data.dl_a !== null) {
                data.push("A,");
              }
              if (v.row.data.dl_b !== null) {
                data.push("B,");
              }
              if (v.row.data.dl_c !== null) {
                data.push("C,");
              }
              if (v.row.data.dl_d !== null) {
                data.push("D,");
              }
              if (v.row.data.dl_e !== null) {
                data.push("E,");
              }
              // console.log(v);
              return data.map((e) => e);
              // return e.driver_license.classification.map((e) =>
              //   e.started_at != null ? e.code : ""
              // );
            }}
          />
          <Column
            dataField="pdl_number"
            //  format="fixedPoint"
            caption={"Мэрэгшсэн ангилал"}
            allowEditing={false}
            cellRender={(v) => {
              var data = [];
              if (v.row.data.class_b !== null) {
                data.push("B");
              }
              if (v.row.data.class_c !== null) {
                data.push("C");
              }
              if (v.row.data.class_d !== null) {
                data.push("D");
              }
              if (v.row.data.class_inter_passenger !== null) {
                data.push("УХЗТ");
              }
              if (v.row.data.class_e !== null) {
                data.push("E");
              }
              // console.log(v);
              return data.map((e) => e);
              // return e.driver_license.classification.map((e) =>
              //   e.started_at != null ? e.code : ""
              // );
            }}
          />
        </Column>
        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default VehicleDriverReportList;
