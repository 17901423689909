import { Box, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";

import {
  getPorkurorReportFun,
  reportGrievanceConfirmedFun,
  reportGrievanceFun2,
  setFilter,
} from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";
import moment from "moment";

const GrievanceReport2List = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const grievanceList2 = useSelector((state) => state.report.grievanceList2);
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const start = moment().add(-4, "y");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  // console.log(start);
  // console.log(moment().year() - 1);
  //console.log(grievanceConfirmedList);
  //console.log(grievanceList2);
  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (grievanceList2 == null) {
      dispatch(reportGrievanceFun2(filter));
    } else {
      //  gridRef.current.instance.endCustomLoading();
      setFormData(grievanceList2);
      // dispatch(setRemFilter());
    }
  }, [grievanceList2, filter, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(reportGrievanceFun2(filter));
    }
  }, [filter, dispatch]);
  //   useEffect(() => {
  //     //console.log(Object.keys(filter).length);
  //     if (Object.keys(filter).length > 0) {
  //       dispatch(getVehicleDriverReportFun(filter));
  //     }
  //   }, [filter, dispatch]);

  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }

  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                //   dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        selection={false}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        wordWrapEnabled={false}
        height={600}
        //summary={}
        // columns={gridColumns}
      >
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
          fixed={true}
        />

        <Column
          dataField="name"
          width={"auto"}
          format="fixedPoint"
          caption={"Туслах сэдэв"}
        />

        <Column caption="Шийдвэрлэх шатандаа байгаа" alignment={"center"}>
          <Column caption="Хугацаандаа" alignment={"center"}>
            <Column
              dataField="implementation.ontime.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="implementation.ontime.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
          <Column
            dataField="color_name"
            format="fixedPoint"
            caption={"Хугацаа хэтэрсэн"}
          >
            {" "}
            <Column
              dataField="implementation.overdue.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="implementation.overdue.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
          <Column format="fixedPoint" caption={"Бүгд"}>
            <Column
              dataField="implementation.total.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="implementation.total.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
        </Column>
        <Column caption="Шийдвэрлэж хариу өгсөн" alignment={"center"}>
          <Column caption="Хугацаандаа" alignment={"center"}>
            <Column
              dataField="closed.ontime.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="closed.ontime.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
          <Column
            dataField="color_name"
            format="fixedPoint"
            caption={"Хугацаа хэтэрсэн"}
          >
            {" "}
            <Column
              dataField="closed.overdue.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="closed.overdue.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
          <Column format="fixedPoint" caption={"Бүгд"}>
            <Column
              dataField="closed.total.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="closed.total.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
        </Column>
        <Column caption="Бүгд" span={2} alignment={"center"}>
          <Column alignment={"center"}>
            <Column
              dataField="total.count"
              format="fixedPoint"
              allowSorting={true}
              caption={"Тоо"}
            />
            <Column
              dataField="total.percent"
              format="fixedPoint"
              caption={"%"}
              cellRender={(v) => {
                return v.value + "%";
              }}
            />
          </Column>
        </Column>

        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default GrievanceReport2List;
