import { createSlice } from "@reduxjs/toolkit";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
import {
  violationDataCall,
  violationDeletetCall,
  violationEditCall,
} from "../../utils/services/violationService";
import moment from "moment";
import BaseHelper from "../../helper/BaseHelper";
export const slice = createSlice({
  name: "violation",
  initialState: {
    violationData: null,
    filter: {
      // start_date: moment().year() + "-01-01",
      // end_date: moment().year() + "-01-21",
    },
    isLoading: true,
  },

  reducers: {
    setFilter: (state, { payload }) => {
      // console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },

    setViolationData: (state, { payload }) => {
      //console.log(payload);
      state.violationData = payload;
    },
  },
});

export const {
  setLoading,

  setViolationData,

  setFilter,
} = slice.actions;

export const getViolation = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await violationDataCall(filter);
    //console.log(responseData);
    if (responseData.status.code === 200) {
      //console.log(responseData);
      dispatch(setViolationData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setViolationData(null));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setViolationData(null));
    console.log(error);
  }
};
export const violationEditFun = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const responseData = await violationEditCall(data);
    // console.log(violation/edit);
    if (responseData.status.code === 200) {
      // console.log(responseData);
      dispatch(getViolation());
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const violationDeleteFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await violationDeletetCall(data);
    // console.log(violation/edit);
    if (responseData.status.code === 200) {
      // console.log(responseData);
      dispatch(getViolation());
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;
