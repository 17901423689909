import React, { useEffect, useState } from "react";

import Map from "devextreme-react/map";

import { markersData } from "./data.js";
import { useDispatch } from "react-redux";

const apiKey = {
  bing: "AnnJt1w_0vAp5KWXagbYJYf2L5airoLS_kMCxulYQaJjnPot-HYarZpK-lblOwXl",
};
export const routesData = [
  {
    weight: 6,
    color: "blue",
    opacity: 0.5,
    mode: "",
    // locations: [
    //   [40.7825, -73.966111],
    //   [40.755833, -73.986389],
    //   [40.753889, -73.981389],
    //   "Brooklyn Bridge,New York,NY",
    // ],
  },
];
export default function MapRoutes(props) {
  const dispatch = useDispatch();
  const [routes, setRoutes] = React.useState(routesData);
  //   const [locRouteDrive, setLocRouteDrive] = useState({
  //     weight: 6,
  //     color: "blue",
  //     opacity: 0.5,
  //     mode: "",
  //     locations: null,
  //   });

  const [markersDataList, setmMrkersDataList] = React.useState(markersData);
  const dataMarkerArray = [];
  const tt = [];
  const routesDataA = {
    weight: 6,
    color: "blue",
    opacity: 0.5,
    mode: "",
  };
  useEffect(() => {
    if (props.dataMarker !== null) {
      const data = props.dataMarker.stop;
      //   Object.keys(data).map((key) =>
      //     console.log(data[key])
      //     //  {key}: {subject[key]}
      //   );

      data.map((stops, key) => {
        var dataMarker = {
          location: [stops.lat, stops.lng],
          tooltip: {
            text: stops.name,
          },
        };

        //tt["lng"] = stops.lng;
        dataMarkerArray.push(dataMarker);
        tt.push([stops.lat, stops.lng]);

        //  routesDataArray.push([stops.lat, stops.lng]);
        // setLocRouteDrive({
        //   ...locRouteDrive,
        //   locations: [stops.lat, stops.lng],
        // });
        // console.log(stops);
      });
      //  routesData["locations"] = routesDataArray;
      //console.log(dataMarkerArray);
      routesDataA["locations"] = tt;
      // console.log([routesDataA]);
      setmMrkersDataList(dataMarkerArray);
      // setRoutes([routesDataA]);
    } else {
      setmMrkersDataList(markersData);
    }
  }, [props]);
  // console.log(markersDataList);
  return (
    <div>
      <Map
        defaultZoom={22}
        height={400}
        width="100%"
        controls={true}
        markers={markersDataList}
        routes={routes}
        provider="bing"
        apiKey={apiKey}
      />
    </div>
  );
}
