//import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { Box } from "devextreme-react";

import {
  Chart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  Grid,
  Crosshair,
  Export,
  Legend,
  Point,
  Label,
  Font,
  Title,
  Subtitle,
  Tooltip,
} from "devextreme-react/chart";
import { populationData } from "../data/chartData";
import { energySources, countriesInfo } from "../data/lineChartData";
import { useEffect, useState } from "react";
const LineChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props != null) {
      setData(props.dashboardData);
    }
  }, [props]);
  // console.log(props);
  return (
    <>
      <Chart
        style={{
          height: "350px",
          margin: "18px",
        }}
        // title="World Population by Decade"
        dataSource={data != null ? data.grievance.line_chart.data : []}
        id="chart"
      >
        <CommonSeriesSettings type="spline" argumentField="serial_key">
          <Point hoverMode="allArgumentPoints" />
        </CommonSeriesSettings>
        {data != null
          ? data.grievance.line_chart.category.map((item) => (
              <Series
                key={item.value}
                valueField={item.value}
                name={item.name}
              />
            ))
          : []}
        <ArgumentAxis
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
          <Label visible={true} backgroundColor="#949494">
            <Font color="#fff" size={12} />
          </Label>
        </Crosshair>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
          equalColumnWidth={true}
        />
        {/* <Title text="Energy Consumption in 2004">
          <Subtitle text="(Millions of Tons, Oil Equivalent)" />
        </Title> */}
        <Export enabled={false} />
        <Tooltip enabled={true} />
      </Chart>
    </>
  );
};

export default LineChart;
