import { Box, useTheme, Button as Btn, Stack, Typography } from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
  RemoteOperations,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
//import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";

import "./style.css";

import useMediaQuery from "@mui/material/useMediaQuery";

import CompanyEdit from "./companyEdit";
import CompanyAdd from "./companyAdd";
import { useDispatch, useSelector } from "react-redux";
import StelektonListData from "../../utils/skeletonList copy";
import {
  editCompany,
  getCompany,
  removeBranchList,
} from "../../store/reducers/company";
import notify from "devextreme/ui/notify";

import { TreeList } from "devextreme-react";
import {
  RequiredRule,
  RowDragging,
  SearchPanel,
  Toolbar,
  Item,
} from "devextreme-react/tree-list";
import {
  branchAddFun,
  branchDeleteFun,
  branchTreeListFun,
} from "../../store/reducers/employe";
import Guid from "devextreme/core/guid";
const BranchList = (props) => {
  const theme = useTheme();
  const treeListRef = useRef();
  const [currentEmployees, setCurrentEmployees] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState();
  const dispatch = useDispatch();
  // console.log(props.companyList);
  const [expanded, setExpanded] = useState(true);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [editColumnName, setEditColumnName] = useState(null);
  const [editRowKey, setEditRowKey] = useState(null);
  const [changes, setChanges] = useState([]);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState(false);
  const [compId, setCompId] = useState("");
  const [parentId, setParentId] = useState("");
  const [formData, setFormData] = useState({});
  const [editRowData, setEditRowData] = useState(null);
  const getIsLoading = useSelector((state) => state.employe.isLoading);
  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);
  const onAddButtonClick = React.useCallback((e) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);
  const onRowInserted = (e) => {
    console.log(e);
    e.component.navigateToRow(e.key);
  };
  useEffect(() => {
    if (getIsLoading === true) {
      treeListRef.current.instance.beginCustomLoading();
    } else {
      treeListRef.current.instance.endCustomLoading();
    }
  }, [getIsLoading]);

  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (props.branchList) {
      //   var tempProps = JSON.parse(JSON.stringify(props.branchList));

      setCurrentEmployees(props.branchList);
      setCompId(props.companyId);
      //  gridRef.current.instance.endCustomLoading();
    }
  }, [props]);

  //   function setChanges() {
  //     console.log(treeListRef.current.instance.saveEditData());
  //   }
  function insertRowRemote(dataObj) {
    treeListRef.current.instance
      .store()
      .insert(dataObj)
      .then(() => treeListRef.current.instance.reload());
  }
  function onSaving(e) {
    //  e.cancel = true;
    // console.log(e.changes[0] !== undefined);
    if (e.changes[0] !== undefined) {
      if (e.changes[0].type === "insert") {
        console.log("insert");
        // console.log(e.changes[0]);
        // setChanges(e.changes);
        // const data = { company_id: 1 };
        // dispatch(branchTreeListFun(data));

        //  setFormData(e.changes[0].data);
        const data = e.changes[0].data;
        data["company_id"] = compId;
        data["id"] = 0;
        data["is_active"] = 1;
        dispatch(branchAddFun(data));
        // e.component.cancelEditData();
        //  treeListRef.current.instance.closeEditCell();
        // insertRowRemote(e.changes[0].data);
        //  setChanges(e.changes);
        // console.log(e);
        //if (e.changes > 0) {
        e.cancel = true;
        // treeListRef.current.instance.closeEditCell();
        e.component.cancelEditData();
        // }
        // setFormData({ ...formData, company_id: compId });
        // console.log(data);
        // e.promise = saveChange(dispatch, e.changes[0]);
      } else if (e.changes[0].type === "update") {
        console.log("update");
        //  console.log(e.changes[0].data);
        const data = e.changes[0].data;
        data["company_id"] = compId;
        data["id"] = e.changes[0].key;
        data["is_active"] = 1;
        data["parent_id"] = parentId;
        if ("name" in data === false) {
          data["name"] = editRowData.name;
        }
        if ("rank" in data === false) {
          data["rank"] = editRowData.rank;
        }
        dispatch(branchAddFun(data));
        // treeListRef.current.instance.refresh();
        treeListRef.current.instance.cancelEditData();
        //setChanges(e.changes);
        // treeListRef.current.instance.editCanceled();
        e.cancel = true;
      } else if (e.changes[0].type === "remove") {
        console.log("delete");

        const data = { id: e.changes[0].key, company_id: compId };
        dispatch(branchDeleteFun(data));
        e.cancel = true;
      }
    } else {
      notify(
        {
          message: "Мэдээлэл шинчлээгүй байна!!!",
          width: 450,
        },
        "error"
      );
    }

    //setData(e.changes[0]);
    // setChanges([]);
    // setEditColumnName(null);
    // setEditRowKey(null);

    // console.log(data);
  }
  //console.log(props.companyId);

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    console.log(selectedRowsData[0]);
  };
  function initNewRow(e) {
    // e.data.name = "Not Started";
    // e.data.Task_Start_Date = new Date();
    // e.data.Task_Due_Date = new Date();
  }
  function onEditorPreparing(e) {
    // treeListRef.current.instance.selectedRowKeys();

    e.allowEditing === true ? setEditRowData(e.row.data) : setEditRowData(null);

    if (e.dataField === "parent_id" && e.row.data.id === 1) {
      e.cancel = true;

      // console.log(e);
    }
  }

  function onInitNewRow(e) {
    e.data.parent_id = parseInt(e.data.parent_id);
  }
  return (
    <>
      <TreeList
        id="tree-list"
        ref={treeListRef}
        dataSource={currentEmployees}
        keyExpr="id"
        parentIdExpr="parent_id"
        height={480}
        // hasItemsExpr="Has_Items"
        //  defaultExpandedRowKeys={expandedKeys}
        rowAlternationEnabled={true}
        autoExpandAll={true}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        onEditorPreparing={onEditorPreparing}
        onInitNewRow={(e) => {
          console.log(e);
        }}
        onSelectionChanged={onSelectionChanged}
        onSaving={onSaving}
        onSaved={(e) => {
          console.log(e);
        }}
        changes={changes}
        onEditingStart={(e) => {
          //  console.log(e.data.parent_id);
          setParentId(e.data.parent_id);
        }}

        // onEditRowKeyChange={onEditRowKeyChange}
      >
        <RemoteOperations filtering={true} sorting={true} grouping={true} />
        {/* <TreeList
        autoExpandAll={expanded}
        ref={treeListRef}
        id="employees"
        dataSource={currentEmployees}
        //  defaultExpandedRowKeys={[1]}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        itemsExpr="child"
        dataStructure="tree"
        onEditorPreparing={
          // (e) => {
          // console.log(e.row.data);
          setectDataFun
          // console.log(treeListRef.current.instance.selectRows());
        }
        onRowInserting={(e) => {
          console.log(e);
        }}
        onOptionChanged={onOptionChanged}
        onSaving={onSaving}
        onRowDblClick={(row) => {
          console.log(row);
          treeListRef.current.instance.editRow();
        }}
        onSelectionChanged={onSelectionChanged}
        // onCellClick={() => {
        //   addNewRow();
        // }}
      > */}
        <Toolbar>
          <Item location="before">
            <Typography sx={{ p: 1 }} variant="h4">
              Хэлтэс жагсаалт
            </Typography>
            {/* // <Button
            //   text={expanded ? "Collapse All" : "Expand All"}
            //   width={136}
            //   onClick={() => {
            //     setExpanded((prevExpanded) => !prevExpanded);
            //     setExpandedRowKeys([]);
            //   }}
            // /> */}
          </Item>
          <Item name="addRowButton" showText="always" />
          <Item name="exportButton" />
          <Item name="columnChooserButton" />
          <Item name="searchPanel" />
        </Toolbar>
        <Selection mode="single" />
        <Column
          allowSorting={true}
          caption="#"
          width={60}
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        />
        <Column dataField="name" caption={"Хэлтсийн нэр"}>
          <RequiredRule />
        </Column>
        {/* <Column dataField="code" caption={"Дугаар"}>
          <RequiredRule />
        </Column> */}
        <Column dataField="rank" caption={"Эрэмбэ"}>
          <RequiredRule />
        </Column>

        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Editing
          mode="row"
          //  popup={{ width: 500, height: 200 }}
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          //  allowUpdating={true}
          useIcons={true}
          changes={changes}
          onChangesChange={setChanges}
          editRowKey={editRowKey}
          onEditRowKeyChange={setEditRowKey}
          editColumnName={editColumnName}
          onEditColumnNameChange={setEditColumnName}
          // newRowPosition={newRowPosition}
          //   onChangesChange={(e) => {
          //     console.log(e);
          //   }}
        >
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Мэдээлэл устгах"}
            saveRowChanges={"Хадгалах"}
            saveRowToNode={"ffff"}
            cancelRowChanges={"Цуцлах"}
            deleteRow="Устгах"
            addRow="Нэмэх"
            editRow="Засах"
            addRowToNode="Нэмэх"
          />
        </Editing>
        {/* <Form>
            <Item itemType="group" colSpan={2}>
              <Item
                dataField="name"
                // editorOptions={{ width: 200, height: 40 }}
              />
            </Item>
          </Form> */}
        {/* <RowDragging
          //  onDragChange={onDragChange}
          //  onReorder={onReorder}
          allowDropInsideItem={true}
          allowReordering={true}
          showDragIcons={true}
        /> */}
        <Paging enabled={true} defaultPageSize={10} />
      </TreeList>
    </>
  );
  // }
};

export default BranchList;
