import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import auth from "./reducers/user/auth";

import vehicle from "./reducers/vehicle";
import driver from "./reducers/driver";
import uiReducer from "./reducers/uiReducer";
import company from "./reducers/company";
import employe from "./reducers/employe";
import requestVehicle from "./reducers/requestVehicle";
import requestDriver from "./reducers/requestDriver";
import feedBack from "./reducers/feedBack";
import metaCrud from "./reducers/metaCrud";
import violation from "./reducers/violation";
import report from "./reducers/report";
//import logger from './middleware/logger';

export const store = configureStore({
  reducer: {
    auth,
    uiReducer,
    vehicle,
    driver,
    company,
    employe,
    requestVehicle,
    requestDriver,
    feedBack,
    metaCrud,
    violation,
    report,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  //concat(logger)
});
