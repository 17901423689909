import { createSlice } from "@reduxjs/toolkit";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
import { getFeedBackTypeCall } from "../../utils/services/feedBackService";
import {
  companyTypeDataCall,
  getCompanyTypeEditAddCall,
  getTransportRouteCall,
  getTransportRouteStopCall,
  getTransportRouteStopDeleteCall,
  getTransportRouteStopEditCall,
  getTransportStopListCall,
  getTransportStopListEditCall,
  getTransportZoneAddEditCall,
  getTransportZoneCall,
  getUserTypeListCall,
  getViolationCitizenTypeDataCall,
  getViolationCitizenTypeEditCall,
  getViolationCompanyCrudCall,
  getViolationCompanyEditCall,
  getViolationDecisionTypeCall,
  getViolationDecisionTypeEditCall,
  getViolationDetectionTypeCall,
  getViolationDetectionTypeEditCall,
  getViolationLegalPenaltyCall,
  getViolationLegalPenaltyEditCall,
  getViolationLegalProvisionCall,
  getViolationLegalProvisionEditCall,
  getViolationOffenderCall,
  getViolationOffenderEditCall,
  getViolationTypeDataCall,
  getViolationTypeEditCall,
  getViolationVehicleCrudCall,
  getViolationVehicleEditCall,
  getviolationServiceTypeCrudCall,
  getviolationServiceTypeCrudEditCall,
  transportRouteAddEditCall,
  userNationalityTypeAddEditCall,
  userPositionTypeAddEditCall,
  userSexTypeAddEditCall,
  userSocietyTypeAddEditCall,
  userTypeAddEditCall,
  vehicleSearchCall,
  vehicleSearchDataCall,
} from "../../utils/services/metaCrudService";
import {
  getUserJobposition,
  getUserSexData,
  getUserSocietyData,
  getUserUserNationalityData,
} from "./user/auth";
import BaseHelper from "../../helper/BaseHelper";
export const slice = createSlice({
  name: "metaCrud",
  initialState: {
    companyTypeData: null,
    transportZoneData: null,
    userTypeListData: null,
    transportRouteListData: null,
    violationCitizenTypeData: null,
    violationTypeData: null,
    violationDecisionType: null,
    violationDetectionType: null,
    violationLegalPenalty: null,
    violationServiceTypeCrud: null,
    transportStopListData: null,
    transportRouteStopList: null,
    violationLegalProvisionData: null,
    violationOffenderData: null,
    vehicleSearchData: null,

    violationVehicleData: null,
    violationCompanyData: null,
    violationVehicleFilter: {},
    violationCompanyFilter: {},
    isLoading: false,
  },

  reducers: {
    setCompanyTypeData: (state, { payload }) => {
      //console.log(payload);
      state.companyTypeData = payload;
    },
    setTransportZoneData: (state, { payload }) => {
      //console.log(payload);
      state.transportZoneData = payload;
    },

    setCreateCompanyTypeData: (state, { payload }) => {
      // console.log(payload);
      state.companyTypeData.unshift(payload);
    },
    setUserTypeListData: (state, { payload }) => {
      state.userTypeListData = payload;
    },
    setTransportRouteListData: (state, { payload }) => {
      state.transportRouteListData = payload;
    },
    setViolationCitizenTypeData: (state, { payload }) => {
      state.violationCitizenTypeData = payload;
    },
    setViolationTypeData: (state, { payload }) => {
      state.violationTypeData = payload;
    },
    setViolationDecisionType: (state, { payload }) => {
      state.violationDecisionType = payload;
    },
    setViolationDetectionType: (state, { payload }) => {
      state.violationDetectionType = payload;
    },
    setViolationLegalPenalty: (state, { payload }) => {
      state.violationLegalPenalty = payload;
    },
    setViolationServiceTypeCrud: (state, { payload }) => {
      state.violationServiceTypeCrud = payload;
    },
    setTransportStopListData: (state, { payload }) => {
      state.transportStopListData = payload;
    },
    setTransportRouteStopList: (state, { payload }) => {
      state.transportRouteStopList = payload;
    },
    setViolationLegalProvisionData: (state, { payload }) => {
      state.violationLegalProvisionData = payload;
    },
    setViolationVehicleData: (state, { payload }) => {
      state.violationVehicleData = payload;
    },
    setViolationCompanyData: (state, { payload }) => {
      state.violationCompanyData = payload;
    },
    setViolationOffenderData: (state, { payload }) => {
      state.violationOffenderData = payload;
    },
    setViolationOffenderClear: (state) => {
      // console.log("dfgsdggddddddddd");
      state.violationOffenderData = null;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setEditFeedBack: (state, { payload }) => {
      // console.log(payload);
      let listCopy = [...state.feedBackData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.phone_number = payload.phone_number;
        }
      });
    },
    setViolationVehicleFilter: (state, { payload }) => {
      //console.log(payload);
      state.violationVehicleFilter = payload;
    },
    setViolationCompanyFilter: (state, { payload }) => {
      //console.log(payload);
      state.violationCompanyFilter = payload;
    },
    setVehicleSearchData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleSearchData = payload;
    },
    setRemoveFeedBack: (state, { payload }) => {
      // console.log(payload);

      let index = state.feedBackData.findIndex(({ id }) => id === payload);
      state.feedBackData.splice(index, 1);
    },
  },
});

export const {
  setLoading,
  setCompanyTypeData,
  setUserTypeListData,
  setTransportRouteListData,
  setTransportZoneData,
  setViolationCitizenTypeData,
  setViolationTypeData,
  setViolationDecisionType,
  setViolationDetectionType,
  setViolationLegalPenalty,
  setViolationServiceTypeCrud,
  setTransportStopListData,
  setTransportRouteStopList,
  setViolationLegalProvisionData,
  setViolationOffenderData,
  setViolationVehicleData,
  setViolationVehicleFilter,
  setViolationCompanyFilter,
  setViolationCompanyData,
  setVehicleSearchData,
  setViolationOffenderClear,
} = slice.actions;

export const getViolationVehicleCrud =
  (violationVehicleFilter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationVehicleCrudCall(
        violationVehicleFilter
      );
      //  console.log(responseData);
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(setViolationVehicleData(responseData.data));
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationCompanyCrudFun =
  (violationCompanyFilter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationCompanyCrudCall(
        violationCompanyFilter
      );
      //  console.log("kkkkkkk");
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(setViolationCompanyData(responseData.data));
        dispatch(setLoading(false));
        // BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationVehicleEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationVehicleEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        //dispatch(getViolationCompanyCrudFun(violationCompanyFilter));
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationCompanyEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationCompanyEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(getViolationCompanyCrudFun({ is_basic: 0 }));
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getCompanyTypeData = () => async (dispatch, getState) => {
  try {
    const responseData = await companyTypeDataCall();
    //  console.log("kkkkkkk");
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setCompanyTypeData(responseData.data));
      dispatch(setLoading(false));
      //BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getTransportRouteCall();
    //  transport/route/list
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setTransportRouteListData(responseData.data));
      dispatch(setLoading(false));
      // BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getViolationOffenderEditFun =
  (data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const responseData = await getViolationOffenderEditCall(data);
      //  transport/route/list
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(getViolationOffenderFun());
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationOffenderFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await getViolationOffenderCall(data);
    //  transport/route/list
    // console.log(responseData);
    dispatch(setLoading(true));
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setViolationOffenderData(responseData.data));
      dispatch(setLoading(false));
      //  BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setLoading(false));
      dispatch(setViolationOffenderClear());

      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setViolationOffenderClear());
    console.log(error);
  }
};
export const getVehicleSearchDataFun = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const responseData = await vehicleSearchDataCall(data);
    //  transport/route/list
    //console.log(responseData);
    dispatch(setLoading(true));
    if (responseData.status.code === 200) {
      //console.log(responseData.data);
      dispatch(setVehicleSearchData(responseData.data));
      dispatch(setLoading(false));
      // BaseHelper.nodifyFun(responseData.status.message, "success");
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setLoading(false));
      // dispatch(setViolationOffenderClear());
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setViolationOffenderClear());
    console.log(error);
  }
};
export const getViolationLegalProvisionFun =
  () => async (dispatch, getState) => {
    try {
      const responseData = await getViolationLegalProvisionCall();
      //  transport/route/list
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(setViolationLegalProvisionData(responseData.data));
        dispatch(setLoading(false));
        //  BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationLegalProvisionEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationLegalProvisionEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData.status.code === 200) {
        //console.log(responseData.data);
        dispatch(getViolationLegalProvisionFun());
        dispatch(setLoading(false));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getCompanyTypeEditAddFun =
  (data) => async (dispatch, getState) => {
    try {
      // console.log(filter);
      const responseData = await getCompanyTypeEditAddCall(data);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(getCompanyTypeData());
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setLoading(false));
        // dispatch(setFeedBackInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getUserTypeList = () => async (dispatch, getState) => {
  try {
    const responseData = await getUserTypeListCall();

    if (responseData.status.code === 200) {
      dispatch(setUserTypeListData(responseData.data));
    } else {
      // dispatch(setUserListData(1));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const userTypeAddEditFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await userTypeAddEditCall(data);

    if (responseData.status.code === 200) {
      dispatch(getUserTypeList());
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      // dispatch(setUserListData(1));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const userPositionTypeAddEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await userPositionTypeAddEditCall(data);

      if (responseData.status.code === 200) {
        dispatch(getUserJobposition());
        BaseHelper.nodifyFun(responseData.status.message, "success");
      } else {
        // dispatch(setUserListData(1));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const userSocietyTypeAddEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await userSocietyTypeAddEditCall(data);
      // basic/society/edit
      if (responseData.status.code === 200) {
        dispatch(getUserSocietyData());
        BaseHelper.nodifyFun(responseData.status.message, "success");
      } else {
        // dispatch(setUserListData(1));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const userSexTypeAddEditFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await userSexTypeAddEditCall(data);
    // basic/sex/edit
    if (responseData.status.code === 200) {
      dispatch(getUserSexData());
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      // dispatch(setUserListData(1));
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }

  dispatch(setLoading(false));
  // dispatch(setUser(null));
  // localStorage.removeItem("token");
};
export const userNationalityTypeAddEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await userNationalityTypeAddEditCall(data);
      // basic/nationality/edit
      if (responseData.status.code === 200) {
        dispatch(getUserUserNationalityData());
        BaseHelper.nodifyFun(responseData.status.message, "success");
      } else {
        // dispatch(setUserListData(1));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const transportRouteAddEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await transportRouteAddEditCall(data);
      // transport/route/edit
      if (responseData.status.code === 200) {
        dispatch(getTransportRouteFun());
        BaseHelper.nodifyFun(responseData.status.message, "success");
      } else {
        // dispatch(setUserListData(1));
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }

    dispatch(setLoading(false));
    // dispatch(setUser(null));
    // localStorage.removeItem("token");
  };
export const getViolationCitizenTypeData = () => async (dispatch, getState) => {
  try {
    const responseData = await getViolationCitizenTypeDataCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setViolationCitizenTypeData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getviolationServiceTypeCrudFun =
  () => async (dispatch, getState) => {
    try {
      const responseData = await getviolationServiceTypeCrudCall();
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(setViolationServiceTypeCrud(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getviolationServiceTypeCrudEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getviolationServiceTypeCrudEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getviolationServiceTypeCrudFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationLegalPenaltyFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getViolationLegalPenaltyCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setViolationLegalPenalty(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getViolationLegalPenaltyCrudEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationLegalPenaltyEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getViolationLegalPenaltyFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationCitizenTypeEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationCitizenTypeEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getViolationCitizenTypeData());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationTypeDataFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getViolationTypeDataCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setViolationTypeData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDetectionTypeFun =
  () => async (dispatch, getState) => {
    try {
      const responseData = await getViolationDetectionTypeCall();
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(setViolationDetectionType(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationDecisionTypeFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getViolationDecisionTypeCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setViolationDecisionType(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDecisionTypeEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationDecisionTypeEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getViolationDecisionTypeFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationDetectionTypeEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationDetectionTypeEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getViolationDetectionTypeFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationTypeEditFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await getViolationTypeEditCall(data);
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(getViolationTypeDataFun());
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getTransportStopListFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getTransportStopListCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setTransportStopListData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteStopFun =
  (data) => async (dispatch, getState) => {
    //  console.log(data);
    try {
      const responseData = await getTransportRouteStopCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        // console.log(responseData.data);
        dispatch(setTransportRouteStopList(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export const getTransportStopListEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getTransportStopListEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getTransportStopListFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
        dispatch(setSuccessSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getTransportRouteStopEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getTransportRouteStopEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);

        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
        // dispatch(setSuccessSnackbarOpen(true));
        // dispatch(setSuccessSnackbarMessage(responseData.message));
        // dispatch(setPosition("right"));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getTransportRouteStopDeleteFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getTransportRouteStopDeleteCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);

        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
        dispatch(setSuccessSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getTransportZoneFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getTransportZoneCall();
    //  console.log("kkkkkkk");
    if (responseData) {
      //console.log(responseData.data);
      dispatch(setTransportZoneData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getTransportZoneAddEditFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getTransportZoneAddEditCall(data);
      //  console.log("kkkkkkk");
      if (responseData) {
        //console.log(responseData.data);
        dispatch(getTransportZoneFun());
        dispatch(setLoading(false));
        // dispatch(setCompanyInspectionData(responseData.data));
      } else {
        dispatch(setErrorSnackbarOpen(true));
        dispatch(setSuccessSnackbarMessage(responseData.message));
        dispatch(setPosition("right"));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export default slice.reducer;
