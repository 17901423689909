import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch } from "react-redux";
import { editCompany } from "../../store/reducers/company";
import {
  setErrorSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
} from "../../store/reducers/uiReducer";

const CompanyEdit = (props) => {
  const dispatch = useDispatch();
  const [regnum, setRegnum] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [director_info, setDirectorInfo] = useState("");
  const [direction_info, setDirectionInfo] = useState("");
  const [organizationTypeId, setOrganizationTypeId] = useState("");

  const formReset = () => {
    setAddress("");
    // setA(props.companyEditData ? props.companyEditData.address : "");
    setRegnum("");
    setEmail("");
    setName("");
    setNote("");
    setPhoneNumber("");
    setDirectorInfo("");
    setDirectionInfo("");
    setOrganizationTypeId("");
  };
  const editAds = () => {
    // dispatch(editTravelList(travelId));
    //console.log(dateStart);
    //let startDate = dateStart.toISOString().split("T")[0];
    //const startDate = dateStart.toISOString().split("T")[0];
    // const endDate = dateEnd.toISOString().split("T")[0];
    if (regnum !== "") {
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("regnum", regnum);
      formData.append("address", address);
      formData.append("email", email);
      formData.append("name", name);
      formData.append("note", note);
      formData.append("phone_number", phone_number);
      formData.append("director_info", director_info);
      formData.append("direction_info", direction_info);
      formData.append(
        "organization_type_id",
        organizationTypeId === "" ? 1 : organizationTypeId
      );
      // console.log(props);
      //dispatch(editCompany(formData));
      dispatch(editCompany(formData));
      props.storeCompany(formData);

      // formReset();
      // window.location.reload();
    } else {
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(" оруулна уу!!!"));
      dispatch(setPosition("right"));
    }
  };
  return (
    <>
      <ScrollView width="100%" height="90%">
        <Box className="dx-fieldset-header">Байгуулагын мэдээлэл</Box>
        <Divider />
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">ААНБ-Регистр:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setRegnum(v);
                    }}
                    value={regnum}
                  />
                </Box>
              </Box>

              {/* 
            <Button
              text="ok"
              onClick={() => {
                // props.searchVehBtn();
              }}
            /> */}
            </Box>
          </Item>
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Төрөл:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setDirectionInfo(v);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">ААНБ-Нэр:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setName(v);
                    }}
                    value={name}
                  />
                </Box>
              </Box>

              {/* 
            <Button
              text="ok"
              onClick={() => {
                // props.searchVehBtn();
              }}
            /> */}
            </Box>
          </Item>
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Удирдлагын нэр:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setDirectorInfo(v);
                    }}
                    value={director_info}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Хаяг:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setAddress(v);
                    }}
                    value={address}
                  />
                </Box>
              </Box>

              {/* 
            <Button
              text="ok"
              onClick={() => {
                // props.searchVehBtn();
              }}
            /> */}
            </Box>
          </Item>
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">ҮА-ны чиглэл:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    onValueChange={(v) => {
                      setDirectionInfo(v);
                    }}
                    value={direction_info}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">И-мэйл:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    value={email}
                    onValueChange={(v) => {
                      setEmail(v);
                    }}
                  />
                </Box>
              </Box>

              {/* 
            <Button
              text="ok"
              onClick={() => {
                // props.searchVehBtn();
              }}
            /> */}
            </Box>
          </Item>
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Утас:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    value={phone_number}
                    onValueChange={(v) => {
                      setPhoneNumber(v);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>

        <Box className="dx-fieldset-header">Нэмэлт мэдээлэл</Box>
        <Divider />
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box sx={{ m: 1 }}>
              <TextBox
                value={note}
                onValueChange={(v) => {
                  setNote(v);
                }}
                valueChangeEvent="input"
              />
            </Box>
          </Item>
        </BoxDevExt>
      </ScrollView>
      <Box
        sx={{
          position: "absolute",
          bottom: " 0px",
          width: "100%",
          borderTop: `1px solid #aaa`,
          padding: "10px 14px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Button
            text="Буцах"
            icon="back"
            onClick={() => {
              // props.searchVehBtn();
            }}
          />

          <Button
            position="end"
            icon="save"
            text="Хадгалах"
            type="default"
            onClick={(e) => {
              editAds();
              // props.searchVehBtn();
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default CompanyEdit;
