import { Box, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
  ColumnFixing,
  ColumnChooser,
  LoadPanel,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";
import {
  getInspectorReportFun,
  getLegalProvisionReportFun,
  getPenaltyReportFun,
  getViolationAanTypeReportFun,
  getViolationInspectionDetectReportFun,
  getViolationInspectionTypeReportFun,
  getViolationTypeReportFun,
  setFilter,
  setRemFilter,
} from "../../../store/reducers/report";
import { getPorkurorReportFun } from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";

const ViolationInspectorTypeReportList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getViolationInspectionTypeReportData = useSelector(
    (state) => state.report.violationInspectionTypeReportData
  );
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);

  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  useEffect(() => {
    gridRef.current.instance.beginCustomLoading();
    if (getViolationInspectionTypeReportData == null) {
      dispatch(getViolationInspectionTypeReportFun(filter));
    } else {
      gridRef.current.instance.endCustomLoading();
      setFormData(getViolationInspectionTypeReportData);
      // dispatch(setRemFilter());
    }
  }, [getViolationInspectionTypeReportData, filter, dispatch]);
  // useEffect(() => {
  //   if (getPorkurorReportData == null && Object.keys(filter).length > 0) {
  //     dispatch(getPorkurorReportFun(filter));
  //   }
  // }, [getPorkurorReportData, filter, dispatch]);
  //console.log(getInspectorReportData);

  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getViolationInspectionTypeReportFun(filter));
    }
  }, [filter, dispatch]);

  //console.log(props);
  // useEffect(() => {
  //   gridRef.current.instance.beginCustomLoading();
  //   if (props.porkurorReportListData) {
  //     setFormData(props.porkurorReportListData);
  //     gridRef.current.instance.endCustomLoading();
  //   }
  // }, [props]);
  // console.log(props.feedBackListData);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  // const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
  //   // console.log("selectedRowKeys");
  //   // console.log(selectedRowsData[0].id);
  //   setSelectIndex(selectedRowKeys[0]);
  //   setIsType(2);
  //   setFormEditData(selectedRowsData[0]);
  // };
  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }
  console.log(filter);
  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getViolationInspectionTypeReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        wordWrapEnabled={true}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        height={600}
      >
        <Selection mode="multiple" />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        {/* <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex - 1 + 1;
          }}
          fixed={true}
        /> */}
        <Column dataField="offender_type" caption={"Субъект"} fixed={true} />
        <Column dataField="violation_date" caption={"Огноо"} groupIndex={0} />
        <Column dataField="firstname" caption={"Нэр"} groupIndex={1} />

        <Column
          dataField="violation_count"
          caption={"Нийт"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_1"
          caption={"Нийтийн тээвэр"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_2"
          caption={"Такси"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_3"
          caption={"Ажилчдын автобус"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_4"
          caption={"Хүүхдийн автобус"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_5"
          caption={"Хууль бус - такси"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_6"
          caption={"Хууль бус - Нийтийн тээвэр"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_7"
          caption={"Хууль бус - хот хооронд"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_8"
          caption={"ачаа тээвэр"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_9"
          caption={"Авто үйлчилгээ"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_10"
          caption={"Угаалга"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="type_11"
          caption={"Суудлын тэрэг"}
          dataType="number"
          width={100}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />

        <Summary>
          <GroupItem
            column="offender_type"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="Нийт"
          />
          <GroupItem
            column="violation_count"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat=" {0}"
          />
          <GroupItem
            column="type_1"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_2"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_3"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_4"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_5"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_6"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_7"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_8"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_9"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_10"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="type_11"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />

          <TotalItem
            column="offender_type"
            summaryType="sum"
            // valueFormat="currency"
            customizeText={customizeDate}
          />
          <TotalItem
            column="violation_count"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat=" {0}"
          />
          <TotalItem
            column="type_1"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_2"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_3"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_4"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_5"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_6"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_7"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_8"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_9"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_10"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="type_11"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
        </Summary>
        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default ViolationInspectorTypeReportList;
