import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useRef, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  Form,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Texts,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import {
  getTransportRouteFun,
  getTransportRouteStopFun,
  transportRouteAddEditFun,
} from "../../store/reducers/metaCrud";
import TransportRoute from "../../components/transportRouteStop/tansportRoute";
import TransportRouteStop from "../../components/transportRouteStop/transportRouteStop";
import MapRoutes from "../../components/maps/mapRoutes";

const TransportRouteCrud = () => {
  const gridRef = useRef();
  const gertransportRouteListData = useSelector(
    (state) => state.metaCrud.transportRouteListData
  );
  // const getTransportRouteStopList = useSelector(
  //   (state) => state.metaCrud.transportRouteStopList
  // );
  const getTransportRouteStopList = useSelector(
    (state) => state.metaCrud.transportRouteStopList
  );
  const filter = useSelector((state) => state.feedBack.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [editRowKey, setEditRowKey] = useState(-1);
  const [selectRowIndex, setSelectRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const [routeStopData, setRouteStopData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gertransportRouteListData === null) {
      dispatch(getTransportRouteFun());
    } else {
      setFormData(gertransportRouteListData);
    }
  }, [gertransportRouteListData, dispatch]);
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length > 0) {
  //     dispatch(getFeedBack(filter));
  //   }
  // }, [filter, dispatch]);
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    //  console.log(selectedRowsData[0]);

    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };

  const setFilters = (filterData) => {
    setRouteStopData(filterData);
  };
  return (
    <Box m="15px">
      <Header
        title="Автобусны чиглэл,зогсоол"
        subtitle="Автобусны чиглэл,зогсоол жагсаалт"
      />
      <Box
        display="grid"
        gap="1px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 6" }}>
          {/* <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} /> */}

          <TransportRoute getFilters={setFilters} />
        </Box>
        <Box sx={{ gridColumn: "span 6" }}>
          <TransportRouteStop
            routeStopData={routeStopData}
            dataR={getTransportRouteStopList}
          />
          {/* <BranchList
            companyId={companyId}
            branchList={getBranchTreeListData}
            isCompanyFilter={isCompanyFilter}
          /> */}
        </Box>
        <Box sx={{ gridColumn: "span 12" }}>
          <MapRoutes dataMarker={getTransportRouteStopList} />
        </Box>
      </Box>
    </Box>
  );
};

export default TransportRouteCrud;
