import { Box } from "@mui/material";

//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import GrievanceConfirmedReportList from "../../../components/reports/grievance/grievanceConfirmedList";
import HumanReportList1 from "../../../components/reports/human/humanReportList1";

const HumanReport1 = () => {
  const filter = useSelector((state) => state.report.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  return (
    <Box m="20px">
      <Header title="Хүний нөөц тайлан" subtitle="Албан тушаалын төрлөөр" />

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <HumanReportList1 />
        </Box>
      </Box>
    </Box>
  );
};

export default HumanReport1;
