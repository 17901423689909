import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  Collapse,
  Icon,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ReactIcons } from "react-icons/fa";
import {
  Badge,
  BusAlert,
  DirectionsBus,
  Error,
  ExpandLess,
  ExpandMore,
  KeyboardArrowRight,
  LocationCity,
  Settings,
  StarBorder,
} from "@mui/icons-material";
import { react, useSelector } from "react-redux";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import parse from "html-react-parser";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMenuAdmin = () => {
  const logedUser = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState("");
  const icons = {
    Badge,
    BusAlert,
    DirectionsBus,
    Error,
    ExpandLess,
    ExpandMore,
    LocationCity,
    Settings,
    StarBorder,
    HomeOutlinedIcon,
    PeopleOutlinedIcon,
    HelpOutlineOutlinedIcon,
  };
  const [selectedIndex, setSelectedIndex] = useState("");

  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index);
    }
  };
  // const handleClick = () => {
  //   setOpen(!open);
  // };
  //console.log(logedUser);
  useEffect(() => {
    if (isNonMobile === false) {
      setIsCollapsed(true);
    }
    //console.log(logedUser);
  }, [isNonMobile]);
  // const iccc = parse("<PeopleOutlinedIcon />");
  //console.log(parse("<PeopleOutlinedIcon />"));
  const renderItems = logedUser.menu.map((item, index) => {
    // console.log(item.icon);
    //  const IconElement = item.icon;
    const IconsComponent = icons[item.icon];
    // console.log(IconsComponent);
    return (
      <List
        key={index}
        component="nav"
        aria-labelledby="nested-list-subheader"

        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Nested List Items
        //   </ListSubheader>
        // }
      >
        <ListItemButton
          onClick={() => {
            handleClick(index);
          }}
          sx={{ background: "none" }}
        >
          <ListItemIcon
            sx={{
              "&:hover": {
                color: "#70d8bd!important",
              },
            }}
          >
            <Icon>
              <IconsComponent />
            </Icon>
          </ListItemIcon>
          <ListItemButton
            to={item.link !== null ? item.link : null}
            sx={{
              background: "none",
              padding: 0,
              margin: 0,
              "&:hover": {
                background: "none",
                color: "#70d8bd!important",
              },
              "&:active": {
                background: "none",
              },
            }}
          >
            {!isCollapsed && <ListItemText primary={item.name} />}
          </ListItemButton>

          {item.child !== null ? (
            index === selectedIndex ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            ""
          )}
          {/* {item.child !== null && open ? (
            <ExpandLess
              onClick={() => {
                if (item.child !== null) {
                  handleClick();
                }
              }}
            />
          ) : item.child !== null ? (
            <ExpandMore
              onClick={() => {
                if (item.child !== null) {
                  handleClick();
                }
              }}
            />
          ) : (
            ""
          )} */}
        </ListItemButton>
        {item.child !== null ? (
          <Collapse
            in={index === selectedIndex ? true : false}
            timeout="auto"
            unmountOnExit
          >
            {item.child.map((child, key) => {
              //
              const IconsComponent2 = icons[child.icon];
              //  console.log(child.link);
              return (
                <List key={key} component="div" disablePadding>
                  <ListItemButton to={child.link} sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <Icon>
                        <IconsComponent2 />
                      </Icon>
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary={child.name} />}
                  </ListItemButton>
                </List>
              );
            })}
          </Collapse>
        ) : (
          ""
        )}
        {/* <Item
          title={item.name}
          to={item.link}
          // eslint-disable-next-line no-undef
          icon={
            <Icon>
              <IconsComponent />
            </Icon>
          }
          selected={selected}
          setSelected={setSelected}
        />
        <KeyboardArrowRight /> */}
      </List>
    );
  });
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          //  height: "auto",
          // overflowY: "scroll",
          position: "fixed",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 55px 5px 10px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar oSidebar collapsed={isCollapsed} width={320}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Нийтийн тээвэр
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/profile.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {logedUser !== null ? logedUser.lastname.substring(0, 1) : ""}
                  .{logedUser !== null ? logedUser.firstname : ""}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {logedUser !== null ? logedUser.type : ""}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>{renderItems}</Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default SidebarMenuAdmin;
