import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  Collapse,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import {
  Badge,
  BusAlert,
  DirectionsBus,
  Error,
  ExpandLess,
  ExpandMore,
  LocationCity,
  StarBorder,
} from "@mui/icons-material";
import { react, useSelector } from "react-redux";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import SidebarMenuUser from "./sideBar/SidebarUser";
import SidebarMenuAdmin from "./sideBar/SidebarAdmin";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMenu = () => {
  const logedUser = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = useState(true);
  const role = localStorage.getItem("role");
  const handleClick = () => {
    setOpen(!open);
  };

  // if (role === "user") {
  //   return <SidebarMenuUser />;
  // } else {
  return <SidebarMenuAdmin />;
  // }
};

export default SidebarMenu;
