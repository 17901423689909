import { useLocation, Navigate, Outlet } from "react-router-dom";
//import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";

const RequireAuth = ({ allowedRoles }) => {
  // const { auth } = useAuth();
  const location = useLocation();
  const logedUser = useSelector((state) => state.auth.user);
  const getRole = useSelector((state) => state.auth.role);
  const role = localStorage.getItem("role");

  //console.log(allowedRoles?.includes(role));
  return allowedRoles?.includes(logedUser !== null ? logedUser.role : role) ? (
    <Outlet />
  ) : logedUser ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
