export const areas = [
  {
    country: "Зөрчил",
    area: 12,
  },
  {
    country: "Гомдол",
    area: 7,
  },
  {
    country: "Саатал",
    area: 7,
  },
  {
    country: "Бусад",
    area: 7,
  },
];
