import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  List,
  LoadIndicator,
  LoadPanel,
  Lookup,
  Popup,
  TextArea,
  TileView,
  TreeView,
} from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { DropDownOptions } from "devextreme-react/autocomplete";

import {
  getEmployeeInfoData,
  getUserJobposition,
  getUserListData,
  setFilter,
  getUserModuleData,
} from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";
import { GroupItem } from "devextreme-react/form";
import DataGrid, { Column, Label } from "devextreme-react/data-grid";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { getCompany } from "../../store/reducers/company";
import ArrayStore from "devextreme/data/array_store";
import { DataGridConfig } from "../../config";
import {
  grievanceMove,
  setGrievanceIsEdit,
} from "../../store/reducers/feedBack";

const GrievanceMoveStore = (props) => {
  const dispatch = useDispatch();
  const [selectedItemKeys, setSelectedItemKeys] = useState("");
  const getUserList = useSelector((state) => state.auth.userListData);
  const getGrievanceIsEdit = useSelector(
    (state) => state.feedBack.grievanceIsEdit
  );
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [modules, setModules] = useState([]);
  const [isPop, setIsPop] = useState(false);
  const [userId, setUserId] = useState("");
  const [comment, setComment] = useState("");
  const [user_type_id, setUserTypeId] = useState("");
  const gridRef = useRef();
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };
  const filter = useSelector((state) => state.auth.filter);
  const setFilters = (field, value) => {
    //  console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };

  useEffect(() => {
    // console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getUserListData(filter));
    }
  }, [filter, dispatch]);
  const syncSelection = (treeView) => {
    const selectedEmployees = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);
    //console.log(selectedEmployees);
    setSelectedEmployees(selectedEmployees);

    // console.log(selectedEmployees.length > 0 ? selectedEmployees[0].id : "");
    if (selectedEmployees.length > 0) {
      setFilters(
        "branch_id",
        selectedEmployees.length > 0 ? selectedEmployees[0].id : null
      );
    }
    // this.setState(() => ({ selectedEmployees }));
  };
  // console.log(getUserList);
  const hideInfo = () => {
    setIsPop(false);
    // setIsConfirm(false);
  };
  const treeViewSelectionChanged = (e) => {
    //console.log(e.component);
    syncSelection(e.component);
  };

  const treeViewContentReady = (e) => {
    syncSelection(e.component);
  };
  function renderTreeViewItem(item) {
    return `${item.name}`;
  }
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log("selectedRowKeys");
    // console.log(selectedRowsData[0].id);
    // setSelectIndex(selectedRowKeys[0]);
    // setIsType(2);
    //  console.log(selectedRowsData[0].id);
    setUserId(selectedRowsData[0].id);

    // console.log(props);
  };
  return (
    <>
      <div className="left">
        {/* <List
          selectionMode="single"
          dataSource={getUserModuleDataList}
          grouped={false}
          searchEnabled={false}
          selectedItemKeys={selectedItemKeys}
          onSelectionChanged={handleListSelectionChange}
          itemRender={renderListItem}
          groupRender={renderListGroup}
          //  elementAttr={listAttrs}
        /> */}
        <TreeView
          id="treeview"
          ref={gridRef}
          //width={340}
          //height={320}
          itemsExpr={"child"}
          //parentIdExpr={"child.id"}
          items={props.branchTreeList}
          //items={dataaa}
          selectNodesRecursive={false}
          selectByClick={true}
          showCheckBoxesMode={"normal"}
          selectionMode={"single"}
          onSelectionChanged={treeViewSelectionChanged}
          onContentReady={treeViewContentReady}
          itemRender={renderTreeViewItem}
        />
      </div>

      <div className="right">
        <DataGrid
          // ref={gridRef}
          {...DataGridConfig}
          selectedRowKeys={true}
          showRowLines={true}
          remoteOperations={true}
          selection={{ mode: "single", selectAllMode: "page" }}
          dataSource={getUserList}
          keyExpr="id"
          height={500}
          onSelectionChanged={onSelectionChanged}
          onRowDblClick={(row) => {
            // console.log(row.data.id);
            // dispatch(getGrievanceDecisionList(row.data.id));
            //setFormEditData(row.data);
            setIsPop(true);
          }}
          // onSelectionChanged={onSelectionChanged}
        >
          <Column
            allowSorting={true}
            caption="#"
            cellRender={(e) => {
              return e.rowIndex + 1;
            }}
          />
          {/* <Column
            dataField="branch_name"
            format="fixedPoint"
            caption={"Хэлтэс нэр"}
          /> */}

          <Column
            dataField="job_title"
            //format="fixedPoint"
            caption={"Албан тушаал"}
          />
          <Column
            dataField="firstname"
            //format="fixedPoint"
            caption={"Ажилтаны нэр"}
          />
        </DataGrid>
        <Popup
          visible={isPop}
          onHiding={hideInfo}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={true}
          position={"top"}
          title={"Санал хүсэлт шийдвэрлэлт"}
          showTitle={true}
          width={400}
          height={200}
        >
          <Stack direction={"column"} spacing={1}>
            <Typography>Тайлбар оруулах</Typography>
            <TextArea
              valueChangeEvent="input"
              onValueChanged={(e) => {
                setComment(e.value);
                // console.log(e.value);
              }}
              value={comment}
            />
            <Box
              sx={{
                bottom: " 0px",
                width: "100%",
                borderTop: `1px solid #aaa`,
                padding: "10px 14px",
              }}
            >
              <Stack direction="row" justifyContent={"space-between"}>
                <Box></Box>

                <Button
                  position="end"
                  icon="save"
                  //text="Ажилтаны мэдээлэл хаадгалах"feedBackData
                  type="default"
                  stylingMode="outlined"
                  onClick={() => {
                    if (comment !== "") {
                      const data = {
                        user_id: userId,
                        grievance_id: props.feedBackData.id,
                        comment: comment,
                        is_transfer: 1,
                      };
                      dispatch(setGrievanceIsEdit(true));
                      dispatch(grievanceMove(data));
                      // console.log(getGrievanceIsEdit);
                      if (!getGrievanceIsEdit) {
                        setIsPop(false);
                        props.isCloseFunc();
                      }
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                >
                  <LoadIndicator
                    className="button-indicator"
                    shadingColor="rgba(0, 0, 0, 0.2)"
                    visible={getGrievanceIsEdit}
                    width={20}
                    height={20}
                    //indicatorSrc="https://i.gifer.com/ZC9Y.gif"
                  />
                  <span className="dx-button-text">{"Илгээх"}</span>
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Popup>
      </div>
    </>
  );
};

export default GrievanceMoveStore;
