import { useDispatch } from "react-redux";
import axios from "../../api/axiosInctance";
import { guest } from "../../store/reducers/user/auth";
import { setViolationOffenderClear } from "../../store/reducers/metaCrud";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
//const getToken1 = localStorage.getItem("token");
export const companyTypeDataCall = async () => {
  try {
    //console.log(axios);
    // let newFilter = {};
    // Object.keys(filter).map((key) => {
    //   if (filter[key] !== null) {
    //     newFilter[key] = filter[key];
    //   }
    // });
    // console.log(JSON.stringify(newFilter));
    const getCompanyData = await axios
      .post(
        "/company/type/list"
        //   , {
        //     params: {
        //       pagesize: 1000,
        //       filter: JSON.stringify(newFilter),
        //     },
        //   },
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;
        console.warn("error", message);
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getCompanyData;
  } catch (error) {
    console.log(error);
  }
};
export const getCompanyTypeEditAddCall = async (data) => {
  try {
    const getEmployeData = await axios
      .post("/company/type/edit", data)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};

export const getViolationCompanyCrudCall = async (violationCompanyFilter) => {
  try {
    //  console.log(violationCompanyFilter);
    let newFilter = {};
    Object.keys(violationCompanyFilter).map((key) => {
      if (violationCompanyFilter[key] !== null) {
        newFilter[key] = violationCompanyFilter[key];
      }
    });

    const dataResponse = await axios
      .post("/violation/company/list", newFilter)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationVehicleCrudCall = async (violationVehicleFilter) => {
  try {
    // console.log(violationVehicleFilter);
    let newFilter = {};
    Object.keys(violationVehicleFilter).map((key) => {
      if (violationVehicleFilter[key] !== null) {
        newFilter[key] = violationVehicleFilter[key];
      }
    });

    const dataResponse = await axios
      .post("/violation/vehicle/list", newFilter)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserTypeListCall = async (filter) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userTypeAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/type/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationVehicleEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/vehicle/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationCompanyEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/company/edit", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userPositionTypeAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/job_position/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userSocietyTypeAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/society/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userSexTypeAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/sex/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userNationalityTypeAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/nationality/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/route/list", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const transportRouteAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/route/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportZoneCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/traffic_zone/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportZoneAddEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/traffic_zone/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationCitizenTypeDataCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/citizen_type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationTypeDataCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDecisionTypeCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/decision_type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationLegalPenaltyCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/legal_penalty/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDetectionTypeCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/detection_type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationLegalPenaltyEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/legal_penalty/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteStopCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/route/data", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportStopListEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/stop/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteStopEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/route_stop/edit", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportRouteStopDeleteCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/route_stop/delete", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getTransportStopListCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/transport/stop/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getviolationServiceTypeCrudCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/service_type/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationLegalProvisionCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/legal_provision/list")
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationOffenderEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/offender/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationOffenderCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/offender/nameList", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    // useDispatch(setViolationOffenderClear());
    return error;
    console.log(error);
  }
};
export const vehicleSearchDataCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/vehicle/data", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    // useDispatch(setViolationOffenderClear());
    return error;
    console.log(error);
  }
};
export const getviolationServiceTypeCrudEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/service_type/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationLegalProvisionEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/legal_provision/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDetectionTypeEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/detection_type/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDecisionTypeEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/decision_type/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationTypeEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/type/edit", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationCitizenTypeEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/violation/citizen_type/edit", data)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
