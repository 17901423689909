import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  getRequestConfirmed,
  requestIsApprovedVehCancel,
  setFilter,
} from "../../../../store/reducers/requestVehicle";
import { DataGridConfig } from "../../../../config";
import { Gallery, ScrollView, TextBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
const RequestConfirmedData = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [displayMode, setDisplayMode] = useState("full");
  const [showPageSizeSelector, setShowPageSizeSelector] = useState(true);
  const [showInfo, setShowInfo] = useState("full");
  const [showNavButtons, setShowNavButtons] = useState("full");
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [ispicView, setIspicView] = useState(false);
  const [testVal, setTestVal] = useState("test");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const requestConfirmedData = useSelector(
    (state) => state.requestVehicle.requestConfirmedData
  );
  const filterConfirmed = useSelector(
    (state) => state.requestVehicle.filterConfirmedAdmin
  );
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filterConfirmed, [field]: value }));
  };
  useEffect(() => {
    if (requestConfirmedData == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(getRequestConfirmed(filterConfirmed));
    }
  }, [requestConfirmedData, filterConfirmed, dispatch]);
  useEffect(() => {
    //  console.log(Object.keys(filter).length);
    if (Object.keys(filterConfirmed).length > 0) {
      dispatch(getRequestConfirmed(filterConfirmed));
    }
  }, [filterConfirmed, dispatch]);

  useEffect(() => {
    if (requestConfirmedData) {
      setFormData(requestConfirmedData);
      // gridRef.current.instance.endCustomLoading();
    }
  }, [requestConfirmedData]);

  //console.log(props);
  const gridRef = useRef();
  //const [state, dispatch] = React.useReducer(reducer, initialState);
  //const vehicleDataList = useSelector((state) => state.vehicle.vehicleData);
  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  //   const onSelectionChanged = (e) => {
  //     //const data = selectedRowsData[0];
  //     const data = e.selectedRowsData;
  //     console.log(e.component);
  //     setSelectIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  //     setIsType(2);
  //     setFormEditData(data[0]);

  //     //console.log(formEditData.ID);
  //   };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    //console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    setIspicView(false);
    // setFormEditData("");
  };

  //   const onSaving = (e) => {
  //     console.log(e.changes);
  //     //gridRef.current.instance.saveEditData();
  //   };
  const setStoreVehicle = (data) => {
    // dispatch(editCompany(data));
    // console.log(data);

    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  function deleteRecords() {
    gridRef.current.instance.getSelectedRowsData().forEach((key) => {
      // console.log(key);
      //   dispatch(removeRequestInData(key.id));
    });
    // this.setState({
    //   selectedItemKeys: [],
    // });
    gridRef.current.instance.refresh();
  }

  const vehicleEditSearch = () => {
    setFormEditData({ ID: 777, register: "33333" });
  };
  // console.log(formData);
  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    gridRef.current.instance.refresh();
                    dispatch(getRequestConfirmed(filterConfirmed));
                    //console.log(gridRef.current.instance.getSelectedRowsData());
                    // setIsPopUpEdit(true);
                    // console.log(isPopUpEdit);
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={(e) => {
                    console.log(e.row);
                    if (formEditData !== "") {
                      const data = {
                        is_approved: 0,
                        id: parseInt(formEditData.id),
                        note: "Цуцлав",
                      };
                      dispatch(requestIsApprovedVehCancel(data));
                      //  console.log(formEditData.id);
                    } else {
                      notify(
                        {
                          message: "Цуцлах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                  type={"danger"}
                  text="Цуцлах"
                />
              </Box>
            </Item>
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={400}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            onSelectionChanged={onSelectionChanged}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={true}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "90%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                  <Item dataField="official_letter_number" />
                  <Item dataField="official_letter_date" />
                  <Item dataField="checked_number" />
                  <Item dataField="checked_date" />
                </Item>
                <Item
                  itemType="group"
                  caption="Тээврийн хэрэгсэл мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="garage_no" />
                  <Item dataField="plate_no" />
                  <Item dataField="cabin_no" />
                  <Item dataField="mark_name" />
                  <Item dataField="model_name" />

                  <Item dataField="color_name" />
                  <Item dataField="vehicle_service_type_name" />
                  <Item dataField="seat_count" />
                  <Item dataField="fuel_type_name" />
                  <Item dataField="engine_model_name" />
                  <Item dataField="max_load" />
                  <Item dataField="length" />
                  <Item dataField="own_weight" />
                  <Item dataField="total_weight" />
                </Item>

                <Item dataField="created" />
                <Item dataField="checked_note" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                width={130}
                format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"ААНБ-н регистер"}
              />
              <Column
                dataField="company_name"
                format="fixedPoint"
                caption={"ААНБ-н нэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="attachment"
                // format="yyyy-MM-dd HH:mm"
                // dataType="date"
                // width={300}
                caption={"Хавсралт"}
                cellRender={(v) => {
                  return (
                    <Button
                      hint="Clone"
                      icon="image"
                      // visible={this.isCloneIconVisible}
                      // disabled={this.isCloneIconDisabled}
                      onClick={() => {
                        setAttachment(
                          v.value.map(
                            (e) => "https://api.ptd.ub.gov.mn/api/v1/" + e.url
                          )
                        );
                        setIspicView(true);
                      }}
                    />
                  );
                }}
              />
            </Column>
            <Column
              dataField="official_letter_number"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              format="fixedPoint"
              caption={"Албан бичиг дугаар"}
            />
            <Column
              dataField="official_letter_date"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              format="fixedPoint"
              caption={"Албан бичиг огноо"}
            />
            <Column
              dataField="checked_number"
              format="fixedPoint"
              caption={"Дүгнэлтийн дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="checked_date"
              format="fixedPoint"
              caption={"Дүгнэлтийн огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="garage_no"
              format="fixedPoint"
              caption={"Грааш дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="smart_bus_id"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Дугаар"}
            />
            <Column
              allowSorting={true}
              width={200}
              dataField="is_checked"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption="Байцаагч төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Байцаагч баталгаажуулсан"
                      size="small"
                      color="warning"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Байцаагч татгалзсан" size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            />
            <Column
              allowSorting={true}
              width={120}
              dataField="is_approved"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption="Төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Баталгаажуулсан"
                      size="small"
                      color="success"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Баталгаажаагүй" size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            />

            {/* <Column
          dataField="is_approved"
          // width={100}

          allowSorting={true}
          cellRender={cellRender}
        /> */}
            <Column
              dataField="plate_no"
              format="fixedPoint"
              caption={"Улсын дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="cabin_no"
              format="fixedPoint"
              caption={"Арлын дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="mark_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Марк"}
            />
            <Column
              dataField="model_name"
              format="fixedPoint"
              caption={"Загвар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="color_name"
              format="fixedPoint"
              caption={"Өнгө"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="seat_count"
              format="fixedPoint"
              caption={"Суудал тоо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="vehicle_service_type_name"
              format="fixedPoint"
              caption={"Төрөл"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="fuel_type_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Шатахууны төрөл"}
            />
            <Column
              dataField="engine_model_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Моторын модел"}
            />
            <Column
              dataField="max_load"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Даац"}
            />
            <Column
              dataField="length"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " см"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Урт"}
            />
            <Column
              dataField="own_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Өөрийн жин"}
            />
            <Column
              dataField="total_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Нийт жин"}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              caption={"Тайлбар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              caption={"Нэмэлт мэдээлэл"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="checked"
              format="fixedPoint"
              caption={"Баталсан"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="created"
              format="fixedPoint"
              caption={"Хүсэлт илгээсэн"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="updated_at"
              format="fixedPoint"
              caption={"Баталсан огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              cellRender={(p) => {
                // console.log(p);
                return p.value;
              }}
            />

            {/* <Scrolling rowRenderingMode="virtual" /> */}
            {/* <Scrolling mode="infinite" /> */}
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            {/* <Scrolling rowRenderingMode="virtual"></Scrolling> */}

            {/* <Paging defaultPageSize={10} visible={true} defaultPageIndex={0} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          // displayMode={displayMode}
          showPageSizeSelector={true}
          showInfo={true}
          //displayMode={"compact"}
          infoText="НИЙТ {1} ХУУДАС (НИЙТ {2} БИЧЛЭГ)"
          showNavigationButtons={showNavButtons}
        /> */}
            <LoadPanel enabled={true} />
          </DataGrid>

          <Popup
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={ispicView}
            showTitle={true}
            showIndicator={true}
            fullScreen={false}
            title="Медиа"
            onHiding={hideInfo}
            width={600}
            height={600}
          >
            <ScrollView width="100%" height="100%">
              <Gallery
                id="gallery"
                dataSource={attachment}
                width={"100%"}
                height={600}
                slideshowDelay={3000}
                loop={false}
                showNavButtons={true}
                dragEnabled={true}
                showIndicator={true}
              />
            </ScrollView>
          </Popup>
        </Box>
      </Box>
    </>
  );
};

export default RequestConfirmedData;
