import { Link } from "react-router-dom";

const Missing = () => {
  return (
    <div
      style={{
        padding: "100px",
        color: "#000",
        background: "#fff",
        textAlign: "center",
      }}
    >
      <h1>Уг модульд засвар хийгдэж байна!</h1>
      <p>Засвар хийгдэж байна.........</p>
      <div style={{}}>
        <img src="../../assets/rep.png" alt="" />
      </div>
    </div>
  );
};

export default Missing;
