import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch } from "react-redux";
import { CheckBox, Lookup } from "devextreme-react";
import { employeesList, employeesTasks } from "./customers";
import { DropDownOptions } from "devextreme-react/autocomplete";
import {
  setErrorSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
} from "../../store/reducers/uiReducer";
import { editUser } from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";
const UserEdit = (props) => {
  const dispatch = useDispatch();
  const [regnum, setRegnum] = useState("");

  const [user_type_id, setUserTypeId] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [is_new_password, setIsNewPassword] = useState(0);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  useEffect(() => {
    // console.log({ props });
    setUserTypeId(props.userEditData ? props.userEditData.user_type_id : "");
    setSurname(props.userEditData ? props.userEditData.surname : "");
    // setA(props.userEditData ? props.userEditData.address : "");
    setRegnum(props.userEditData ? props.userEditData.regnum : "");
    setLastname(props.userEditData ? props.userEditData.lastname : "");
    setFirstname(props.userEditData ? props.userEditData.firstname : "");
    setEmail(props.userEditData ? props.userEditData.email : "");
    setPhoneNumber(props.userEditData ? props.userEditData.phone_number : "");
    // setPassword(props.userEditData ? props.userEditData.password : "");
    // setRepassword(props.userEditData ? props.userEditData.repassword : "");
    setIsNewPassword("");
    setPassword("");
    setRepassword("");
  }, [props]);
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };
  const editData = () => {
    // dispatch(editTravelList(travelId));
    //console.log(dateStart);
    //let startDate = dateStart.toISOString().split("T")[0];
    //const startDate = dateStart.toISOString().split("T")[0];
    // const endDate = dateEnd.toISOString().split("T")[0];
    if (regnum !== "") {
      const formData = new FormData();
      formData.append("id", props.userEditData.id);
      formData.append("regnum", regnum);
      formData.append("user_type_id", user_type_id);
      formData.append("email", email);
      formData.append("surname", surname);
      formData.append("phone_number", phone_number);
      formData.append("lastname", lastname);
      formData.append("firstname", firstname);
      formData.append("password", password);
      formData.append("repassword", repassword);
      formData.append("is_new_password", is_new_password);

      props.userUpdate(formData);
      // formReset();
    } else {
      notify(
        {
          message: "Мэдээлэл гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };

  return (
    <>
      <ScrollView width="100%" height="90%">
        <Box className="dx-fieldset-header">Хувийн мэдээлэл</Box>
        <Divider />
        <BoxDevExt direction="row" width="100%">
          <Item ratio={2}>
            <BoxDevExt direction="row" width="100%">
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Ургийн овог:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={surname}
                        onValueChange={(v) => {
                          setSurname(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label"> И-мэйл:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={email}
                        onValueChange={(v) => {
                          setEmail(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
            </BoxDevExt>
            <BoxDevExt direction="row" width="100%">
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Овог:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={lastname}
                        onValueChange={(v) => {
                          setLastname(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Утас:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={phone_number}
                        onValueChange={(v) => {
                          setPhoneNumber(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
            </BoxDevExt>
            <BoxDevExt direction="row" width="100%">
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Нэр:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={firstname}
                        onValueChange={(v) => {
                          setFirstname(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Регистр:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        value={regnum}
                        onValueChange={(v) => {
                          setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
            </BoxDevExt>
          </Item>
        </BoxDevExt>

        <Box className="dx-fieldset-header">Хэрэглэгчийн эрх</Box>
        <Divider />

        <BoxDevExt direction="row" width="50%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Хандалтын эрх:</Box>
                <Box className="dx-field-value">
                  <Lookup
                    onSelectionChanged={(e) => {
                      // props.filterCompany(e.selectedItem.ID);
                      console.log(e.selectedItem.ID);
                      setUserTypeId(e.selectedItem.ID);
                    }}
                    items={employeesTasks}
                    //defaultValue={employeesTasks[0].ID}
                    displayExpr={getDisplayExpr}
                    valueExpr="ID"
                  >
                    <DropDownOptions showTitle={false} />
                  </Lookup>
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="50%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Нууц үг солих эсэх:</Box>
                <Box className="dx-field-value">
                  <CheckBox
                    onValueChange={(e) => {
                      console.log(e);
                      e === true ? setIsNewPassword(1) : setIsNewPassword(0);
                    }}
                    defaultValue={true}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt
          displayExpr={is_new_password === 1 ? true : false}
          direction="row"
          width="50%"
        >
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Нууц үг:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    mode="password"
                    onValueChange={(v) => {
                      setPassword(v);
                    }}
                    value={password}
                    valueChangeEvent="input"
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="50%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Нууц үг давтах:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    mode="password"
                    value={repassword}
                    onValueChange={(v) => {
                      setRepassword(v);
                    }}
                    valueChangeEvent="input"
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
      </ScrollView>
      <Box
        sx={{
          position: "absolute",
          bottom: " 0px",
          width: "100%",
          borderTop: `1px solid #aaa`,
          padding: "10px 14px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Button
            text="Буцах"
            icon="back"
            onClick={() => {
              // props.searchVehBtn();
            }}
          />

          <Button
            position="end"
            icon="save"
            text="Хадгалах"
            type="default"
            onClick={() => {
              editData();
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default UserEdit;
