export const populationData = [
  {
    arg: "1 сар",
    val: 3032019978,
  },
  {
    arg: "2 сар",
    val: 3683676306,
  },
  {
    arg: "3 сар",
    val: 4434021975,
  },
  {
    arg: "4 сар",
    val: 5281340078,
  },
  {
    arg: "5 сар",
    val: 6115108363,
  },
  {
    arg: "6 сар",
    val: 6922947261,
  },
  {
    arg: "7 сар",
    val: 7235000000,
  },
  {
    arg: "8 сар",
    val: 5281340078,
  },
];
