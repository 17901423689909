import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  useMediaQuery,
  Typography,
  Stack,
  Divider,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, {
  useState,
  useRef,
  useEffect,
  createRef,
  useCallback,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  inspectorVehicleSetFun,
  removeRequestList,
  requestIsApprovedConfirm,
  requestIsCheckedConfirm,
  setFilter,
} from "../../../../store/reducers/requestVehicle";
import { DataGridConfig } from "../../../../config";
import { blue } from "@mui/material/colors";
import {
  DateBox,
  DropDownBox,
  FileUploader,
  Gallery,
  ScrollView,
  TextArea,
  TextBox,
} from "devextreme-react";
import notify from "devextreme/ui/notify";
import { createVehicle } from "../../../../store/reducers/vehicle";
import BaseHelper from "../../../../helper/BaseHelper";
import { getUserListData } from "../../../../store/reducers/user/auth";
const gridBoxDisplayExpr2 = (item) => {
  // console.log(item);
  return item
    ? `${item.lastname !== null ? item.lastname.substring(0, 1) : ""}.${
        item.firstname
      }`
    : "";
};
const RequestInData = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const fileInput = createRef();

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const requestDataList = useSelector(
    (state) => state.requestVehicle.requestData
  );
  const filter = useSelector((state) => state.requestVehicle.filterAdmin);
  const [isChecked, setIsChecked] = useState(false);
  const [isAproved, setIsApproved] = useState(false);
  const [isCheckedNote, setIsCheckedNote] = useState("");
  const [vehicleIdcode, setVehicleIdcode] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isChecked1, setIsChecked1] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [documentDate, setDocumentDate] = useState(null);
  const dispatch = useDispatch();
  const userListData = useSelector((state) => state.auth.userListData);
  const [attachment, setAttachment] = useState([]);
  const [ispicView, setIspicView] = useState(false);
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState("");
  const [isGridBoxOpenedType, setIsGridBoxOpenedType] = useState(false);
  const gridColumns = ["lastname", "firstname"];

  // console.log(typeDateGridBoxValue);
  const dataTypeRender = () => {
    return (
      <DataGrid
        dataSource={userListData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          // console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpenedType(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  useEffect(() => {
    if (userListData == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(
        getUserListData({
          search_value: "",
          user_type_id: 12,
        })
      );
    }
  }, [userListData, dispatch]);
  useEffect(() => {
    if (requestDataList == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(getRequest(filter));
    }
  }, [requestDataList, filter, dispatch]);
  useEffect(() => {
    //  console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getRequest(filter));
    }
  }, [filter, dispatch]);

  useEffect(() => {
    if (requestDataList) {
      setFormData(requestDataList);
      // gridRef.current.instance.endCustomLoading();
    }
  }, [requestDataList]);

  //console.log(formData);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    setIsChecked(false);
    setIsDelete(false);
    setIspicView(false);
    // setFormEditData("");
  };
  const submitPolice = () => {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();

    if (isCheckedNote !== "") {
      //  console.log(getSelectedData[0].id);
      // console.log(selectedFiles);
      const formData = new FormData();
      formData.append("id", getSelectedData[0].id);
      formData.append("note", isCheckedNote);
      formData.append("checked_number", documentId);
      formData.append("checked_date", documentDate);
      formData.append("is_checked", 1);

      //  formData.append("attachment", uploadedFiles);
      if (selectedFiles.length > 0) {
        Array.from(selectedFiles).forEach((img) => {
          //  console.log(img);
          formData.append("attachment", img);
        });
      }

      // getSelectedData.map((e) =>
      dispatch(requestIsCheckedConfirm(formData));
      // );
      setIsChecked(false);
    } else {
      notify(
        {
          message: "Тайлбар оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };
  //  console.log(selectedFiles);
  const onSelectedFilesChanged = useCallback(
    (e) => {
      setSelectedFiles(e.value);
    },
    [setSelectedFiles]
  );
  //console.log(logedUser);
  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    gridRef.current.instance.refresh();
                    dispatch(getRequest(filter));
                    //  console.log(gridRef.current.instance.getSelectedRowsData());
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"check"}
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      38,
                      71,
                      89,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  //  style={{ "background-color": "yellow", color: "white" }}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      setIsChecked1(true);

                      //setIsChecked(true);
                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах тээврийн хэрэгсэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Байцаагчид хувиарлах"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      38,
                      71,
                      91,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  icon={"check"}
                  style={{ "background-color": "orange" }}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (getSelectedData.length > 0) {
                      if (getSelectedData[0].is_checked === 1) {
                        notify(
                          {
                            message: "Байцаагч баталсан байна!!!",
                            width: 450,
                          },
                          "error"
                        );
                      } else if (
                        logedUser.id !== getSelectedData[0].checked_by
                      ) {
                        notify(
                          {
                            message:
                              "Та уг тээврийн хэрэгсэл батлах боломжгүй байна   !!!",
                            width: 450,
                          },
                          "error"
                        );
                      } else {
                        setIsChecked(true);
                      }
                      //setIsChecked(true);
                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах тээврийн хэрэгсэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Байцаагч батлах"
                />
              </Box>
            </Item>

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={
                    BaseHelper.permitionCheckTwoChild(
                      38,
                      71,
                      92,
                      "is_update",
                      logedUser
                    )
                      ? false
                      : true
                  }
                  icon={"check"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      if (getSelectedData[0].is_checked != 1) {
                        notify(
                          {
                            message: "Байцаагч батлаагүй байна!!!",
                            width: 450,
                          },
                          "error"
                        );
                      } else {
                        setIsApproved(true);
                      }

                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Батлах тээврийн хэрэгсэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Мэргэжилтэн батлах"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={(e) => {
                    // console.log(e.row);

                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      setIsDelete(true);
                      //console.log(getSelectedData[0].id);
                    } else {
                      notify(
                        {
                          message: "Устгах хүсэлтээ сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    //gridRef.current.instance.deselectAll();
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item>
            <Item location="after">
              <Box>
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
            {/* <Item location="center">
              <Box sx={{ mb: 2 }}>
                <Typography variant="h3">
                  Тээврийн хэрэгслийн хүсэлтүүд жагсаалт
                </Typography>
              </Box>
            </Item> */}
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={320}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            onEditCanceled={(e) => {
              setVehicleIdcode("");
            }}
            onSelectionChanged={onSelectionChanged}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={true}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "90%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                  <Item dataField="official_letter_number" />
                  <Item dataField="official_letter_date" />
                  <Item dataField="checked_number" />
                  <Item dataField="checked_date" />
                </Item>
                <Item
                  itemType="group"
                  caption="Тээврийн хэрэгсэл мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="garage_no" />
                  <Item dataField="plate_no" />
                  <Item dataField="cabin_no" />
                  <Item dataField="mark_name" />
                  <Item dataField="model_name" />

                  <Item dataField="color_name" />
                  <Item dataField="vehicle_service_type_name" />
                  <Item dataField="seat_count" />
                  <Item dataField="fuel_type_name" />
                  <Item dataField="engine_model_name" />
                  <Item dataField="max_load" />
                  <Item dataField="length" />
                  <Item dataField="own_weight" />
                  <Item dataField="total_weight" />
                </Item>

                <Item dataField="created" />
                <Item dataField="checked_note" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                width={130}
                format="fixedPoint"
                caption={"ААНБ-н регистер"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="company_name"
                format="fixedPoint"
                caption={"ААНБ-н нэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="attachment"
                // format="yyyy-MM-dd HH:mm"
                // dataType="date"
                // width={300}
                caption={"Хавсралт"}
                cellRender={(v) => {
                  return (
                    <Button
                      hint="Clone"
                      icon="image"
                      // visible={this.isCloneIconVisible}
                      // disabled={this.isCloneIconDisabled}
                      onClick={() => {
                        setAttachment(
                          v.value.map(
                            (e) => "https://api.ptd.ub.gov.mn/api/v1/" + e.url
                          )
                        );
                        setIspicView(true);
                      }}
                    />
                  );
                }}
              />
            </Column>
            <Column
              dataField="official_letter_number"
              format="fixedPoint"
              caption={"Албан бичиг дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="official_letter_date"
              format="fixedPoint"
              caption={"Албан бичиг огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="checked_number"
              format="fixedPoint"
              caption={"Дүгнэлтийн дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="checked_date"
              format="fixedPoint"
              caption={"Дүгнэлтийн огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="garage_no"
              format="fixedPoint"
              caption={"Граашийн дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              allowSorting={true}
              width={200}
              dataField="is_checked"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption="Байцаагч төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Байцаагч баталгаажуулсан"
                      size="small"
                      color="success"
                    />
                  );
                } else if (p.value === 0 && p.data.checked_by === null) {
                  return <Chip label="Байцаагчид хувиарлаагүй" size="small" />;
                } else if (p.data.checked_by !== null && p.value === 0) {
                  return (
                    <Chip
                      label="Байцаагчид хувиарласан"
                      size="small"
                      color="warning"
                    />
                  );
                } else {
                  return (
                    <span className="status status-warning">{p.value}</span>
                  );
                }
              }}
            />
            <Column
              dataField="checked"
              format="fixedPoint"
              caption={"Байцаагч"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="plate_no"
              format="fixedPoint"
              caption={"Улсын дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="cabin_no"
              format="fixedPoint"
              caption={"Арлын дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="mark_name"
              format="fixedPoint"
              caption={"Марк"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="model_name"
              format="fixedPoint"
              caption={"Загвар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="color_name"
              format="fixedPoint"
              caption={"Өнгө"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="vehicle_service_type_name"
              format="fixedPoint"
              caption={"Төрөл"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="fuel_type_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Шатахууны төрөл"}
            />
            <Column
              dataField="engine_model_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Моторын модел"}
            />
            <Column
              dataField="max_load"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Даац"}
            />
            <Column
              dataField="length"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " см"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Урт"}
            />
            <Column
              dataField="own_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Өөрийн жин"}
            />
            <Column
              dataField="total_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Нийт жин"}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              caption={"Тайлбар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="created"
              format="fixedPoint"
              caption={"Хүсэлт илгээсэн"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="seat_count"
              format="fixedPoint"
              caption={"Суудал тоо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Бүртгэсэн огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />

            <LoadPanel enabled={true} />
          </DataGrid>

          {/* <Popup
            visible={isPop}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            position={"center"}
            title={
              isType === 2
                ? "Тээврийн хэрэгсэл засах"
                : "Тээврийн хэрэгсэл бүртгэх"
            }
            showTitle={true}
            width={900}
            //height={600}
          >
            sdfdsfds
          </Popup> */}

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked}
            showTitle={true}
            title="Байцаагч батлах хэсэг"
            onHiding={hideInfo}
            width={400}
            height={400}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              {/* <Typography sx={{ pt: 2, pb: 2, textAlign: "start" }}>
                Та уг тээврийн хэрэгслийг батлах уу ?
              </Typography> */}
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  sx={{ mb: 2 }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Typography sx={{ textAlign: "start" }}>
                      Дүгнэлт дугаар
                    </Typography>
                    <TextBox
                      value={documentId}
                      // onValueChange={onValueChange}
                      onKeyUp={(e) => {
                        e.event.preventDefault();
                        setDocumentId(e.event.target.value);
                      }}
                      placeholder=" Дүгнэлт дугаар."
                      // console.log(e);
                      // setPlateValue(e);
                      //  }}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography sx={{ textAlign: "start" }}>
                      Дүгнэлт огноо
                    </Typography>
                    <DateBox
                      value={documentDate}
                      // onValueChange={onValueChange}
                      defaultValue={
                        documentDate === ""
                          ? new Date().getDate() +
                            "-" +
                            parseInt(new Date().getMonth() + 1) +
                            "-" +
                            new Date().getFullYear()
                          : documentDate
                      }
                      displayFormat="yyyy-M-dd"
                      // onKeyUp={(e) => {
                      //   e.event.preventDefault();
                      //   setDocumentDate(e.event.target.value);
                      // }}
                      onValueChanged={(e) => {
                        // console.log(e.value);
                        setDocumentDate(e.value);
                      }}
                      type="date"
                      placeholder=" Дүгнэлт огноо."
                      // console.log(e);
                      // setPlateValue(e);
                      //  }}
                    />
                  </Box>
                </Stack>
                <TextArea
                  height={70}
                  value={isCheckedNote}
                  // maxLength={this.state.maxLength}
                  // defaultValue={this.state.value}
                  autoResizeEnabled={true}
                  placeholder="Тайлбар оруулах...."
                  onKeyUp={(e) => {
                    //  if (e.event.keyCode === 13) {
                    e.event.preventDefault();
                    setIsCheckedNote(e.event.target.value);
                    //console.log(e.event.target.value);
                    //}
                    // console.log(e.event.target.value);
                  }}
                />
              </Box>

              <Box sx={{ pb: 1, textAlign: "center" }}>
                {/* <input
                  type="file"
                  multiple={false}
                  accept={"*"}
                  onChange={ChooseFile}
                  ref={fileInput}
                  // ref={this.fileInput}
                  style={{ display: "none" }}
                />
                <Button
                  type="normal"
                  // style={this.props.ButtonStyle ? this.props.ButtonStyle : {}}
                  text="Файл сонгох"
                  icon="activefolder"
                  disabled={false}
                  onClick={() => {
                    fileInput.current.click();
                  }}
                /> */}

                {/* <Typography>{selectedFiles.name}</Typography> */}
                {/* <Box ref={(Label = createRef())} /> */}
                <FileUploader
                  selectButtonText="Файл хавсаргах"
                  labelText="эсвэл файлаа чирч оруулна уу"
                  multiple={true}
                  accept={"*"}
                  uploadMode={"useForm"}
                  //  uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                  onValueChanged={onSelectedFilesChanged}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    submitPolice();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>

          <Popup
            dragEnabled={true}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isAproved}
            showTitle={true}
            title="Мэргэжилтэн батлах хэсэг"
            onHiding={hideInfo}
            width={300}
            height={220}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              <Typography sx={{ pt: 1, pb: 2, textAlign: "center" }}>
                Та уг тээврийн хэрэгслийг батлах уу ?
              </Typography>
              {/* <Box sx={{ pb: 1, textAlign: "center" }}>
                <Stack
                  direction="row"
                  sx={{ pb: 0, mt: 1 }}
                  justifyContent={"space-evenly"}
                >
                  <Typography sx={{ textAlign: "center" }}>ID code</Typography>
                  <TextBox
                    value={vehicleIdcode}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setVehicleIdcode(e.event.target.value);
                    }}
                    placeholder="Id code оруулна уу."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Stack>
              </Box> */}

              <Stack
                direction="row"
                sx={{ pb: 2, mt: 1 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsApproved(false);
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //console.log(getSelectedData[0].id);
                    //  console.log(getSelectedData);
                    //  if (vehicleIdcode !== "") {
                    // console.log(getSelectedData);

                    getSelectedData.map((e) => {
                      const data = {
                        id: e.vehicle_id,
                        // smart_bus_id: vehicleIdcode,
                      };

                      dispatch(requestIsApprovedConfirm(e.id));
                      dispatch(createVehicle(data));
                    });
                    setIsApproved(false);
                    //   } else {
                    // notify(
                    //   {
                    //     message: "ID code оруулаагүй байна!!!",
                    //     width: 450,
                    //   },
                    //   "error"
                    // );
                    // }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isDelete}
            showTitle={false}
            title="Устгах"
            onHiding={hideInfo}
            width={250}
            height={100}
          >
            <Stack direction={"column"} justifyContent={"stretch"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "center" }}>
                Та уг хүсэлтийг устгах уу ?
              </Typography>
              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                />

                <Button
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    dispatch(removeRequestList(getSelectedData[0].id));
                    // deleteRecords();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked1}
            showTitle={true}
            title="Байцаагчид хувиарлах хэсэг"
            onHiding={hideInfo}
            width={350}
            height={180}
          >
            <Stack direction={"column"} justifyContent={"start"} spacing={2}>
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <DropDownBox
                  height={30}
                  value={typeDateGridBoxValue}
                  opened={isGridBoxOpenedType}
                  valueExpr="id"
                  deferRendering={true}
                  displayExpr={gridBoxDisplayExpr2}
                  placeholder="Байцаагч сонгох..."
                  showClearButton={false}
                  dataSource={userListData}
                  onValueChanged={(e) => {
                    setTypeDateGridBoxValue(e.value);
                    // console.log(e.value);
                    // props.getFilters("source_id", e.value);
                    // if (e.value === null) {
                    //   props.getFilters(null);
                    // }
                    // console.log(e.value);
                  }}
                  onOptionChanged={(e) => {
                    // console.log(e.name);
                    if (e.name === "opened") {
                      setIsGridBoxOpenedType(e.value);
                      // props.filterCompany(null);
                    }
                  }}
                  contentRender={dataTypeRender}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked1(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (typeDateGridBoxValue !== "") {
                      //  hideInfo();
                      // console.log(getSelectedData[0].id);
                      getSelectedData.map((e) =>
                        dispatch(
                          inspectorVehicleSetFun({
                            id: e.id,
                            inspector_id: typeDateGridBoxValue,
                          })
                        )
                      );

                      setIsChecked(false);
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={ispicView}
            showTitle={true}
            showIndicator={true}
            fullScreen={false}
            title="Медиа"
            onHiding={hideInfo}
            width={600}
            height={600}
          >
            <ScrollView width="100%" height="100%">
              <Gallery
                id="gallery"
                dataSource={attachment}
                width={"100%"}
                height={600}
                slideshowDelay={3000}
                loop={false}
                showNavButtons={true}
                dragEnabled={true}
                showIndicator={true}
              />
            </ScrollView>
          </Popup>
        </Box>
      </Box>
    </>
  );
};

export default RequestInData;
