export default function Validation(values) {
  //   console.log(fields);
  //   console.log(values);
  //   const validate = (values) => {
  const errors = {};

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors["email"] = 'И-мэйл хаягаа зөв оруулнаа уу.!!!"';
  }
  //console.log(errors);
  if (values.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      errors["password"] = "Нууц үг таарахгүй байна.";
    }
  }
  return errors;
  //   };
}
