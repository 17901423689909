import {
  AccountCircle,
  Clear,
  Lock,
  LockPerson,
  LoginRounded,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Container,
  Grid,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import Validation from "../../store/middleware/validation";
import { useNavigate, useLocation } from "react-router-dom";
import {
  LoginBannerImage,
  LoginBoxContainer,
  LoginButtonGroup,
  LoginTextField,
} from "../../styles/login";
import { Colors } from "../../styles/theme";

import { tokens, useMode } from "../../theme";
import { setSuccessSnackbarMessage } from "../../store/reducers/uiReducer";
import {
  // AuthVerify,
  guest,
  signIn as SignIn,
  signUpDan,
} from "../../store/reducers/user/auth";
import { signIn } from "../../store/reducers/user/auth";
import { useEffect, useState } from "react";
const Login = () => {
  const navigate = useNavigate();
  //  const theme = useTheme();
  const [theme2, colorMode] = useMode();
  // const colors = tokens(theme.palette.mode);
  // console.log(colorMode);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const signIn = async ({ data }) => {
    // console.log(data);
    await dispatch(SignIn({ data }));
    window.location = "/";
  };

  const submitForm = (data) => {
    const validate = Validation(data);

    if (Object.keys(validate).length > 0) {
      // dispatch(setSuccessSnackbarOpen(true));
      Object.keys(validate).map(function (key, index) {
        // console.log(validate[key]);
        dispatch(setSuccessSnackbarMessage(validate[key]));
      });

      return null;
    }

    signIn({ data });
    //  if (!loading) {

    // }
    // console.log(data);
  };
  return (
    <LoginBoxContainer
      sx={{
        left: 0,
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: "100vh",
          boxSizing: "border-box",
          display: "flex",
        }}
      >
        <Grid
          container
          // alignItems="center"
          direction="row"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            lg={3}
            md={4}
            sm={5}
            // direction="row"
            justifyContent={"center"}
            textAlign="center"
          >
            <Box
              sx={{
                backgroundColor: "#eff2f7",
                height: "100%",
              }}
            >
              <LoginBannerImage src="/assets/login.png" sx={{ mt: 3 }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            sm={5}
            sx={{ backgroundColor: "#10163a", textAlign: "center" }}
          >
            <Box
              sx={{
                color: Colors.white,
                textAlign: "left",
                pl: " 46px",
                pt: 2,
                pr: 5,
              }}
            >
              <form
                onSubmit={handleSubmit(submitForm)}
                id="myid-1"
                name="myid-1"
              >
                <Box sx={{ ml: 1 }}>
                  <Typography variant="h3" sx={{}}>
                    Нэвтрэх хэсэг
                  </Typography>
                  <Typography variant="p" sx={{}}>
                    Удирдлагын системд нэвтрэх хэсэг
                  </Typography>
                </Box>
                <Controller
                  name={"email"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LoginTextField
                      id="txt1"
                      autoFocus={true}
                      fullWidth={true}
                      // sx={{ width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                      type="email"
                      placeholder={"И-мэйл"}
                      onChange={onChange}
                      value={value || ""}
                      label={"И-мэйл хаяг"}
                      size={"medium"}
                      variant="filled"
                      // variant="standard"
                    />
                  )}
                />
                {errors.email?.type === "required" && (
                  <Typography
                    sx={{ fontSize: "12px", padding: "0px 13px 0px;" }}
                    severity="error"
                    color="error"
                  >
                    "И-мэйл хаяг оруулнаа уу"
                  </Typography>
                )}

                <Controller
                  name={"password"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LoginTextField
                      id="txt2"
                      fullWidth={true}
                      sx={{ width: "100%" }}
                      onChange={onChange}
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={"Нууц үг"}
                      value={value || ""}
                      label={"Нууц үг"}
                      size={"small"}
                      variant="filled"
                      // variant="standard"
                    />
                  )}
                />

                {errors.password?.type === "required" && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      padding: "0px 13px 0px;",
                    }}
                    severity="error"
                    color="error"
                  >
                    {" "}
                    "Нууц оруулнаа уу"{" "}
                  </Typography>
                )}
                <Stack
                  direction={"row"}
                  justifyContent="space-between "
                  sx={{ mt: 2 }}
                >
                  <Box>
                    <Checkbox defaultChecked />
                    Сануулах
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2">Нууц үг сэргээх</Typography>{" "}
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between "
                  sx={{ mt: 0 }}
                >
                  <LoginButtonGroup
                    sx={{
                      width: "100%",
                      color: Colors.white,
                      background: Colors.success,
                      "&:hover": {
                        backgroundColor: Colors.success,
                      },
                    }}
                    type="submit"
                    size="large"
                    color="primary"
                    disabled={loading}
                    variant="contained"
                    startIcon={<LoginRounded />}
                    // onClick={signIn}
                  >
                    {loading ? "Уншиж байна ..." : "Нэвтрэх"}
                  </LoginButtonGroup>
                  <LoginButtonGroup
                    size="large"
                    sx={{
                      width: "100%",
                      color: Colors.white,
                      background: Colors.info,
                      "&:hover": {
                        backgroundColor: Colors.info,
                      },
                    }}
                    startIcon={<Clear />}
                    variant="contained"
                  >
                    Арилгах
                  </LoginButtonGroup>
                </Stack>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </LoginBoxContainer>
  );
};

export default Login;
