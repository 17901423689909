import {
  Avatar,
  Box,
  IconButton,
  ListItemButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  CampaignTwoTone,
  Help,
  HelpTwoTone,
  LogoutOutlined,
  Person,
} from "@mui/icons-material";
import { Colors } from "../../styles/theme";
import { logout } from "../../store/reducers/user/auth";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "react-pro-sidebar";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const colorMode = useContext(ColorModeContext);
  const settings = ["Хувийн мэдээлэл", "Системээс гарах"];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showCartType, setShowCartType] = useState(null);
  const logedUser = useSelector((state) => state.auth.user);
  const [user, setUser] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = async () => {
    // console.log("ggfgf");
    await dispatch(logout());
  };

  const handleClick = (event, type) => {
    //console.log(type);
    setAnchorEl(event.currentTarget);
    setShowCartType(type);

    // navigate("/adsCartDetial");
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    // console.log(event);
  };
  const faqMenu = () => {
    navigate("/faq");
    // console.log(event);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    //console.log(event);
  };
  const menuSettingClick = (menu) => {
    //console.log(menu);
    switch (menu) {
      case 0:
        navigate("/");
        break;
      // case 1:
      //   navigate("/myAds");
      //   break;
      case 1:
        logOut();
        break;

      default:
        break;
    }
  };
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Хайх" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}

      <Box display="flex">
        <IconButton
          sx={{ borderRadius: 0 }}
          onClick={colorMode.toggleColorMode}
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton sx={{ borderRadius: 0 }}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton
          sx={{ borderRadius: 0 }}
          onClick={() => {
            faqMenu();
          }}
        >
          <HelpTwoTone />
        </IconButton>
        <Box sx={{ flexGrow: 0, m: 1 }}>
          <Tooltip title="хэрэглэгч тохиргоо">
            <IconButton onClick={handleOpenUserMenu} sx={{ borderRadius: 0 }}>
              <Stack direction={"row"} spacing={2}>
                <Avatar
                  sizes="xl"
                  alt="Remy Sharp"
                  src={"/assets/profile.png"}
                />
                <Stack direction={"column"}>
                  <Typography variant="caption">Сайн байн уу?</Typography>
                  <Typography variant="body2">
                    {logedUser !== null
                      ? logedUser.lastname.substring(0, 1)
                      : ""}
                    .{logedUser !== null ? logedUser.firstname : ""}
                  </Typography>
                </Stack>
              </Stack>
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <ListItemButton
                  onClick={() => {
                    menuSettingClick(index);
                  }}
                >
                  {index === 0 ? (
                    <Person sx={{ fontSize: "18px", color: Colors.primary }} />
                  ) : (
                    // : index === 1 ? (
                    //   <CampaignTwoTone
                    //     sx={{ fontSize: "18px", color: Colors.primary }}
                    //   />
                    // )
                    <LogoutOutlined
                      sx={{ fontSize: "18px", color: Colors.primary }}
                    />
                  )}
                  <Typography variant="caption" textAlign="center">
                    {setting}
                  </Typography>
                </ListItemButton>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
