import { createSlice } from "@reduxjs/toolkit";

import {
  inspectionCall,
  companyDataCall,
  companyImageCall,
  companyRemove,
  companySearchCall,
  companyEditCall,
  companyTypeDataCall,
} from "../../utils/services/companyService";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
export const slice = createSlice({
  name: "company",
  initialState: {
    companyData: null,
    // companyTypeData: null,
    companySearchData: null,
    companyInspectionData: null,
    trailerInspectionData: null,
    filter: {},
    isLoading: true,
  },

  reducers: {
    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setCompanySearchData: (state, { payload }) => {
      // console.log(payload);
      state.companySearchData = payload;
    },
    setCompanyData: (state, { payload }) => {
      // console.log(payload);
      state.companyData = payload;
    },
    // setCompanyTypeData: (state, { payload }) => {
    //   // console.log(payload);
    //   state.companyTypeData = payload;
    // },
    setCreateCompany: (state, { payload }) => {
      // console.log(payload);
      state.companyData.unshift(payload);
    },
    setRemoveCompany: (state, { payload }) => {
      // console.log(payload);

      let index = state.companyData.findIndex(({ id }) => id === payload);
      state.companyData.splice(index, 1);
    },
  },
});

export const {
  setCompanyInspectionData,
  setTrailerInspectionData,
  setLoading,
  setCompanySearchData,
  setCompanyData,
  // setCompanyTypeData,
  setCreateCompany,
  setRemoveCompany,
  setFilter,
} = slice.actions;

export const getCompany = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await companyDataCall(filter);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData);
      dispatch(setCompanyData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
// export const getCompanyTypeData = () => async (dispatch, getState) => {
//   try {
//     const responseData = await companyTypeDataCall();
//     //  console.log("kkkkkkk");
//     if (responseData) {
//       //console.log(responseData.data);
//       dispatch(setCompanyTypeData(responseData.data));
//       dispatch(setLoading(false));
//       // dispatch(setCompanyInspectionData(responseData.data));
//     } else {
//       dispatch(setErrorSnackbarOpen(true));
//       dispatch(setSuccessSnackbarMessage(responseData.message));
//       dispatch(setPosition("right"));
//     }

//     //console.log(alert);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const removeCompanyList = (companyId) => async (dispatch, getState) => {
  try {
    const responseData = await companyRemove(companyId);
    if (responseData.status.code === 200) {
      dispatch(setRemoveCompany(companyId));
      dispatch(setSuccessSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.message));
      dispatch(setPosition("right"));
    }
  } catch (error) {
    console.log(error);
  }
};
export const editCompany = (formData, config) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await companyEditCall(formData);
    // console.log(responseData);
    if (responseData) {
      // console.log(responseData.data);
      // dispatch(setCompanySearchData(responseData.data));
      // dispatch(setCompanyInspectionData(responseData.data));
    } else {
      //console.log(responseData);
      dispatch(setErrorSnackbarOpen(true));
      dispatch(setSuccessSnackbarMessage(responseData.status.message));
      dispatch(setPosition("right"));
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;
