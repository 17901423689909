import {
  Avatar,
  Box,
  IconButton,
  ListItemButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  CampaignTwoTone,
  Help,
  HelpTwoTone,
  LogoutOutlined,
  Person,
} from "@mui/icons-material";
import { Colors } from "../../styles/theme";
import { logout } from "../../store/reducers/user/auth";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "react-pro-sidebar";

const FooterBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const colorMode = useContext(ColorModeContext);
  const settings = ["Хувийн мэдээлэл", "Системээс гарах"];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const logedUser = useSelector((state) => state.auth.user);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      sx={{ background: "#1F2A40!important" }}
    >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Хайх" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}

      <Box>
        Хаяг: Улаанбаатар хот, Чингэлтэй дүүрэг, 4 дүгээр хороо, Бага тойруу 15,
        Нийслэлийн засаг захиргааны 4 байр 11 давхар
      </Box>
    </Box>
  );
};

export default FooterBar;
