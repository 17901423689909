export const markersData = [
  {
    location: [47.91633195, 106.90669610658803],
    tooltip: {
      text: "Талбай",
    },
  },
  {
    location: "47.91800014874037, 106.92678048771744",
    tooltip: {
      text: "Талбай",
    },
  },
];

// export const routesData = [
//   {
//     weight: 6,
//     color: "blue",
//     opacity: 0.5,
//     mode: "",
//     locations: [
//       [40.7825, -73.966111],
//       [40.755833, -73.986389],
//     ],
//   },
// ];
