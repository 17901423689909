import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";

export const LoginBoxContainer = styled(Box)(({ theme }) => ({
  //display: "flex",
  position: "absolute",
  top: 0,
  lef: 0,

  width: "100%",
  height: "100%",
  background: `url('/assets/background.jpeg')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: " no-repeat",
  color: Colors.black,

  // paddingLeft: "3rem",
  // paddingRight: "3rem",

  [theme.breakpoints.down("md")]: {
    //width: "100%",
  },
}));
export const LoginTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  color: Colors.white,
}));

export const LoginTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputLabel-root": {
    color: Colors.white,
  },
  // ".MuiInput-root": {
  //   fontSize: "12px",
  // },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },

  // marginTop: "1rem",
  // //borderRadius: "10px",
  // color: Colors.black,
  // background: Colors.white,
  // ".MuiInputLabel-root::before": {
  //   borderBottom: `1px solid ${Colors.primary}`,
  // },
  margin: "1rem",
  // padding: "10px 10px 10px 10px",
}));
export const LoginButtonGroup = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
  name: "MyShopButton",
  slot: "Root",

  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "primary" && Colors.success,
    props.color === "secondary" && styles.secondary,
  ],
})(({ theme }) => ({
  // padding: "20px 0px",
  color: Colors.white,
  // fontWeight: "bold",
  // fontsSize: "16px",

  margin: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    // padding: "10px 0px",
    // fontSize: "12px",
  },
}));
export const LoginBannerImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  // width: "100%",
  verticalAlign: "middle",
  // paddingTop: "2rem",
  //height: "250px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // height: "250px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    //height: "200px",
  },
}));
