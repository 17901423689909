import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  useMediaQuery,
  Typography,
  Stack,
  ImageListItem,
  IconButton,
  ImageList,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  companyReqeustVehicleFun,
  getRequest,
  removeRequestList,
  requestIsApprovedConfirm,
  requestIsCheckedConfirm,
  setFilter,
} from "../../../../store/reducers/requestVehicle";
import { DataGridConfig } from "../../../../config";
import { blue } from "@mui/material/colors";
import { DateBox, FileUploader, TextArea, TextBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { getVehicle } from "../../../../store/reducers/vehicle";
import moment from "moment";
import { Cancel, CancelOutlined, Delete, Remove } from "@mui/icons-material";
const MAX_COUNT = 3;
const RequestInData = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const vehicleDataList = useSelector((state) => state.vehicle.vehicleData);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const requestDataList = useSelector(
    (state) => state.requestVehicle.requestData
  );
  const filter = useSelector((state) => state.vehicle.filter);
  const [isChecked, setIsChecked] = useState(false);
  const [isAproved, setIsApproved] = useState(false);
  const [isCheckedNote, setIsCheckedNote] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [documentDate, setDocumentDate] = useState(null);
  const dispatch = useDispatch();

  const [multiple, setMultiple] = useState(true);
  const [uploadMode, setUploadMode] = useState("instantly");
  const [accept, setAccept] = useState("image");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const onSelectedFilesChanged = useCallback(
    (e) => {
      const uploaded = [...selectedFiles];
      let limitExceeded = false;
      e.value.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          // uploaded.push(file);

          setSelectedFiles([...selectedFiles, file]);

          // console.log(uploaded.length + 1);
          if (uploaded.length === MAX_COUNT) setFileLimit(true);

          if (uploaded.length > MAX_COUNT) {
            notify({
              message: `Хамгийн ихдээ ${MAX_COUNT + 1} зураг оруулна уу!!!`,
              type: "error",
            });
            removeImage(file);
            // var newFiles = [...selectedFiles];
            // // const currentIndex = selectedFiles.indexOf(uploadMode[0].image);
            // newFiles.splice(-1);
            // setSelectedFiles(newFiles);
            //  dispatch(setPosition("right"));
            // alert(`hamgiin ihdee ${MAX_COUNT}`);
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        } else {
          console.log("noooo");
        }

        // setSelectedFiles(...selectedFiles, e.value);
        // setSelectedFiles(e.value);
      });
    },
    [selectedFiles]
  );
  const removeImage = (image, i) => {
    // console.log(image);
    var newFiles = [...selectedFiles];

    const currentIndex = selectedFiles.indexOf(image);
    // const currentIndex2 = upload.indexOf(image);
    if (currentIndex !== -1) {
      //file.slice(currentIndex);
      newFiles.splice(selectedFiles.indexOf(image), 1);
    }
    setSelectedFiles(newFiles);

    // console.log(newFiles);
  };
  // console.log(selectedFiles);
  const setFilters = (field, value) => {
    //  console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  const submitRequest = () => {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();
    // console.log(selectedFiles);

    if (documentDate !== null && documentId !== "") {
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("vehicle_id", getSelectedData[0].id);
      formData.append("official_letter_number", documentId);
      formData.append("official_letter_date", documentDate);
      formData.append("note", "Тээврийн хэрэгсэл бүртгүүлэх хүсэлт");

      //  formData.append("attachment", uploadedFiles);
      if (selectedFiles.length > 0) {
        Array.from(selectedFiles).forEach((img) => {
          //  console.log(img);
          formData.append("attachment", img);
        });
      }
      dispatch(companyReqeustVehicleFun(formData));
      // getSelectedData.map((e) => {
      //   dispatch(
      //     companyReqeustVehicleFun({
      //       id: 0,
      //       vehicle_id: e.id,
      //       note: "Тээврийн хэрэгсэл бүртгүүлэх хүсэлт",
      //       attachment: selectedFiles,
      //     })
      //   );
      // });
      setIsApproved(false);
    } else {
      notify(
        {
          message: "Талбарыг гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };
  useEffect(() => {
    if (vehicleDataList == null) {
      // setFilters("is_approved", 1);

      setFilters("is_approved", 0);
    }
  }, [vehicleDataList]);
  useEffect(() => {
    if (vehicleDataList == null) {
      // setFilters("is_approved", 1);

      dispatch(getVehicle({ is_approved: 0, is_requested: 0 }));
    }
  }, [vehicleDataList, filter, dispatch]);
  useEffect(() => {
    if (vehicleDataList) {
      setFormData(vehicleDataList);
      gridRef.current.instance.endCustomLoading();
    }
  }, [vehicleDataList]);

  //console.log(props);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    setIsChecked(false);
    setIsDelete(false);
    // setFormEditData("");
  };

  const setStoreVehicle = (data) => {
    gridRef.current.instance.refresh();
    setIsPop(false);
  };
  function deleteRecords() {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();
    getSelectedData.map((e) => dispatch(removeRequestList(e.id)));
    setIsDelete(false);

    gridRef.current.instance.refresh();
  }
  console.log(formData);
  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    gridRef.current.instance.refresh();
                    //  console.log(gridRef.current.instance.getSelectedRowsData());
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"check"}
                  onClick={() => {
                    setSelectedFiles([]);
                    // console.log(selectedFiles);
                    setDocumentId("");
                    setDocumentDate(null);
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      setIsApproved(true);

                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message:
                            "Хүсэлт илгээх тээврийн хэрэгсэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Хүсэлт илгээх"
                />
              </Box>
            </Item>
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={(e) => {
                    // console.log(e.row);

                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      setIsDelete(true);
                    } else {
                      notify(
                        {
                          message: "Устгах хүсэлтээ сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    //gridRef.current.instance.deselectAll();
                  }}
                  type={"danger"} 
                  text="Устгах"
                />
              </Box>
            </Item> */}
            <Item location="after">
              <Box>
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
            {/* <Item location="center">
              <Box sx={{ mb: 2 }}>
                <Typography variant="h3">
                  Тээврийн хэрэгслийн хүсэлтүүд жагсаалт
                </Typography>
              </Box>
            </Item> */}
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={320}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            onSelectionChanged={onSelectionChanged}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={false}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "90%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                  {/* <Item dataField="official_letter_number" />
                  <Item dataField="official_letter_date" /> */}
                </Item>
                <Item
                  itemType="group"
                  caption="Тээврийн хэрэгсэл мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="garage_no" />
                  <Item dataField="plate_no" />
                  <Item dataField="cabin_no" />
                  <Item dataField="mark_name" />
                  <Item dataField="model_name" />

                  <Item dataField="color_name" />
                  <Item dataField="vehicle_service_type_name" />
                  <Item dataField="seat_count" />
                  <Item dataField="fuel_type_name" />
                  <Item dataField="engine_model_name" />
                  <Item dataField="max_load" />
                  <Item dataField="length" />
                  <Item dataField="own_weight" />
                  <Item dataField="total_weight" />
                </Item>

                <Item dataField="created" />
                <Item dataField="checked_note" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                width={130}
                format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"ААНБ-н регистер"}
              />
              <Column
                dataField="company_name"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                format="fixedPoint"
                caption={"ААНБ-н нэр"}
              />
            </Column>
            <Column
              dataField="garage_no"
              format="fixedPoint"
              caption={"Граашийн дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              allowSorting={true}
              width={200}
              dataField="is_requested"
              caption="Төлөв"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 0) {
                  return (
                    <Chip
                      label="Хүсэлт илгээгээгүй"
                      size="small"
                      color="warning"
                    />
                  );
                } else if (p.value === 1) {
                  return <Chip label="Хүсэлт илгээгээгүй" size="small" />;
                }
                return <span className="status status-success">{p.value}</span>;
              }}
            />

            <Column
              dataField="plate_no"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Улсын дугаар"}
            />
            <Column
              dataField="cabin_no"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Арлын дугаар"}
            />
            <Column
              dataField="mark_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Марк"}
            />
            <Column
              dataField="model_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Загвар"}
            />
            <Column
              dataField="imported_date"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Орж ирсэн"}
            />
            <Column
              dataField="color_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Өнгө"}
            />
            <Column
              dataField="vehicle_service_type_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Төрөл"}
            />
            <Column
              dataField="fuel_type_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Шатахууны төрөл"}
            />
            <Column
              dataField="engine_model_name"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Моторын модел"}
            />
            <Column
              dataField="max_load"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Даац"}
            />
            <Column
              dataField="length"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " см"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Урт"}
            />
            <Column
              dataField="own_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Өөрийн жин"}
            />
            <Column
              dataField="total_weight"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox
                    readOnly
                    value={e.value + " кг"}
                    valueChangeEvent="input"
                  />
                );
              }}
              caption={"Нийт жин"}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Тайлбар"}
            />
            <Column
              dataField="created"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Хүсэлт илгээсэн"}
            />
            <Column
              dataField="seat_count"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Суудал тоо"}
            />
            <Column
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Бүртгэсэн огноо"}
            />

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />

            <LoadPanel enabled={true} />
          </DataGrid>

          <Popup
            visible={isPop}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            position={"center"}
            title={
              isType === 2
                ? "Тээврийн хэрэгсэл засах"
                : "Тээврийн хэрэгсэл бүртгэх"
            }
            showTitle={true}
            width={900}
            //height={600}
          >
            sdfdsfds
          </Popup>

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked}
            showTitle={true}
            title="Байцаагч батлах хэсэг"
            onHiding={hideInfo}
            width={350}
            height={280}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "start" }}>
                Та уг тээврийн хэрэгслийг батлах уу ?
              </Typography>
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <TextArea
                  height={100}
                  value={isCheckedNote}
                  // maxLength={this.state.maxLength}
                  // defaultValue={this.state.value}
                  autoResizeEnabled={true}
                  placeholder="Тайлбар оруулах...."
                  onKeyUp={(e) => {
                    //  if (e.event.keyCode === 13) {
                    e.event.preventDefault();
                    setIsCheckedNote(e.event.target.value);
                    //console.log(e.event.target.value);
                    //}
                    // console.log(e.event.target.value);
                  }}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (isCheckedNote !== "") {
                      console.log(getSelectedData[0].id);
                      getSelectedData.map((e) =>
                        dispatch(requestIsCheckedConfirm(e.id, isCheckedNote))
                      );
                      setIsChecked(false);
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>

          <Popup
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isAproved}
            showTitle={true}
            title="Хүсэлт илгээх хэсэг"
            onHiding={hideInfo}
            width={530}
            height={"auto"}
          >
            <Stack direction={"column"} justifyContent={"start"} spacing={1}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start" }}>
                    Албан бичиг дугаар
                  </Typography>
                  <TextBox
                    value={documentId}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setDocumentId(e.event.target.value);
                    }}
                    placeholder=" Албан бичиг дугаар."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start" }}>
                    Албан бичиг огноо
                  </Typography>
                  <DateBox
                    value={documentDate}
                    // onValueChange={onValueChange}
                    defaultValue={
                      documentDate === ""
                        ? new Date().getDate() +
                          "-" +
                          parseInt(new Date().getMonth() + 1) +
                          "-" +
                          new Date().getFullYear()
                        : documentDate
                    }
                    displayFormat="yyyy-M-dd"
                    // onKeyUp={(e) => {
                    //   e.event.preventDefault();
                    //   setDocumentDate(e.event.target.value);
                    // }}
                    onValueChanged={(e) => {
                      // console.log(e.value);
                      setDocumentDate(e.value);
                    }}
                    type="date"
                    placeholder=" Албан бичиг огноо."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
              </Stack>

              <FileUploader
                selectButtonText="Зураг хавсаргах"
                multiple={multiple}
                uploadFailedMessage=""
                showFileList={false}
                removeImage={true}
                deleteRecords
                accept={accept}
                uploadMode={"instantly"}
                labelText="эсвэл файлаа чирч оруулна уу"
                // uploadMode={uploadMode}
                //uploadUrl="http://localhost:5001/"
                onValueChanged={onSelectedFilesChanged}
              />
              {selectedFiles.length === 0 ? (
                ""
              ) : (
                <div
                  className="content"
                  style={{
                    display: selectedFiles.length > 0 ? "block" : "none",
                  }}
                >
                  <ImageList
                    variant="quilted"
                    //cols={4}

                    rowHeight={121}
                    sx={selectedFiles.length > 0 ? { width: "100%" } : ""}
                    cols={5}
                    //rowHeight={"auto"}
                  >
                    {selectedFiles.map((elem, i) => {
                      //  console.log(elem);
                      return (
                        <ImageListItem key={i}>
                          <img
                            src={URL.createObjectURL(elem)}
                            srcSet={elem}
                            alt={elem}
                            loading="lazy"
                          />

                          <IconButton
                            onClick={() => {
                              // console.log(i);
                              removeImage(elem, i);
                            }}
                            sx={{ position: "absolute", right: -12, top: -8 }}
                          >
                            <CancelOutlined sx={{ color: "red" }} />
                          </IconButton>
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                  {/* <div>
                    <h4>Selected Files</h4>
                    {selectedFiles.map((file, i) => (
                      <div className="selected-item" key={i}>
                        <span>
                          {`Нэр: ${file.name}`}
                          <br />
                        </span>
                        <span>
                          {`Хэмжээ ${file.size}`}
                          <br />
                        </span>
                        <span>
                          {`Төрөл ${file.type}`}
                          <br />
                        </span>
                        <span>{`Last Modified Date: ${file.lastModifiedDate}`}</span>
                      </div>
                    ))}
                  </div> */}
                </div>
              )}
              {/* <Box sx={{ pb: 1, textAlign: "center" }}>
                <TextArea
                  height={100}
                  value={isCheckedNote}
                  // maxLength={this.state.maxLength}
                  // defaultValue={this.state.value}
                  autoResizeEnabled={true}
                  placeholder="Тайлбар оруулах...."
                  onKeyUp={(e) => {
                    //  if (e.event.keyCode === 13) {
                    e.event.preventDefault();
                    setIsCheckedNote(e.event.target.value);
                    //console.log(e.event.target.value);
                    //}
                    // console.log(e.event.target.value);
                  }}
                />
              
              </Box> */}

              <Stack
                direction="row"
                spacing={2}
                sx={{ pb: 2 }}
                justifyContent={"flex-end"}
              >
                <Button
                  width={120}
                  text="Үгүй"
                  icon="remove"
                  onClick={() => {
                    setIsApproved(false);
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    submitRequest();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isDelete}
            showTitle={false}
            title="Устгах"
            onHiding={hideInfo}
            width={250}
            height={100}
          >
            <Stack direction={"column"} justifyContent={"stretch"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "center" }}>
                Та уг хүсэлтийг устгах уу ?
              </Typography>
              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                />

                <Button
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    deleteRecords();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
        </Box>
      </Box>
    </>
  );
};

export default RequestInData;
