import { Box, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
  ColumnFixing,
  ColumnChooser,
  LoadPanel,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";
import { setFilter, setRemFilter } from "../../../store/reducers/report";
import { getPorkurorReportFun } from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";

const PorkurorReportList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getPorkurorReportData = useSelector(
    (state) => state.report.porkurorReportData
  );
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);

  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  useEffect(() => {
    gridRef.current.instance.beginCustomLoading();
    if (getPorkurorReportData == null && Object.keys(filter).length > 0) {
      dispatch(getPorkurorReportFun(filter));
    } else {
      gridRef.current.instance.endCustomLoading();
      setFormData(getPorkurorReportData);
      // dispatch(setRemFilter());
    }
  }, [getPorkurorReportData, filter, dispatch]);
  // useEffect(() => {
  //   if (getPorkurorReportData == null && Object.keys(filter).length > 0) {
  //     dispatch(getPorkurorReportFun(filter));
  //   }
  // }, [getPorkurorReportData, filter, dispatch]);
  // console.log(filter);

  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getPorkurorReportFun(filter));
    }
  }, [filter, dispatch]);

  //console.log(props);
  // useEffect(() => {
  //   gridRef.current.instance.beginCustomLoading();
  //   if (props.porkurorReportListData) {
  //     setFormData(props.porkurorReportListData);
  //     gridRef.current.instance.endCustomLoading();
  //   }
  // }, [props]);
  // console.log(props.feedBackListData);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  // const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
  //   // console.log("selectedRowKeys");
  //   // console.log(selectedRowsData[0].id);
  //   setSelectIndex(selectedRowKeys[0]);
  //   setIsType(2);
  //   setFormEditData(selectedRowsData[0]);
  // };
  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }
  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getPorkurorReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        wordWrapEnabled={true}
        dataSource={formData}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        height={600}
      >
        <Selection mode="multiple" />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex - 1 + 1;
          }}
          fixed={true}
        />
        <Column dataField="offender_type" caption={"Субъект"} fixed={true} />
        <Column dataField="violation_date" caption={"Огноо"} groupIndex={0} />
        <Column
          dataField="violation_count"
          caption={"Нийт гомдол"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="total_moved"
          caption={"Харьяаллын дагуу шилжүүлсэн"}
          dataType="number"
          width={100}
        />
        <Column
          dataField="total_dont_agree"
          caption={"Татгалзах тогтоол үйлдсэн"}
          dataType="number"
          width={100}
        />

        <Column
          caption="Зөрчлийн хэрэг үүсгэсэн"
          alignment={"center"}
          fixed={false}
        >
          <Column
            dataField="total_prosecure"
            format="fixedPoint"
            caption={"Нийт"}
            dataType="number"
            width={100}
          />
          <Column
            dataField="total_canceled"
            format="fixedPoint"
            caption={"Хэрэгсэхгүй болгох тогтоол"}
            dataType="number"
            width={100}
          />
          <Column caption={"Шийдвэрлэсэн"}>
            <Column
              dataField="total_solved"
              format="fixedPoint"
              caption={"Тоо"}
              dataType="number"
              width={100}
            />
            <Column
              dataField="total_solved_penalty_amount"
              format="fixedPoint"
              caption={"Мөнгөн дүн"}
              dataType="number"
              width={100}
            />
            <Column
              dataField="total_solved_settlement"
              format="fixedPoint"
              caption={"Хохирол барагдуулалт"}
              dataType="number"
              width={100}
            />
          </Column>
        </Column>
        <Column
          caption="Хялбаршуулан шийдвэрлэсэн"
          alignment={"center"}
          fixed={false}
        >
          <Column
            dataField="total_easy"
            format="fixedPoint"
            caption={"Тоо"}
            dataType="number"
            width={100}
          />
          <Column
            dataField="total_easy_penalty_amount"
            format="fixedPoint"
            caption={"Мөнгөн дүн"}
            dataType="number"
          />
          <Column
            dataField="total_easy_settlement"
            format="fixedPoint"
            caption={"Хохирол барагдуулалт"}
            dataType="number"
            width={100}
          />
        </Column>
        <Column
          caption="ТОРГОХ ШИЙТГЭЛ НОГДУУЛСАН"
          alignment={"center"}
          fixed={false}
        >
          <Column
            dataField="total_penalty_count"
            format="fixedPoint"
            caption={"Тоо"}
            dataType="number"
            width={100}
          />
          <Column
            dataField="total_penalty_amount"
            format="fixedPoint"
            caption={"Мөнгөн дүн"}
            dataType="number"
            width={100}
          />
          <Column
            dataField="total_settlement"
            format="fixedPoint"
            caption={"Хохирол барагдуулалт"}
            dataType="number"
            width={100}
          />
        </Column>

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />

        <Summary>
          <GroupItem
            column="offender_type"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="Нийт"
          />
          <GroupItem
            column="violation_count"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat=" {0}"
          />
          <GroupItem
            column="total_moved"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_dont_agree"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_prosecure"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_canceled"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_solved"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_solved_penalty_amount"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_solved_settlement"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_easy"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_easy_penalty_amount"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_easy_settlement"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_penalty_count"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_penalty_amount"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <GroupItem
            column="total_settlement"
            summaryType="sum"
            showInGroupFooter={true}
            alignByColumn={true}
            displayFormat="{0}"
          />
          <TotalItem
            column="offender_type"
            summaryType="sum"
            // valueFormat="currency"
            customizeText={customizeDate}
          />
          <TotalItem
            column="violation_count"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            // customizeText={customizeDate}
          />
          <TotalItem
            column="total_moved"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            // customizeText={customizeDate}
          />
          <TotalItem
            column="total_dont_agree"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            // customizeText={customizeDate}
          />
          <TotalItem
            column="total_prosecure"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            // customizeText={customizeDate}
          />
          <TotalItem
            column="total_canceled"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_solved"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_solved_penalty_amount"
            summaryType="sum"
            displayFormat="{0}₮"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_solved_settlement"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_easy"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_easy_penalty_amount"
            summaryType="sum"
            displayFormat="{0}₮"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_easy_settlement"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_penalty_count"
            summaryType="sum"
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_penalty_amount"
            summaryType="sum"
            displayFormat="{0}₮"

            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
          <TotalItem
            column="total_settlement"
            summaryType="sum"
            dataType={"number"}
            displayFormat="{0}"
            // valueFormat="currency"
            //  customizeText={customizeDate}
          />
        </Summary>
        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default PorkurorReportList;
