//import { jwtDecode } from "jwt-decode";
import notify from "devextreme/ui/notify";
import { useDispatch, useSelector } from "react-redux";

class BaseHelper {
  ParseInt(Value) {
    try {
      if (!Value) {
        return 0;
      }
      var result = parseInt(parseFloat("" + Value));
      if (!isNaN(result)) {
        return result;
      } else {
        return 0;
      }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
    }
  }

  // tokenCheck() {
  //   try {
  //     let token = localStorage.getItem("token");
  //     let decodedToken = jwtDecode(token);
  //     // process.env.NODE_ENV === "development" &&
  //     //   console.log("Decoded Token", decodedToken);
  //     let currentDate = new Date();

  //     // JWT exp is in seconds
  //     if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //       //   process.env.NODE_ENV === "development" && console.log("Token expired.");
  //       return true;
  //       // state.isExpired = true;
  //       // getGuestToken();
  //     } else {
  //       return false;
  //     }
  //   } catch (ex) {
  //     process.env.NODE_ENV === "development" && console.log(ex);
  //   }
  // }
  currancyFormat = function (num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "₮";
  };
  CusromFormat = function (num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  permitionCheck(permitionId, childId, type, user) {
    try {
      //console.log(user);
      const perm = user.permission.find((e) => {
        if (e.id === permitionId) {
          switch (type) {
            case "is_update":
              return e.is_update === 1;
              break;
            case "is_create":
              return e.is_create === 1;
              break;
            case "is_delete":
              return e.is_delete === 1;
              break;

            default:
              break;
          }
        } else {
        }
      });
      // console.log(perm);
      if (childId > 0) {
        const permChild = user.permission.find((e) => e.id === permitionId);
        const permCheck = permChild.child.find((e) => {
          if (e.id === childId) {
            switch (type) {
              case "is_update":
                return e.is_update === 1;
                break;
              case "is_create":
                return e.is_create === 1;
                break;
              case "is_delete":
                return e.is_delete === 1;
                break;

              default:
                break;
            }
          }
        });
        return typeof permCheck === "undefined" ? false : true;
      }

      // return typeof permCheck === "undefined" ? false : true;
      return typeof perm === "undefined" ? false : true;
      // let token = localStorage.getItem("token");
      // let decodedToken = jwtDecode(token);
      // // process.env.NODE_ENV === "development" &&
      // //   console.log("Decoded Token", decodedToken);
      // let currentDate = new Date();

      // // JWT exp is in seconds
      // if (decodedToken.exp * 1000 < currentDate.getTime()) {
      //   //   process.env.NODE_ENV === "development" && console.log("Token expired.");
      //   return true;
      //   // state.isExpired = true;
      //   // getGuestToken();
      // } else {
      //   return false;
      // }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
    }
  }
  permitionCheckTwoChild(permitionId, childId, childId2, type, user) {
    try {
      //console.log(user);

      // console.log(perm);
      if (childId > 0) {
        const permChild = user.permission.find((e) => e.id === permitionId);
        const permChild2 = permChild.child.find((e) => e.id === childId);
        const permCheck = permChild2.child.find((e) => {
          if (e.id === childId2) {
            switch (type) {
              case "is_update":
                return e.is_update === 1;
                break;
              case "is_create":
                return e.is_create === 1;
                break;
              case "is_delete":
                return e.is_delete === 1;
                break;

              default:
                break;
            }
          }
        });
        return typeof permCheck === "undefined" ? false : true;
      }

      // return typeof permCheck === "undefined" ? false : true;
      return typeof perm === "undefined" ? false : true;
      // let token = localStorage.getItem("token");
      // let decodedToken = jwtDecode(token);
      // // process.env.NODE_ENV === "development" &&
      // //   console.log("Decoded Token", decodedToken);
      // let currentDate = new Date();

      // // JWT exp is in seconds
      // if (decodedToken.exp * 1000 < currentDate.getTime()) {
      //   //   process.env.NODE_ENV === "development" && console.log("Token expired.");
      //   return true;
      //   // state.isExpired = true;
      //   // getGuestToken();
      // } else {
      //   return false;
      // }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
    }
  }
  GetBvhel(Value) {
    try {
      var result = parseInt(parseFloat("" + Value));
      if (!isNaN(result)) {
        var res = this.GetFloat(Value);
        if (res > result) {
          if (this.GetFloat(result) + this.GetFloat("0.5") <= res) {
            return result + 1;
          } else {
            return result;
          }
        }
        return result;
      } else {
        return 0;
      }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
    }
  }

  GetFloat(Value) {
    try {
      var result = parseFloat(Value);
      if (!isNaN(result)) {
        return result;
      } else {
        return 0;
      }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
    }
  }

  getValue = function (Obj, FieldName) {
    try {
      var Names = FieldName.split(".");
      var ValObj = Obj;
      for (var i = 0; i < Names.length; i++) {
        ValObj = ValObj[Names[i]];
      }

      return ValObj;
    } catch (ex) {
      return null;
    }
  };

  getDateYMD = function (Option) {
    var date = null;
    if (Option === undefined) {
      date = new Date();
    } else {
      if (Option.Date === undefined && Option.DateStr !== "") {
        date = new Date(Option.DateStr);
      }
      if (
        Option.Date !== undefined &&
        Option.Date !== null &&
        Option.DateStr === undefined
      ) {
        date = Option.Date;
      }
      if (
        (Option.Date === undefined || Option.Date === null) &&
        (Option.DateStr === undefined ||
          Option.DateStr === null ||
          Option.DateStr === "")
      ) {
        date = new Date();
      }
    }

    function pad2(n) {
      return (n < 10 ? "0" : "") + n;
    }
    return (
      date.getFullYear() +
      "-" +
      pad2(date.getMonth() + 1) +
      "-" +
      pad2(date.getDate())
    );
  };

  getDateYMDHMS = function (Option) {
    var date = null;
    if (Option === undefined) {
      date = new Date();
    } else {
      if (Option.Date === undefined && Option.DateStr !== "") {
        date = new Date(Option.DateStr.replace(".000Z", ""));
      }
      if (
        Option.Date !== undefined &&
        Option.Date !== null &&
        Option.DateStr === undefined
      ) {
        date = Option.Date;
      }
      if (
        (Option.Date === undefined || Option.Date === null) &&
        (Option.DateStr === undefined ||
          Option.DateStr === null ||
          Option.DateStr === "")
      ) {
        date = new Date();
      }
    }

    function pad2(n) {
      return (n < 10 ? "0" : "") + n;
    }
    return (
      date.getFullYear() +
      "-" +
      pad2(date.getMonth() + 1) +
      "-" +
      pad2(date.getDate()) +
      " " +
      pad2(date.getHours()) +
      ":" +
      pad2(date.getMinutes()) +
      ":" +
      pad2(date.getSeconds())
    );
  };

  getDateToStrFromStr = function (dateStr) {
    if (dateStr === undefined) {
      return new Date()
        .toISOString()
        .replace(/T/, " ") // replace T with a space
        .replace(/\..+/, "");
    } else {
      return dateStr
        .replace(/T/, " ") // replace T with a space
        .replace(/\..+/, "");
    }
  };

  getDateNumbers = function (date) {
    if (date === undefined) {
      return new Date()
        .toISOString()
        .replace(/T/, "")
        .replace(/\..+/, "")
        .replace(/-/g, "")
        .replace(/:/g, "");
    } else {
      return date
        .toISOString()
        .replace(/T/, "")
        .replace(/\..+/, "")
        .replace(/-/g, "")
        .replace(/:/g, "");
    }
  };

  ArraySort = function (property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };
  ArraySortDesc = function (property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  GetAgeDateStr = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  GetChangeArray = function (Rows, Dictionarys) {
    for (let i = 0; i < Rows.length; i++) {}
  };
  nodifyFun = function (message, type) {
    return notify(
      {
        message: message,
        height: "auto",
        width: 500,
        minWidth: 450,
        type: type,
        displayTime: 5000,
        animation: {
          show: {
            type: "fade",
            duration: 400,
            from: 0,
            to: 1,
          },
          hide: { type: "fade", duration: 40, to: 0 },
        },
      },
      {
        position: {
          top: 40,
          left: "40%",
          bottom: undefined,
          right: undefined,
        },
        // direction,
      }
    );
  };
}

export default new BaseHelper();
