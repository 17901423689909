import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import notify from "devextreme/ui/notify";
import {
  createVehicle,
  getVehicleInspectionData,
  getVehicleSearch,
} from "../../store/reducers/vehicle";
import { CheckBox, LoadIndicator, Lookup } from "devextreme-react";
import {
  setErrorSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
} from "../../store/reducers/uiReducer";
import { DropDownOptions } from "devextreme-react/autocomplete";

const UserAdd = (props) => {
  //==  const [plateNo, setPlateNo] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const [garage_no, setGarageNo] = useState("");
  const dispatch = useDispatch();
  const [plateValue, setPlateValue] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [vehicle_id, setVehicleId] = useState("");
  const [is_new_password, setIsNewPassword] = useState(0);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const vehicleSearchData = useSelector(
    (state) => state.vehicle.vehicleSearchData
  );
  const getVehicleInspection = useSelector(
    (state) => state.vehicle.vehicleInspectionData
  );
  console.log(props);

  //const textBoxRef = useRef();
  useEffect(() => {
    if (props.comptData) {
      setCompanyId(props.comptData.id);
    }
  }, [props]);
  const searchButton = {
    icon: "/assets/eye.png",
    //icon: "fa fa-envelope-o",
    type: "default",
    onClick: () => {
      dispatch(getVehicleSearch(plateValue));

      dispatch(getVehicleInspectionData(plateValue));
    },
  };
  const editVehicleFun = () => {
    if (garage_no !== "" && plateValue !== "") {
      const formData = new FormData();
      formData.append("id", vehicleSearchData.id);
      formData.append("company_id", company_id);
      formData.append("garage_no", garage_no);
      formData.append("is_approved", 0);

      dispatch(createVehicle(formData, 1));
      props.storeUser(formData);
    } else {
      notify(
        {
          message: "Мэдээлэл гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };
  const onValueChange = useCallback((v) => {
    const result = v;

    setPlateValue(result);
    // if (value) {

    // }
    // console.log(v);
  }, []);

  const rules = {
    x: /[0-9]/,
    y: /[й, м,ё,б,у,ө,а,х,ш,р,о,л,т,и,о,з,ф,ж,ы,э,г,с,ц,ч,н,я,е,к,в,д,п,ю,й,н,я,ү,ь]/,
  };

  return (
    <>
      <ScrollView width="100%" height="90%">
        <Box className="dx-fieldset-header">Байгуулагын мэдээлэл</Box>
        <Divider />
        <BoxDevExt direction="row" width="100%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">ААНБ-регистр:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    readOnly
                    value={props.comptData ? props.comptData.regnum : ""}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">ААНБ-нэр:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    value={props.comptData ? props.comptData.name : ""}
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>

        <Box className="dx-fieldset-header">Үндсэн мэдээлэл</Box>
        <Divider />
        <BoxDevExt direction="row" width="50%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Хандалтын эрх:</Box>
                <Box className="dx-field-value">
                  <Lookup
                    onSelectionChanged={(e) => {
                      // props.filterCompany(e.selectedItem.ID);
                      console.log(e.selectedItem.ID);
                      // setUserTypeId(e.selectedItem.ID);
                    }}
                    //  items={employeesTasks}
                    //defaultValue={employeesTasks[0].ID}
                    // displayExpr={getDisplayExpr}
                    valueExpr="ID"
                  >
                    <DropDownOptions showTitle={false} />
                  </Lookup>
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>

        <BoxDevExt
          displayExpr={is_new_password === 1 ? true : false}
          direction="row"
          width="50%"
        >
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Нууц үг:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    mode="password"
                    onValueChange={(v) => {
                      setPassword(v);
                    }}
                    value={password}
                    valueChangeEvent="input"
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
        <BoxDevExt direction="row" width="50%">
          <Item ratio={1}>
            <Box className="dx-fieldset">
              <Box className="dx-field">
                <Box className="dx-field-label">Нууц үг давтах:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    mode="password"
                    value={repassword}
                    onValueChange={(v) => {
                      setRepassword(v);
                    }}
                    valueChangeEvent="input"
                  />
                </Box>
              </Box>
            </Box>
          </Item>
        </BoxDevExt>
      </ScrollView>

      <Box
        sx={{
          position: "absolute",
          bottom: " 0px",
          width: "100%",
          borderTop: `1px solid #aaa`,
          padding: "10px 14px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Button
            text="Буцах"
            icon="back"
            onClick={() => {
              // props.searchVehBtn();
            }}
          />

          <Button
            position="end"
            icon="save"
            text="Бүртгэх"
            type="default"
            onClick={() => {
              editVehicleFun();
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default UserAdd;
