import axios from "../../api/axiosInctance";
import { guest } from "../../store/reducers/user/auth";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
//const getToken1 = localStorage.getItem("token");
export const getDashboardDataCall = async () => {
  try {
    const responseData = await axios
      .post("/report/dashboard")
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getDriverReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_5", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationInspectorWorkCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_13a", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getDriverVehicleActivityCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_6", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportGrievanceConfirmedCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/grievance_1", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportGrievanceCall2 = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/grievance_2", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportGrievanceCall3 = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/grievance_3", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanCall1 = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/hr_1", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanCall2 = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/hr_2", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanCall3 = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/hr_3", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleDriverReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_4", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleCountAgeReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_3", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleCountBuildReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_1", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleCountMarkReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/activity_2", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getPorkurorReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_1", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getServiceTypeReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_2", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getInspectorReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_3", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getPenaltyReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_4", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getLegalProvisionReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_5", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationTypeReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_6", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationDetectionReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_7", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationCompanyReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_8", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationSubjectReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_9", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationAanTypeReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_10", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationInspectionDetectReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_11", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
export const getViolationInspectionTypeReportCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const responseData = await axios
      .post("/report/violation_12", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
