import axios from "../../api/axiosInctance";
import { guest } from "../../store/reducers/user/auth";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
//const getToken1 = localStorage.getItem("token");
export const employeTreeListCall = async (data) => {
  try {
    const getEmployeData = await axios
      .post("/company/branch/tree", data)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};
export const branchTreeListCall = async (branchFilter) => {
  try {
    let newFilter = {};
    Object.keys(branchFilter).map((key) => {
      if (branchFilter[key] !== null) {
        newFilter[key] = branchFilter[key];
      }
    });
    const getEmployeData = await axios
      .post("/company/branch/list", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};
export const branchAddCall = async (data) => {
  try {
    // console.log(data);
    const getEmployeData = await axios
      .post("/company/branch/edit", data)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};
export const branchDeleteCall = async (data) => {
  try {
    // console.log(data);
    const getEmployeData = await axios
      .post("/company/branch/delete", data)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};
export const employeDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    const getEmployeData = await axios
      .post("/company/employe/list", newFilter)
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getEmployeData;
  } catch (error) {
    console.log(error);
  }
};
// export const employeDataCall = async (filter) => {
//   try {
//     //console.log(axios);
//     let newFilter = {};
//     Object.keys(filter).map((key) => {
//       if (filter[key] !== null) {
//         newFilter[key] = filter[key];
//       }
//     });
//     // console.log(JSON.stringify(newFilter));
//     const getEmployeData = await axios
//       .get("/employe/getEmployes", {
//         params: {
//           pagesize: 1000,
//           filter: JSON.stringify(newFilter),
//         },
//       })
//       .then((res) => {
//         //console.log(res.data);
//         return res.data;
//       })
//       .catch((err) => {
//         console.log("Aldaaa");
//         let message =
//           typeof err.response !== "undefined"
//             ? err.response.data.message
//             : err.message;
//         console.warn("error", message);
//       })
//       .finally(() => {
//         // console.log("ddd");
//       });
//     return getEmployeData;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const employeRemove = async (employeId) => {
  try {
    // console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("/employe/removeEmploye", { id: employeId })
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};

export const createEmployeService = async (formData) => {
  try {
    //   console.log(plateNo);

    const getInspection = await axios
      .post("/company/employe/edit", formData)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        //console.log("Aldaaa");
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const inspectionCall = async ({ plate_no }) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("/employe/inspection", plate_no)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeSearchCall = async ({ regnum }) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("employe/data", regnum)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingListCall = async () => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/list")
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingTypeListCall = async () => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/type/list")
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingTypeEditCall = async (data) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/type/edit", data)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingTypeDeleteCall = async (data) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/type/delete", data)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingEditCall = async (data) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/edit", data)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const employeTrainingDeleteCall = async (data) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("user/training/delete", data)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined" ? err.response.data : err.message;
        //  console.log(message.status.message);
        const data = {
          status: { code: 400, message: message.status.message },
        };
        return data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
