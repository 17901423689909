import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";

import { getPenaltyReportFun } from "../../store/reducers/report";
import PenaltyReportList from "../../components/reports/penaltyReport/penalty_reportt_list";

const PenaltyReport = () => {
  const filter = useSelector((state) => state.report.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const dispatch = useDispatch();
  //   const setFilters = (field, value) => {
  //     //console.log(field, value);
  //     dispatch(setFilter({ ...filter, [field]: value }));
  //   };
  //console.log(filter);
  //   useEffect(() => {
  //     //console.log(Object.keys(filter).length);
  //     if (Object.keys(filter).length === 0) {
  //       dispatch(setFilter({ type_date: "month" }));
  //       //  dispatch(getPenaltyReportData(filter));
  //     }
  //   }, [filter, dispatch]);
  //   useEffect(() => {
  //     if (getPenaltyReportData == null && Object.keys(filter).length > 0) {
  //       dispatch(getPenaltyReportFun(filter));
  //     }
  //   }, [getPenaltyReportData, filter, dispatch]);
  //   useEffect(() => {
  //     //console.log(Object.keys(filter).length);
  //     if (Object.keys(filter).length > 0) {
  //       dispatch(getPenaltyReportFun(filter));
  //     }
  //   }, [filter, dispatch]);
  //   useEffect(() => {
  //     if (Object.keys(filter).length > 0) {
  //     //  dispatch(getFeedBack(filter));
  //     }
  //   }, [filter, dispatch]);
  //   const filterIsCompany = (filterData) => {
  //     // console.log(filter);
  //     //const data = selectedRowsData[0];
  //     if (filterData !== null) {
  //       setCompanyName(filterData[0]);
  //       setIsCompanyFilter(true);
  //     } else {
  //       setCompanyName(null);
  //       setIsCompanyFilter(false);
  //     }
  //   };

  return (
    <Box m="20px">
      <Header
        title="Шийтгэсэн заалт тайлан"
        subtitle="Шийтгэсэн заалт тайлан жагсаалт"
      />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <PenaltyReportList
          //  porkurorReportListData={getPenaltyReportData}
          // comptData={compName}
          //  isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PenaltyReport;
