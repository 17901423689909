import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useCallback, useEffect, useRef, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Form,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Texts,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import {
  getViolationCitizenTypeData,
  getViolationVehicleCrud,
  getViolationLegalProvisionEditFun,
  getViolationLegalProvisionFun,
  getViolationOffenderEditFun,
  getViolationOffenderFun,
  setViolationVehicleFilter,
  setViolationCompanyFilter,
  getViolationCompanyCrudFun,
  getViolationVehicleEditFun,
} from "../../store/reducers/metaCrud";
import {
  getVehicleInspectionData,
  getVehicleSearch,
} from "../../store/reducers/vehicle";
//import { Lookup , DropDownOptions } from "devextreme-react/lookup";

const ViolationVehicleCrud = () => {
  const gridRef = useRef();
  const getViolationVehicleData = useSelector(
    (state) => state.metaCrud.violationVehicleData
  );
  const getViolationCompanyData = useSelector(
    (state) => state.metaCrud.violationCompanyData
  );
  const getViolationVehicleFilter = useSelector(
    (state) => state.metaCrud.violationVehicleFilter
  );
  const getViolationCompanyFilter = useSelector(
    (state) => state.metaCrud.violationCompanyFilter
  );
  const violationCitizenTypeDataList = useSelector(
    (state) => state.metaCrud.violationCitizenTypeData
  );
  const vehicleSearchData = useSelector(
    (state) => state.vehicle.vehicleSearchData
  );
  const getVehicleInspection = useSelector(
    (state) => state.vehicle.vehicleInspectionData
  );

  const violationCompanyFilter = useSelector(
    (state) => state.metaCrud.violationCompanyFilter
  );
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [editRowKey, setEditRowKey] = useState(-1);
  const [selectRowIndex, setSelectRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const [plateValue, setPlateValue] = useState("");
  const [company_id, setCompanyId] = useState(null);
  const [vehicle_id, setVehicleId] = useState("");
  const dispatch = useDispatch();

  //console.log(filter);

  //   is_basic: 0,
  useEffect(() => {
    if (Object.keys(getViolationVehicleFilter).length === 0) {
      dispatch(setViolationVehicleFilter({ is_basic: 0 }));
    }
  }, [getViolationVehicleData, getViolationVehicleFilter, dispatch]);

  //console.log(Object.keys(getViolationVehicleFilter).length);
  useEffect(() => {
    if (
      getViolationVehicleData === null &&
      Object.keys(getViolationVehicleFilter).length > 0
    ) {
      //   dispatch(setFilter({ is_approved: 1 }));
      //dispatch(setViolationVehicleFilter({ is_basic: 0 }));
      dispatch(getViolationVehicleCrud(getViolationVehicleFilter));
    } else {
      setFormData(getViolationVehicleData);
    }
  }, [getViolationVehicleData, getViolationVehicleFilter, dispatch]);

  //------------------
  useEffect(() => {
    if (Object.keys(getViolationCompanyFilter).length === 0) {
      dispatch(setViolationCompanyFilter({ is_basic: 0 }));
    }
  }, [getViolationCompanyFilter, dispatch]);
  //console.log(Object.keys(getViolationVehicleFilter).length);
  useEffect(() => {
    if (
      getViolationCompanyData === null &&
      Object.keys(getViolationCompanyFilter).length > 0
    ) {
      //   dispatch(setFilter({ is_approved: 1 }));
      //dispatch(setViolationVehicleFilter({ is_basic: 0 }));
      dispatch(getViolationCompanyCrudFun(getViolationCompanyFilter));
    }
  }, [getViolationCompanyData, getViolationCompanyFilter, dispatch]);
  useEffect(() => {
    if (violationCitizenTypeDataList === null) {
      dispatch(getViolationCitizenTypeData());
    }
  }, [violationCitizenTypeDataList, dispatch]);
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length > 0) {
  //     dispatch(getFeedBack(filter));
  //   }
  // }, [filter, dispatch]);
  //console.log(getViolationCompanyData);
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowsData[0]);

    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };
  // function deleteRecords() {
  //   gridRef.current.instance.getSelectedRowsData().forEach((key) => {
  //     console.log(key);
  //     //  dispatch(removeCompanyList(key.id));
  //   });
  //   // this.setState({
  //   //   selectedItemKeys: [],
  //   // });
  //   gridRef.current.instance.refresh();
  // }
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;
      //console.log(e.changes[0].type);
      //  const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          const data = e.changes[0].data;

          if (Object.keys(data).length > 0) {
            data["id"] = vehicleSearchData.id;
          }

          console.log(data);
          dispatch(getViolationVehicleEditFun(data));
          // console.log(data);
          gridRef.current.instance.cancelEditData();
          e.cancel = true;
          // e.component.cancelEditData();
          gridRef.current.instance.cancelEditData();
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;
          // console.log(data);
          data["id"] = e.changes[0].key;

          dispatch(getViolationVehicleEditFun(data));
          gridRef.current.instance.cancelEditData();

          e.cancel = true;
        } else if (e.changes[0].type === "remove") {
          // console.log(e.changes[0].key);
          const data = { id: e.changes[0].key };
          console.log(data);

          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }
      // e.component.cancelEditData();
    },
    [vehicleSearchData]
  );

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    // console.log(editRowKey);
    setEditRowKey(editRowKey);
  }, []);
  const searchButton = {
    icon: "/assets/eye.png",
    //icon: "fa fa-envelope-o",
    type: "default",
    onClick: () => {
      dispatch(getVehicleSearch(plateValue));
      dispatch(getVehicleInspectionData(plateValue));
    },
  };
  const onValueChange = useCallback((v) => {
    const result = v;

    setPlateValue(result);
    // if (value) {

    // }
    // console.log(v);
  }, []);
  // function onEditorPreparing(e) {
  //   // treeListRef.current.instance.selectedRowKeys();
  //   console.log(e.row.data);
  //   e.allowEditing === true ? setEditRowData(e.row.data) : setEditRowData(null);
  // }
  return (
    <Box m="20px">
      <Header
        title="Зөрчил гаргагч тээврийн хэрэгсэл бүртгэх"
        subtitle="Зөрчил гаргагч тээврийн хэрэгсэл жагсаалт"
      />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    dispatch(getViolationLegalProvisionFun());
                    gridRef.current.instance.refresh();
                    //   console.log(gridRef.current.instance.getSelectedRowsData());
                    // setIsPopUpEdit(true);
                    // console.log(isPopUpEdit);
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"add"}
                  onClick={() => {
                    gridRef.current.instance.addRow();
                  }}
                  type={"default"}
                  text="Шинэ"
                />
              </Box>
            </Item>
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"edit"}
                  onClick={(e) => {
                    // if (selectIndex > -1) {
                    //   gridRef.current.instance.editRow(selectIndex);
                    // } else {
                    //   console.log("ffff");
                    // }
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //  console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      gridRef.current.instance.editRow(selectRowIndex[0] - 1);
                    } else {
                      notify(
                        {
                          message: "Засах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    //gridRef.current.instance.instance.deselectAll();
                  }}
                  type={"success"}
                  text="Засах"
                />
              </Box>
            </Item> */}
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      for (let i = 0; i < getSelectedData.length; i++) {
                        const element = getSelectedData[i];

                        console.log(element);
                      }
                    } else {
                      notify(
                        {
                          message: "Устгах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item> */}
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
            rowAlternationEnabled={true}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={420}
            onRowDblClick={(row) => {
              setFormEditData(row.data);
              // console.log(row);
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            //   defaultSelectionFilter={selectionFilter}
            onSelectionChanged={onSelectionChanged}
            onSaving={onSaving}
            // onEditorPreparing={onEditorPreparing}
          >
            <Editing
              mode="popup"
              allowUpdating={true}
              //  allowAdding={true}
              allowDeleting={true}
              // changes={changes}
              useIcons={true}
              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popup
                title="тээврийн хэрэгсэл"
                showTitle={true}
                width={"40%"}
                height={350}
              />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                saveRowToNode={"ffff"}
                cancelRowChanges={"Цуцлах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_id" />
                </Item>
                <Item itemType="group" colCount={2} colSpan={2}>
                  {/* <Item dataField="citizen_type_id" /> */}

                  <Item ratio={1}>
                    <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">Улсын дугаар</Box>
                        <Box className="dx-field-value">
                          <TextBox
                            // ref={textBoxRef}

                            value={plateValue}
                            // validationStatus={"invalid"}
                            placeholder="Улсын дугаар"
                            onValueChange={onValueChange}
                            showClearButton={true}
                            // onEnterKey={onEnterKey}
                            onFocusIn={true}
                            onKeyUp={(e) => {
                              if (e.event.keyCode === 13) {
                                e.event.preventDefault();
                                // console.log(e.event.target.value);
                                // dispatch(getVehicleSearch(plateValue));
                                console.log("ok");
                                //  dispatch(getVehicleInspectionData(plateValue));
                              }
                            }}
                            valueChangeEvent="input"
                          >
                            <TextBoxButton
                              name="password"
                              location="after"
                              options={searchButton}
                            />
                          </TextBox>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">Марк:</Box>
                        <Box className="dx-field-value">
                          <TextBox
                            readOnly
                            value={
                              vehicleSearchData
                                ? vehicleSearchData.mark_name
                                : ""
                            }
                            valueChangeEvent="input"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">Загвар:</Box>
                        <Box className="dx-field-value">
                          <TextBox
                            readOnly
                            value={
                              vehicleSearchData
                                ? vehicleSearchData.model_name
                                : ""
                            }
                            valueChangeEvent="input"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Item>

                  <Item ratio={1}>
                    {/* <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">ААНБ сонгох</Box>
                        <Box className="dx-field-value">
                          <Lookup
                            dataSource={[{ id: 1, name: "hgfhgf" }]}
                            grouped={true}
                            displayExpr="Subject"
                            //  inputAttr={groupedLookupLabel}
                          >
                            <DropDownOptions
                              hideOnOutsideClick={true}
                              showTitle={false}
                            />
                          </Lookup>
                        </Box>
                      </Box>
                    </Box> */}
                    <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">Арлын №:</Box>
                        <Box className="dx-field-value">
                          <TextBox
                            readOnly
                            value={
                              vehicleSearchData
                                ? vehicleSearchData.cabin_no
                                : ""
                            }
                            valueChangeEvent="input"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="dx-fieldset">
                      <Box className="dx-field">
                        <Box className="dx-field-label">Өнгө:</Box>
                        <Box className="dx-field-value">
                          <TextBox
                            readOnly
                            value={
                              vehicleSearchData
                                ? vehicleSearchData.color_name
                                : ""
                            }
                            valueChangeEvent="input"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Item>

                  {/* <Item dataField="company_id" />
                  <Item dataField="lastname" />
                  <Item dataField="firstname" />
                  <Item dataField="phone" />
                  <Item dataField="address" /> */}
                </Item>

                {/* <Item
                  itemType="group"
                  caption="Home Address"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="StateID" />
                  <Item dataField="Address" />
                </Item> */}
              </Form>
            </Editing>

            <FilterRow visible={true} />
            <ColumnChooser enabled={false} />

            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column
              dataField="plate_no"
              format="fixedPoint"
              caption={"Улсын дугаар"}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField="cabin_no"
              format="fixedPoint"
              caption={"Арлын дугаар"}
            />
            <Column
              dataField="mark_name"
              format="fixedPoint"
              caption={"Марк"}
            />
            <Column
              dataField="model_name"
              format="fixedPoint"
              caption={"Загвар"}
            />
            <Column
              dataField="color_name"
              format="fixedPoint"
              caption={"Өнгө"}
            />
            <Column dataField="company_id" caption="ААН">
              <Lookup
                dataSource={getViolationCompanyData}
                valueExpr="id"
                displayExpr="name"
              />
              <RequiredRule />
            </Column>

            {/* <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Огноо"}
            /> */}

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default ViolationVehicleCrud;
