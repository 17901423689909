import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import {
  Badge,
  BusAlert,
  Campaign,
  CampaignOutlined,
  DirectionsBus,
  Feedback,
  People,
  Person,
  Person2,
} from "@mui/icons-material";
import PieChart from "../../components/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDashboardDataFun } from "../../store/reducers/report";
import DriverPieChart from "../../components/driverPei";
import DriverPieManChart from "../../components/driverPeiMan";
import DriverPieWomenChart from "../../components/driverPeiWomen";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const dashboardDataList = useSelector((state) => state.report.dashboardData);
  useEffect(() => {
    if (dashboardDataList === null) {
      // console.log(Object.keys(filter).length);
      dispatch(getDashboardDataFun());
    }
  }, [dashboardDataList, dispatch]);
  // console.log(dashboardDataList);
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Удирдлагын хэсэг" subtitle="Админы удирдлагын хэсэг" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.grievance.total_count
                : "0"
            }
            subtitle="Нийт ирсэн санал,хүсэлт"
            progress="1"
            increase="100%"
            icon={
              <Feedback
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.vehicle.total_count
                : "0"
            }
            subtitle="Нийт тээврийн хэрэгсэл"
            progress="1"
            increase="100%"
            icon={
              <DirectionsBus
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.driver.total_count
                : "0"
            }
            subtitle="Нийт жолооч"
            progress={
              dashboardDataList != null
                ? "0." +
                  parseInt(
                    (dashboardDataList.driver.total_pd * 100) /
                      dashboardDataList.driver.total_count
                  )
                : "0"
            }
            increase={
              dashboardDataList != null
                ? parseInt(
                    (dashboardDataList.driver.total_pd * 100) /
                      dashboardDataList.driver.total_count
                  ) + "%"
                : "0"
            }
            icon={
              <Badge
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.user.total_count
                : "0"
            }
            subtitle="Нийт хэрэглэгч"
            progress="1"
            increase="100%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Нийт санал, хүсэлт
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart dashboardData={dashboardDataList} isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Санал хүсэлт төрлөөр
            </Typography>
          </Box>
          {dashboardDataList === null
            ? []
            : dashboardDataList.grievance.source.map((source, i) => (
                <Box
                  key={`${source.id}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Stack direction={"row"} spacing={1}>
                    <CampaignOutlined color={colors.greenAccent[500]} />

                    <Typography
                      color={colors.greenAccent[500]}
                      sx={{ fontSize: "16px" }}
                    >
                      {source.name}
                    </Typography>
                  </Stack>
                  {/* <Box color={colors.grey[100]}>{source.name}</Box> */}
                  <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                    width={"40%"}
                    height={"40px"}
                    sx={{
                      fontSize: "22px",
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    {source.total_count}
                  </Box>
                </Box>
              ))}
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Нийт жолооч насаар
            </Typography>

            <Stack direction={"row"} spacing={1}>
              <People />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.driver.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>

          <Box>
            <DriverPieChart
              isDashboard={true}
              dashboardData={dashboardDataList}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Эрэгтэй жолооч насаар
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <Person />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.driver.male.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <DriverPieManChart
              isDashboard={true}
              dashboardData={dashboardDataList}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Эмэгтэй жолооч насаар
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <Person2 />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.driver.female.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <DriverPieWomenChart
              isDashboard={true}
              dashboardData={dashboardDataList}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Зөрчил сараар
            </Typography>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ padding: "30px 30px 0 30px" }}
            >
              <Campaign />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.violation.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>

          <Box>
            <BarChart isDashboard={true} dashboardData={dashboardDataList} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="20px"
        >
          {/* <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            ТХ-н насжилтаар
          </Typography> */}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              ТХ-н насжилтаар
            </Typography>
            <Stack direction={"row"} spacing={1} sx={{ marginBottom: "15px" }}>
              <DirectionsBusIcon />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.vehicle.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{}}>
            <PieChart isDashboard={true} dashboardData={dashboardDataList} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
