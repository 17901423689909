import { Box, Typography, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";

import {
  getPorkurorReportFun,
  reportGrievanceConfirmedFun,
  reportHumanFun1,
  setFilter,
} from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";
import moment from "moment";
import { Colors } from "../../../styles/theme";

const HumanReportList1 = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const humanList1 = useSelector((state) => state.report.humanList1);
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const start = moment().add(-4, "y");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  // console.log(start);
  // console.log(moment().year() - 1);
  // console.log(humanList1);
  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (humanList1 == null) {
      dispatch(reportHumanFun1(filter));
    } else {
      //  gridRef.current.instance.endCustomLoading();
      setFormData(humanList1);
      // dispatch(setRemFilter());
    }
  }, [humanList1, filter, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(reportHumanFun1(filter));
    }
  }, [filter, dispatch]);
  //   useEffect(() => {
  //     //console.log(Object.keys(filter).length);
  //     if (Object.keys(filter).length > 0) {
  //       dispatch(getVehicleDriverReportFun(filter));
  //     }
  //   }, [filter, dispatch]);

  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }

  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                //   dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        selection={false}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        wordWrapEnabled={false}
        height={600}
        //summary={}
        // columns={gridColumns}
      >
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
          fixed={true}
        />

        <Column
          dataField="company.name"
          width={"auto"}
          format="fixedPoint"
          caption={"Албан тушаалын төрөл"}
        />
        {formData.map((val, i) => {
          console.log(val.job_titles[i].count);
          return (
            <Column
              caption={val.job_titles[i].name}
              alignment={"center"}
              width={"auto"}
            >
              <Column
                format="fixedPoint"
                allowSorting={true}
                caption={"Бүтцээр"}
                alignment={"end"}
                width={"auto"}
                cellRender={(v) => {
                  return "0";
                }}
              />
              <Column
                dataField={"job_titles.count"}
                format="fixedPoint"
                alignment={"end"}
                width={"auto"}
                caption={"Одоо ажиллаж байгаа"}
                cellRender={(v) => {
                  //   console.log(v.row.data.job_titles[i].count);
                  return (
                    <Typography sx={{ color: "#0094ff", fontWeight: 600 }}>
                      {v.row.data.job_titles[i].count}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="implementation.ontime.percent"
                format="fixedPoint"
                caption={"Хомсдолтой байгаа"}
                width={"auto"}
                alignment={"end"}
                cellRender={(v) => {
                  return "0";
                }}
              />
            </Column>
          );
        })}

        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default HumanReportList1;
