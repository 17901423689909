import {
  Box,
  useTheme,
  Button as Btn,
  Stack,
  Typography,
  Chip,
} from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";
import { Box as BoxDevExt, Item as ItemDev } from "devextreme-react/box";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  LoadPanel,
  Popup,
  Texts,
  Editing,
  Lookup,
  RequiredRule,
  PatternRule,
} from "devextreme-react/data-grid";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";

import "./style.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";

import { DataGridConfig } from "../../config";
import notify from "devextreme/ui/notify";
import {
  createDriver,
  getDriver,
  getDriverSearch,
  removeDriverList,
  setDriverSearchData,
} from "../../store/reducers/driver";
import { getCompany, setFilter } from "../../store/reducers/company";
import { LoadIndicator } from "devextreme-react";
import { driverRemove } from "../../utils/services/driverService";
import BaseHelper from "../../helper/BaseHelper";

const DriverList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const driverSearchData = useSelector(
    (state) => state.driver.driverSearchData
  );
  const filter = useSelector((state) => state.driver.filter);
  const getIsLoading = useSelector((state) => state.driver.isLoading);
  const filterComp = useSelector((state) => state.company.filter);
  const getCompanyList = useSelector((state) => state.company.companyData);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isCompChange, setIsCompChange] = useState(false);

  const [selectIndex, setSelectIndex] = useState(-1);
  const [editData, setEditData] = useState([]);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const [direction, setDirection] = useState("up-push");
  const [phonNumber, setPhonNumber] = useState(
    props.driverEditData ? props.driverEditData.phone_number : ""
  );
  const [register, setRegister] = useState(
    props.driverEditData ? props.driverEditData.register : ""
  );
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [coordinatePosition, setCoordinatePosition] = useState({
    top: 40,
    left: "50%",
    bottom: undefined,
    right: undefined,
  });
  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (props.driverListData) {
      setFormData(props.driverListData);
      //    gridRef.current.instance.endCustomLoading();
    }
  }, [props]);
  function timeout(number) {
    return new Promise((res) => setTimeout(res, number));
  }
  const test = async () => {
    //for 1 sec delay
    await timeout(5000);
  };

  useEffect(() => {
    if (Object.keys(filterComp).length === 0) {
      dispatch(setFilter({ is_basic: 1 }));
    }
  }, [filterComp, getCompanyList, dispatch]);
  useEffect(() => {
    if (getCompanyList == null && Object.keys(filterComp).length > 0) {
      dispatch(getCompany(filterComp));
    }
  }, [filterComp, getCompanyList, dispatch]);

  //console.log(props);
  const gridRef = useRef();

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    //console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    // setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };
  const searchButton = {
    icon: "/assets/eye.png",
    //stylingMode: "outlined",

    //icon: "fa fa-envelope-o",
    type: "default",
    disabled: getIsLoading ? true : false,
    onClick: (e) => {
      dispatch(getDriverSearch(register));
      //  searchVeh(e);
      // dispatch(getVehicleSearch(plateValue));
      // dispatch(getVehicleInspectionData(plateValue));
    },
  };
  // console.log(props.compData !== null ? props.comptData.id : "");
  const onValueChange = useCallback((v) => {
    const result = v;

    setRegister(result);
  }, []);
  const onValueChangePhone = useCallback((v) => {
    const result = v;

    setPhonNumber(result);
  }, []);
  //console.log(props);
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;
      // console.log(e.changes[0].type);

      const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          // console.log(e.changes);
          //  console.log(driverSearchData.id);
          //  const data = e.changes[0].data;
          const values = e.changes[0].data;
          // console.log(dd);
          const data = {
            id: driverSearchData.id,
            is_basic: 1,
            company_id: props.compData !== null ? props.comptData.id : "",
            phone_number: values.phone_number,
          };

          dispatch(createDriver(data));
          gridRef.current.instance.cancelEditData();
          e.cancel = true;

          // console.log(data);
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;

          data["id"] = e.changes[0].key;
          // data["legal_provision_id"] = 1;
          // data["is_active"] = 1;
          // if ("code" in data === false) {
          //   data["code"] = selectedData[0].code;
          // }
          // if ("rank" in data === false) {
          //   data["rank"] = selectedData[0].rank;
          // }
          // if ("name" in data === false) {
          //   data["name"] = selectedData[0].name;
          // }
          //console.log(data);
          // dispatch(createDriver(data));
          gridRef.current.instance.cancelEditData();

          e.cancel = true;
        } else if (e.changes[0].type === "remove") {
          const data = { id: e.changes[0].key };
          // console.log(data);
          dispatch(removeDriverList(data));

          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }

      // e.component.cancelEditData();
    },
    [driverSearchData, phonNumber, props, dispatch]
  );
  function avatarRender() {
    return (
      <img
        style={{ marginLeft: "20px", width: "120px", height: "140px" }}
        src={
          driverSearchData
            ? driverSearchData.picture_url
            : "/assets/profile.png"
        }
        alt=""
      />
    );
  }

  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getDriver(filter));
                gridRef.current.instance.refresh();
                // console.log(gridRef.current.instance.getSelectedRowsData());
                // setIsPopUpEdit(true);
                // console.log(isPopUpEdit);
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(39, 0, "is_create", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"add"}
              onClick={() => {
                // console.log(props.isCompanyFilter);
                if (logedUser.role !== "user") {
                  if (props.isCompanyFilter) {
                    dispatch(setDriverSearchData(null));
                    gridRef.current.instance.addRow();
                  } else {
                    notify(
                      {
                        message: "Аж ахуй нэгж сонгон уу!!!",
                        width: 450,
                      },
                      "error"
                    );
                  }
                } else {
                  gridRef.current.instance.addRow();
                }
              }}
              type={"default"}
              text="Шинэ"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        // showRowLines={true}
        //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
        rowAlternationEnabled={true}
        remoteOperations={true}
        dataSource={formData}
        keyExpr="id"
        onEditingStart={(e) => {
          setRegister(e.data.register);
          setPhonNumber(e.data.phone_number);
          dispatch(setDriverSearchData(null));
          setIsCompChange(true);
        }}
        onEditCanceled={(e) => {
          setIsCompChange(false);
          setRegister(null);
          setRegister(null);
        }}
        height={520}
        //wordWrapEnabled={true}
        onRowDblClick={(row) => {
          setFormEditData(row.data);
          // console.log(row);
          gridRef.current.instance.editRow(row.rowIndex);
        }}
        //   defaultSelectionFilter={selectionFilter}
        onSelectionChanged={onSelectionChanged}
        onSaving={onSaving}
      >
        <Editing
          mode="popup"
          allowUpdating={BaseHelper.permitionCheck(
            39,
            0,
            "is_update",
            logedUser
          )}
          allowDeleting={BaseHelper.permitionCheck(
            39,
            0,
            "is_delete",
            logedUser
          )}
          useIcons={true}
          //  changes={changes}
          //  onChangesChange={setChanges}
        >
          <Popup
            title="Жолооч"
            showTitle={true}
            width={!isNonMobile ? "100%" : "70%"}
            height={!isNonMobile ? "100%" : "60%"}
          />
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Мэдээлэл устгах"}
            saveRowChanges={"Хадгалах"}
            saveRowToNode={"ffff"}
            cancelRowChanges={"Цуцлах"}
            deleteRow="Устгах"
            addRow="Нэмэх"
            editRow="Засах"
            addRowToNode="Нэмэх"
          />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField={"register"} />
              <Item dataField={"surname"} />
              <Item dataField={"lastname"} />
              <Item dataField={"firstname"} />
              <Item dataField={"company_regnum"} />
              <Item dataField={"phone_number"} />

              {/* <Item
                visible={logedUser.role === "admin" ? true : false}
                dataField="company_id"
              /> */}
            </Item>
            <Item
              itemType="group"
              caption="Жолоочийн үнэмлэхний мэдээлэл"
              colCount={4}
              colSpan={2}
            >
              {/* <Item dataField={"picture_url"} /> */}

              <Item render={avatarRender} colSpan={0} />

              <Item itemType="group" colCount={2} colSpan={3}>
                <Item dataField={"dl_number"} />
                <Item dataField={"dl_b"} />
                <Item dataField={"pdl_number"} />
                <Item dataField={"gender"} />

                <Item dataField={"eye_color_name"} />
                <Item dataField={"blood_type_name"} />
                <Item dataField={"birthdate"} />
              </Item>
            </Item>
          </Form>
        </Editing>
        <FilterRow visible={true} />
        <Selection mode="multiple" />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        />
        <Column caption="Байгуулллага" fixed={true}>
          <Column
            allowEditing={false}
            dataField="company_regnum"
            width={130}
            format="fixedPoint"
            caption={"ААНБ-н регистер"}
          />
          <Column
            allowEditing={false}
            dataField="company_name"
            width={130}
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          />

          {/* <Column
            //   editCellRender={(e) => {

            allowEditing={
              logedUser.role === "admin" && isCompChange ? true : false
            }
            dataField="company_id"
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          >
            <Lookup
              dataSource={getCompanyList}
              valueExpr="id"
              displayExpr="name"
            />
          </Column> */}
          <Column
            dataField="is_approved"
            format="fixedPoint"
            caption={"Төлөв"}
            cellRender={(p) => {
              // console.log(p);
              if (p.value === 1) {
                return (
                  <Chip label="Баталгаажсан" size="small" color="success" />
                );
              } else if (p.value === 0) {
                return <Chip label="Баталгаажаагүй" size="small" />;
              }
              return <span className="status status-warning">{p.value}</span>;
            }}
          />
        </Column>

        <Column caption="Хувийн мэдэээлэл">
          <Column
            dataField="surname"
            format="fixedPoint"
            caption={"Ургийн овог"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={driverSearchData ? driverSearchData.surname : e.value}
                  valueChangeEvent="input"
                />
              );
            }}
          />
          <Column
            dataField="lastname"
            format="fixedPoint"
            caption={"Овог"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={driverSearchData ? driverSearchData.lastname : e.value}
                  valueChangeEvent="input"
                />
              );
            }}
          />
          <Column
            allowEditing={false}
            dataField="firstname"
            format="fixedPoint"
            caption={"Нэр"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData ? driverSearchData.firstname : e.value
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />

          <Column
            dataField="register"
            //format="fixedPoint"
            caption={"Регистер"}
            editCellRender={(e) => {
              // console.log(e);
              return (
                <TextBox
                  value={register}
                  placeholder="Регистерийн дугаар"
                  onValueChange={onValueChange}
                  showClearButton={true}
                  valueChangeEvent="input"
                  inputAttr={{ style: "text-transform: uppercase" }}
                >
                  <TextBoxButton
                    name="password"
                    location="after"
                    options={searchButton}
                  />
                </TextBox>
              );
            }}
          />
          <Column
            allowEditing={true}
            dataField="phone_number"
            format="fixedPoint"
            caption={"Утас"}
            // editCellRender={(e) => {
            //   return (
            //     <TextBox
            //       value={phonNumber}
            //       valueChangeEvent="input"
            //       onValueChange={onValueChangePhone}
            //     />
            //   );
            // }}
          >
            <RequiredRule />
            {/* <PatternRule
              message={'Your phone must have "(555) 555-5555" format!'}
              pattern={/^\(\d{3}\) \d{3}-\d{4}$/i}
            /> */}
          </Column>
          <Column
            dataField="eye_color_name"
            format="fixedPoint"
            allowEditing={false}
            caption={"Нүдний өнгө"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData ? driverSearchData.eye_color_name : e.value
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />

          <Column
            dataField="blood_type_name"
            format="fixedPoint"
            allowEditing={false}
            caption={"Цусны бүлэг"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData
                      ? driverSearchData.blood_type_name
                      : e.value
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />
          <Column
            dataField="birthdate"
            format="fixedPoint"
            allowEditing={false}
            caption={"Төрсөн огноо"}
            editCellRender={(e) => {
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData ? driverSearchData.birthdate : e.value
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />
        </Column>
        <Column caption="Жолооны үнэмлэх">
          <Column
            dataField="dl_number"
            allowEditing={false}
            format="fixedPoint"
            caption={"Үнэмлэх №"}
          />
          <Column
            dataField="dl_b"
            format="fixedPoint"
            caption={"Жолоо ангилал"}
            cellRender={(v) => {
              var data = [];
              if (v.row.data.dl_a !== null) {
                data.push("A,");
              }
              if (v.row.data.dl_b !== null) {
                data.push("B,");
              }
              if (v.row.data.dl_c !== null) {
                data.push("C,");
              }
              if (v.row.data.dl_d !== null) {
                data.push("D,");
              }
              if (v.row.data.dl_e !== null) {
                data.push("E,");
              }
              // console.log(v);
              return data.map((e) => e);
              // return e.driver_license.classification.map((e) =>
              //   e.started_at != null ? e.code : ""
              // );
            }}
            editCellRender={(v) => {
              var data = [];
              if (v.row.data.dl_a !== null) {
                data.push("A,");
              }
              if (v.row.data.dl_b !== null) {
                data.push("B,");
              }
              if (v.row.data.dl_c !== null) {
                data.push("C,");
              }
              if (v.row.data.dl_d !== null) {
                data.push("D,");
              }
              if (v.row.data.dl_e !== null) {
                data.push("E,");
              }

              // console.log(data.toString());
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData
                      ? driverSearchData.driver_license.classification.map(
                          (e) => (e.started_at != null ? e.code : "")
                        )
                      : v.data.dl_number
                      ? data.toString()
                      : ""
                  }
                  valueChangeEvent="input"
                />
              );

              // return e.driver_license.classification.map((e) =>
              //   e.started_at != null ? e.code : ""
              // );
            }}
          />
        </Column>
        <Column position="center" caption="Мэрэгшсэн жолооны үнэмлэх">
          <Column
            dataField="pdl_number"
            //  format="fixedPoint"
            caption={"Мэрэгшсэн ангилал"}
            allowEditing={false}
            cellRender={(v) => {
              var data = [];
              if (v.row.data.class_b !== null) {
                data.push("B");
              }
              if (v.row.data.class_c !== null) {
                data.push("C");
              }
              if (v.row.data.class_d !== null) {
                data.push("D");
              }
              if (v.row.data.class_inter_passenger !== null) {
                data.push("УХЗТ");
              }
              if (v.row.data.class_e !== null) {
                data.push("E");
              }
              // console.log(v);
              return data.map((e) => e);
              // return e.driver_license.classification.map((e) =>
              //   e.started_at != null ? e.code : ""
              // );
            }}
            editCellRender={(v) => {
              var data = [];
              if (v.row.data.class_b !== null) {
                data.push("B");
              }
              if (v.row.data.class_c !== null) {
                data.push("C");
              }
              if (v.row.data.class_d !== null) {
                data.push("D");
              }
              if (v.row.data.class_inter_passenger !== null) {
                data.push("УХЗТ");
              }
              if (v.row.data.class_e !== null) {
                data.push("E");
              }

              // console.log(data.toString());
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData
                      ? driverSearchData.pro_driver_license.classification.map(
                          (e) =>
                            e.is_expired !== 1 && e.expire_at !== ""
                              ? e.code
                              : ""
                        )
                      : v.data.pdl_number
                      ? data.toString()
                      : ""
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />

          <Column
            dataField="gender"
            allowEditing={false}
            format="fixedPoint"
            caption={"Хүйс"}
            editCellRender={(v) => {
              return (
                <TextBox
                  readOnly
                  value={
                    driverSearchData
                      ? driverSearchData.gender.name
                      : v.data.gender
                  }
                  valueChangeEvent="input"
                />
              );
            }}
          />
        </Column>
        <Column
          dataField="approved_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Баталгаажсан огноо"}
        />
        <Column
          dataField="created_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Бүртгэсэн"}
        />
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />
      </DataGrid>
    </>
  );
};

export default DriverList;
