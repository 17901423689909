import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  List,
  LoadPanel,
  Lookup,
  TextArea,
  TileView,
  TreeList,
  TreeView,
} from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { DropDownOptions } from "devextreme-react/autocomplete";

import {
  getUserModuleData,
  setUserPermission,
} from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";
import { GroupItem } from "devextreme-react/form";
import DataGrid, { Column, Label, Selection } from "devextreme-react/data-grid";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { getCompany } from "../../store/reducers/company";
import ArrayStore from "devextreme/data/array_store";
import { DataGridConfig } from "../../config";
import { borderColor } from "polished";
const EmployeModule = (props) => {
  const dispatch = useDispatch();
  const [selectedItemKeys, setSelectedItemKeys] = useState("");
  const getUserModuleDataList = useSelector(
    (state) => state.auth.userModuleData
  );

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [modules, setModules] = useState([]);
  // console.log(getEmployeTreeListData);
  // const getUserSexDataList = useSelector((state) => state.auth.userSexData);
  const [checkedValue, setCheckedValue] = useState(null);
  const [selectRowData, setSelectRowData] = useState(null);
  const gridRef = useRef();
  const CheckBoxRef = useRef();
  //const [moduleChangeData, setModuleChangeData] = useState({});
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };

  //   useEffect(() => {
  //     setBranch_id(
  //       getEmployeeDataList !== null ? getEmployeeDataList[0].branch_id : 0
  //     );

  //   }, [getEmployeeDataList, dispatch]);

  useEffect(() => {
    if (getUserModuleDataList === null) {
      const userId = { user_id: props.userData.id };
      dispatch(getUserModuleData(userId));
    }
  }, [getUserModuleDataList, dispatch]);
  useEffect(() => {
    if (getUserModuleDataList !== null) {
      var tempProps = JSON.parse(JSON.stringify(getUserModuleDataList));

      setModules(tempProps);
    }
  }, [getUserModuleDataList, dispatch]);
  const syncSelection = (treeView) => {
    const selectedEmployees = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);
    // console.log(selectedEmployees);
    setSelectedEmployees(selectedEmployees);

    // console.log(selectedEmployees.length > 0 ? selectedEmployees[0].id : "");

    // this.setState(() => ({ selectedEmployees }));
  };

  const expandedRowKeys = [1];
  function onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;

    if (rowData) {
      //  console.log(rowData);
      setSelectRowData(rowData);
      //   progress = rowData.Task_Completion ? `${rowData.Task_Completion}%` : '';
      //   cellValue = e.component.cellValue(e.row.rowIndex, 'Assigned');
      //   taskEmployees.byKey(cellValue).done((item) => {
      //     assigned = item.Name;
      //   });

      //   this.setState({
      //     taskSubject: rowData.Task_Subject,
      //     taskAssigned: assigned,
      //     startDate: new Date(rowData.Task_Start_Date).toLocaleDateString(),
      //     taskStatus: e.row.data.Task_Status,
      //     taskProgress: progress,
      //     focusedRowKey: e.component.option('focusedRowKey'),
      //   });
    }
  }
  return (
    <>
      <TreeList
        ref={gridRef}
        id="employees"
        dataSource={getUserModuleDataList}
        // defaultExpandedRowKeys={expandedRowKeys}
        focusedRowEnabled={true}
        autoExpandAll={true}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        itemsExpr="child"
        dataStructure="tree"
        onFocusedRowChanged={onFocusedRowChanged}
        keyExpr="id"
      >
        {/* <Selection mode="single" /> */}
        <Column dataField="name" caption="Модул нэр" />
        {/* <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        /> */}
        <Column
          dataField="is_master"
          width={130}
          //format="fixedPoint"
          caption={"Мастер"}
          cellRender={(v) => {
            //  console.log(v.value);
            // return

            return (
              <CheckBox
                ref={CheckBoxRef}
                onValueChanged={(args) => {
                  if (selectRowData !== null) {
                    // setCheckedValue(1);
                    // console.log(selectedRow);
                    const data = {
                      user_id: props.userData.id,
                      permission_id: selectRowData.id,
                      is_master: args.value ? 1 : 0,
                    };
                    console.log(data);
                    dispatch(setUserPermission(data));
                  }
                  //  console.log(checkedValue);
                }}
                style={{ border: "1px solid #3debd3ab" }}
                defaultValue={v.value === 1 ? true : false}
              />
            );
          }}
        />
        <Column
          dataField="is_read"
          width={130}
          //format="fixedPoint"
          caption={"Харах"}
          cellRender={(v) => {
            //  console.log(v.value);
            // return

            return (
              <CheckBox
                ref={CheckBoxRef}
                onValueChanged={(args) => {
                  if (selectRowData !== null) {
                    // setCheckedValue(1);
                    // console.log(selectedRow);
                    const data = {
                      user_id: props.userData.id,
                      permission_id: selectRowData.id,
                      is_read: args.value ? 1 : 0,
                    };
                    // console.log(data);
                    dispatch(setUserPermission(data));
                  }
                  //  console.log(checkedValue);
                }}
                style={{ border: "1px solid #3debd3ab" }}
                defaultValue={v.value === 1 ? true : false}
              />
            );
          }}
        />

        <Column
          dataField="is_create"
          width={130}
          format="fixedPoint"
          caption={"Үүсгэх"}
          cellRender={(v) => {
            //  console.log(v.value);
            // return

            return (
              <CheckBox
                ref={CheckBoxRef}
                onValueChanged={(args) => {
                  if (selectRowData !== null) {
                    // setCheckedValue(1);
                    // console.log(selectedRow);
                    const data = {
                      user_id: props.userData.id,
                      permission_id: selectRowData.id,
                      is_create: args.value ? 1 : 0,
                    };
                    // console.log(data);
                    dispatch(setUserPermission(data));
                  }
                  //  console.log(checkedValue);
                }}
                style={{ border: "1px solid #3debd3ab" }}
                defaultValue={v.value === 1 ? true : false}
              />
            );
          }}
        />
        <Column
          dataField="is_update"
          width={130}
          format="fixedPoint"
          caption={"Засах"}
          cellRender={(v) => {
            //  console.log(v.value);
            // return

            return v.value !== null ? (
              <CheckBox
                ref={CheckBoxRef}
                onValueChanged={(args) => {
                  if (selectRowData !== null) {
                    // setCheckedValue(1);
                    // console.log(selectedRow);
                    const data = {
                      user_id: props.userData.id,
                      permission_id: selectRowData.id,
                      is_update: args.value ? 1 : 0,
                    };
                    // console.log(data);
                    dispatch(setUserPermission(data));
                  }
                  //  console.log(checkedValue);
                }}
                style={{ border: "1px solid #3debd3ab" }}
                defaultValue={v.value === 1 ? true : false}
              />
            ) : (
              ""
            );
          }}
        />
        <Column
          dataField="is_delete"
          width={130}
          format="fixedPoint"
          caption={"Устгах"}
          cellRender={(v) => {
            //  console.log(v.value);
            // return

            return v.value !== null ? (
              <CheckBox
                ref={CheckBoxRef}
                onValueChanged={(args) => {
                  if (selectRowData !== null) {
                    // setCheckedValue(1);
                    // console.log(selectedRow);
                    const data = {
                      user_id: props.userData.id,
                      permission_id: selectRowData.id,
                      is_delete: args.value ? 1 : 0,
                    };
                    // console.log(data);
                    dispatch(setUserPermission(data));
                  }
                  //  console.log(checkedValue);
                }}
                style={{ border: "1px solid #3debd3ab" }}
                defaultValue={v.value === 1 ? true : false}
              />
            ) : (
              ""
            );
          }}
        />
      </TreeList>
    </>
  );
};

export default EmployeModule;
