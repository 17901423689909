import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  LoadPanel,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  getRequestConfirmed,
  requestIsApprovedCancel,
  requestIsApprovedConfirm,
  setFilter,
} from "../../../../store/reducers/requestDriver";
import { DataGridConfig } from "../../../../config";
import { TextBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { requestIsApprovedDriverConfirmCall } from "../../../../utils/services/requestService";
const RequestConfirmedData = (props) => {
  const theme = useTheme();

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);

  const [formEditData, setFormEditData] = useState("");

  const requestConfirmedData = useSelector(
    (state) => state.requestDriver.requestConfirmedData
  );
  const filterConfirmed = useSelector(
    (state) => state.requestDriver.filterConfirmedAdmin
  );
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filterConfirmed, [field]: value }));
  };
  useEffect(() => {
    if (requestConfirmedData == null) {
      // gridRef.current.instance.beginCustomLoading();
      dispatch(getRequestConfirmed(filterConfirmed));
    }
  }, [requestConfirmedData, filterConfirmed, dispatch]);
  useEffect(() => {
    //  console.log(Object.keys(filter).length);
    if (Object.keys(filterConfirmed).length > 0) {
      dispatch(getRequestConfirmed(filterConfirmed));
    }
  }, [filterConfirmed, dispatch]);

  useEffect(() => {
    if (requestConfirmedData) {
      // gridRef.current.instance.endCustomLoading();
      setFormData(requestConfirmedData);
    }
  }, [requestConfirmedData]);

  //console.log(props);
  const gridRef = useRef();
  //const [state, dispatch] = React.useReducer(reducer, initialState);
  //const vehicleDataList = useSelector((state) => state.vehicle.vehicleData);
  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  //   const onSelectionChanged = (e) => {
  //     //const data = selectedRowsData[0];
  //     const data = e.selectedRowsData;
  //     console.log(e.component);
  //     setSelectIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
  //     setIsType(2);
  //     setFormEditData(data[0]);

  //     //console.log(formEditData.ID);
  //   };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    //console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    // setFormEditData("");
  };

  //   const onSaving = (e) => {
  //     console.log(e.changes);
  //     //gridRef.current.instance.saveEditData();
  //   };
  const setStoreVehicle = (data) => {
    // dispatch(editCompany(data));
    // console.log(data);

    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  function deleteRecords() {
    gridRef.current.instance.getSelectedRowsData().forEach((key) => {
      // console.log(key);
      //   dispatch(removeRequestInData(key.id));
    });
    // this.setState({
    //   selectedItemKeys: [],
    // });
    gridRef.current.instance.refresh();
  }

  const vehicleEditSearch = () => {
    setFormEditData({ ID: 777, register: "33333" });
  };

  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    gridRef.current.instance.refresh();
                    console.log(gridRef.current.instance.getSelectedRowsData());
                    // setIsPopUpEdit(true);
                    // console.log(isPopUpEdit);
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={(e) => {
                    if (formEditData !== "") {
                      const data = {
                        is_approved: 0,
                        id: parseInt(formEditData.id),
                        note: "Цуцлав",
                      };
                      dispatch(requestIsApprovedCancel(data));
                      //  console.log(formEditData.id);
                    } else {
                      notify(
                        {
                          message: "Цуцлах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    // console.log(selectIndex);
                    // for (let i = 0; i < selectIndex.length; i++) {
                    //   gridRef.current.instance.deleteRow(selectIndex[i]);
                    // }
                  }}
                  type={"danger"}
                  text="Цуцлах"
                />
              </Box>
            </Item>
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={400}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            onSelectionChanged={onSelectionChanged}
          >
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={false}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "90%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                  <Item dataField="official_letter_number" />
                  <Item dataField="official_letter_date" />
                </Item>
                <Item
                  itemType="group"
                  caption="Жолоочийн мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="surname" />
                  <Item dataField="lastname" />
                  <Item dataField="firstname" />
                  <Item dataField="register" />
                  <Item dataField="gender" />

                  <Item dataField="eye_color_name" />
                  <Item dataField="blood_type_name" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="dl_number" />
                  <Item dataField="dll" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="pdl_number" />
                  <Item dataField="class_b" />
                  <Item dataField="class_c" />
                  <Item dataField="class_e" />
                  <Item dataField="class_d" />
                  <Item dataField="class_inter_passenger" />
                  <Item dataField="class_inter_transport" />
                  <Item dataField="class_tourist" />
                  <Item dataField="class_danger" />
                </Item>
                <Item dataField="created" />
                <Item dataField="approved_note" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                width={130}
                format="fixedPoint"
                caption={"ААНБ-н регистер"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="company_name"
                format="fixedPoint"
                caption={"ААНБ-н нэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
            </Column>
            <Column
              dataField="official_letter_number"
              format="fixedPoint"
              caption={"Албан бичиг дугаар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="official_letter_date"
              format="fixedPoint"
              caption={"Албан бичиг огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />

            {/* <Column
              allowSorting={true}
              width={200}
              dataField="is_checked"
              caption="Байцаагч төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Байцаагч баталгаажуулсан"
                      size="small"
                      color="warning"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Байцаагч татгалзсан" size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            /> */}
            <Column
              allowSorting={true}
              width={120}
              dataField="is_approved"
              caption="Төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Баталгаажуулсан"
                      size="small"
                      color="success"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Баталгаажаагүй" size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            />

            {/* <Column
          dataField="is_approved"
          // width={100}

          allowSorting={true}
          cellRender={cellRender}
        /> */}
            <Column
              dataField="surname"
              format="fixedPoint"
              caption={"Ургийн овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="lastname"
              format="fixedPoint"
              caption={"Овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="firstname"
              format="fixedPoint"
              caption={"Нэр"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="register"
              format="fixedPoint"
              caption={"Регистер"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="gender"
              format="fixedPoint"
              caption={"Хүйс"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="eye_color_name"
              format="fixedPoint"
              caption={"Нүдний өнгө"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />

            <Column
              dataField="blood_type_name"
              format="fixedPoint"
              caption={"Цусны бүлэг"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column caption="Жолооны үнэмлэх">
              <Column
                dataField="dl_number"
                format="fixedPoint"
                caption={"Үнэмлэх №"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="dll"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                //format="fixedPoint"
                caption={"Ангилал"}
                calculateCellValue={(v) => {
                  var data = [];
                  if (v.dl_a !== null) {
                    data.push("A,");
                  }
                  if (v.dl_b !== null) {
                    data.push("B,");
                  }
                  if (v.dl_c !== null) {
                    data.push("C,");
                  }
                  if (v.dl_d !== null) {
                    data.push("D,");
                  }
                  if (v.dl_e !== null) {
                    data.push("E,");
                  }
                  // console.log(data);
                  return data.map((e) => e);
                }}
              />
            </Column>
            <Column position="center" caption="Мэрэгшсэн жолооны үнэмлэх">
              <Column
                dataField="pdl_number"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                //  format="fixedPoint"
                caption={"Үнэмлэх №"}
              />
              <Column
                dataField="class_b"
                //  format="fixedPoint"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                caption={"В хүчинтэй огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_c"
                // format="date"
                caption={"С хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  // console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />

              <Column
                dataField="class_d"
                // format="fixedPoint"
                caption={"D хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_e"
                // format="fixedPoint"
                caption={"E хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_passenger"
                format="fixedPoint"
                caption={"ОУ-н зорчигч огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_transport"
                format="fixedPoint"
                caption={"ОУ-н тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_tourist"
                format="fixedPoint"
                caption={"Жуулчин тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_danger"
                format="fixedPoint"
                caption={"Аюултай тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
            </Column>
            <Column
              dataField="approved_note"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Нэмэлт мэдээлэл"}
            />
            <Column
              dataField="checked"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Баталсан"}
            />
            <Column
              dataField="created"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Хүсэлт илгээсэн"}
            />
            <Column
              dataField="updated_at"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Баталсан огноо"}
              cellRender={(p) => {
                // console.log(p);
                return p.value;
              }}
            />

            {/* <Scrolling rowRenderingMode="virtual" /> */}
            {/* <Scrolling mode="infinite" /> */}
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            {/* <Scrolling rowRenderingMode="virtual"></Scrolling> */}

            {/* <Paging defaultPageSize={10} visible={true} defaultPageIndex={0} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          // displayMode={displayMode}
          showPageSizeSelector={true}
          showInfo={true}
          //displayMode={"compact"}
          infoText="НИЙТ {1} ХУУДАС (НИЙТ {2} БИЧЛЭГ)"
          showNavigationButtons={showNavButtons}
        /> */}
            <LoadPanel enabled={true} />
          </DataGrid>

          {/* <Popup
            visible={isPop}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            position={"center"}
            title={
              isType === 2
                ? "Тээврийн хэрэгсэл засах"
                : "Тээврийн хэрэгсэл бүртгэх"
            }
            showTitle={true}
            width={900}
            //height={600}
          >
            sdfdsfds
          </Popup> */}
        </Box>
      </Box>
    </>
  );
};

export default RequestConfirmedData;
