import { Box, IconButton, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import ReactPlayer from "react-player";
import { border } from "polished";
import { grey } from "@mui/material/colors";
import { FaFilePdf } from "react-icons/fa";
import { Colors } from "../../styles/theme";
import { Document, PDFDownloadLink, Page, Text } from "@react-pdf/renderer";
const FaqFeedback = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const pdfUrl = "../../../file/zaavar.pdf";

  // Function to trigger the download
  const onButtonClick = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  return (
    <Box m="20px">
      <Header title="Санал хүсэлт цэс" subtitle="Feedback Menu" />
      <Box sx={{ textAlign: "right", p: 1 }}>
        <IconButton
          onClick={() => {
            onButtonClick(pdfUrl);
          }}
        >
          <FaFilePdf color={Colors.danger} />
        </IconButton>
        {/* <Typography>
          {" "}

          <FaFilePdf /> татах
        </Typography> */}
      </Box>
      {/* <PDFDownloadLink
        document={
          <Document>
            <Page>
              <Text>Toto</Text>
            </Page>
          </Document>
        }
        fileName={pdfUrl}
      >
        Download
      </PDFDownloadLink> */}

      <Box
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        justifyItems={"center"}
      >
        <ReactPlayer
          style={{
            // border: "1px solid",
            //borderColor: "grey",
            background: "black",
          }}
          light={true}
          controls={true}
          className="react-player"
          url="../../../video/sanal_huselt.mp4"
          width="100%"
          height="500px"
        />
      </Box>
    </Box>
  );
};

export default FaqFeedback;
