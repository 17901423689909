import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";

import { clearSnackbarData } from "../../store/reducers/uiReducer";
import { CheckCircle, Close } from "@mui/icons-material";
import { Alert, IconButton } from "@mui/material";

export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const successSnackbarMessage = useSelector(
    (state) => state.uiReducer.successSnackbarMessage
  );

  const successSnackbarOpen = useSelector(
    (state) => state.uiReducer.successSnackbarOpen
  );
  const errorSnackbarOpen = useSelector(
    (state) => state.uiReducer.errorSnackbarOpen
  );
  const messagePosition = useSelector((state) => state.uiReducer.position);
  // console.log(successSnackbarOpen);
  function handleClose() {
    dispatch(clearSnackbarData());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: messagePosition ? messagePosition : "left",
      }}
      open={successSnackbarOpen || errorSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={successSnackbarOpen ? "success" : "error"}
        color={successSnackbarOpen ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {successSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}
