import axios from "../../api/axiosInctance";

export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};
//const getToken1 = localStorage.getItem("token");
export const companyDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    //console.log(newFilter);
    const getCompanyData = await axios
      .post("/company/list", newFilter)
      .then((res) => {
        //  console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getCompanyData;
  } catch (error) {
    console.log(error);
  }
};
// export const companyTypeDataCall = async () => {
//   try {
//     //console.log(axios);
//     // let newFilter = {};
//     // Object.keys(filter).map((key) => {
//     //   if (filter[key] !== null) {
//     //     newFilter[key] = filter[key];
//     //   }
//     // });
//     // console.log(JSON.stringify(newFilter));
//     const getCompanyData = await axios
//       .post(
//         "/company/type/list"
//         //   , {
//         //     params: {
//         //       pagesize: 1000,
//         //       filter: JSON.stringify(newFilter),
//         //     },
//         //   },
//       )
//       .then((res) => {
//         // console.log(res.data);
//         if (res.data.status.code === 200) {
//           return res.data;
//         }
//       })
//       .catch((err) => {
//         console.log("Aldaaa");
//         let message =
//           typeof err.response !== "undefined"
//             ? err.response.data.message
//             : err.message;
//         console.warn("error", message);
//       })
//       .finally(() => {
//         // console.log("ddd");
//       });
//     return getCompanyData;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const companyRemove = async (companyId) => {
  try {
    //console.log(axios);
    const id = {
      id: companyId,
    };
    const editData = await axios
      .post("/company/delete", id)
      .then((res) => {
        //  console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return editData;
  } catch (error) {
    console.log(error);
  }
};

export const companyEditCall = async (formData) => {
  try {
    //console.log(axios);

    const editData = await axios
      .post("/company/edit", formData)
      .then((res) => {
        //  console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return editData;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const companySearchCall = async ({ plateNo }) => {
  try {
    //console.log(axios);

    const getInspection = await axios
      .post("company/data", plateNo)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log("Aldaaa");
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;
        console.warn("error", message);
        return message;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
