import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";

import UserList from "../../components/user/userList";
import { getUserListData, setFilter } from "../../store/reducers/user/auth";
import FilterBox from "../../components/user/filter";

const User = () => {
  const getUserList = useSelector((state) => state.auth.userListData);
  const isLoad = useSelector((state) => state.auth.isLoading);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const filter = useSelector((state) => state.auth.filter);
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const dispatch = useDispatch();
  const setFilters = (field, value) => {
    //  console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };

  useEffect(() => {
    if (getUserList == null) {
      dispatch(getUserListData(filter));
    }
  }, [getUserList, filter, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getUserListData(filter));
    }
  }, [filter, dispatch]);
  const filterIsCompany = (filterData) => {
    // console.log("-----------");
    // console.log(filterData);
    // console.log("----------");
    if (filterData !== null) {
      setCompanyName(filterData[0]);
      setIsCompanyFilter(true);
    } else {
      setCompanyName(null);
      setIsCompanyFilter(false);
    }

    //const data = selectedRowsData[0];

    // setFilters(filterData);
  };

  return (
    <Box m="20px">
      <Header title="Хэрэглэгч" subtitle="Хэрэглэгчийн жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} />
        </Box>
        <Box sx={{ gridColumn: "span 9" }}>
          <UserList
            userList={getUserList}
            comptData={compName}
            isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default User;
