import {
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  List,
  LoadPanel,
  Lookup,
  SelectBox,
  TextArea,
  TileView,
  Toolbar,
  TreeList,
  TreeView,
} from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { DropDownOptions } from "devextreme-react/autocomplete";

import {
  getUserData,
  getUserTypeModuleDataFun,
  setUser,
  setUserTypePermission,
} from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";
import { GroupItem } from "devextreme-react/form";
import DataGrid, { Column, Label, Selection } from "devextreme-react/data-grid";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { getCompany } from "../../store/reducers/company";
import ArrayStore from "devextreme/data/array_store";
import { DataGridConfig } from "../../config";
import { borderColor } from "polished";
import { getUserTypeList } from "../../store/reducers/metaCrud";
const EmployeeSettings = (props) => {
  const dispatch = useDispatch();
  const [selectedItemKeys, setSelectedItemKeys] = useState("");
  const getUserTypeModuleDataList = useSelector(
    (state) => state.auth.userTypeModuleData
  );
  const getUserTypeListData = useSelector(
    (state) => state.metaCrud.userTypeListData
  );
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [modules, setModules] = useState([]);
  // console.log(getEmployeTreeListData);
  // const getUserSexDataList = useSelector((state) => state.auth.userSexData);
  const [checkedValue, setCheckedValue] = useState(null);
  const [selectRowData, setSelectRowData] = useState(null);
  const [companyVal, setCompanyVal] = useState("");
  const gridRef = useRef();
  const CheckBoxRef = useRef();
  //const [moduleChangeData, setModuleChangeData] = useState({});
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };

  useEffect(() => {
    if (companyVal === "") {
      setCompanyVal(3);
    }
  }, [companyVal, dispatch]);

  useEffect(() => {
    if (getUserTypeModuleDataList === null) {
      const userTypeId = { user_type_id: companyVal };
      dispatch(getUserTypeModuleDataFun(userTypeId));
    }
  }, [getUserTypeModuleDataList, companyVal, dispatch]);
  useEffect(() => {
    if (getUserTypeListData === null) {
      dispatch(getUserTypeList());
    }
  }, [getUserTypeListData, dispatch]);
  useEffect(() => {
    if (getUserTypeModuleDataList !== null) {
      var tempProps = JSON.parse(JSON.stringify(getUserTypeModuleDataList));

      setModules(tempProps);
    }
  }, [getUserTypeModuleDataList, dispatch]);
  const syncSelection = (treeView) => {
    const selectedEmployees = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);
    // console.log(selectedEmployees);
    setSelectedEmployees(selectedEmployees);

    // console.log(selectedEmployees.length > 0 ? selectedEmployees[0].id : "");

    // this.setState(() => ({ selectedEmployees }));
  };

  const expandedRowKeys = [1];
  function onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;

    if (rowData) {
      //  console.log(rowData);
      setSelectRowData(rowData);
    }
  }
  function companyChanged(e) {
    const compData = e.value;
    //console.log(compData);
    const userTypeId = { user_type_id: e.value };
    dispatch(getUserTypeModuleDataFun(userTypeId));
    setCompanyVal(compData);
  }
  // console.log(getUserTypeModuleDataList);
  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
          p: 2,
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Stack direction={"column"}>
            <Toolbar>
              <Item location="before">
                <Typography sx={{ pl: 1 }}>
                  Хэрэглэгчийн төрөл сонгох:
                </Typography>
              </Item>
              <Item location="before">
                <SelectBox
                  style={{ "margin-left": "12px" }}
                  width="250"
                  height={"30"}
                  items={getUserTypeListData}
                  displayExpr="name"
                  valueExpr="id"
                  value={companyVal}
                  placeholder="Хэрэглэгчийн төрөл сонгох"
                  onValueChanged={companyChanged}
                />
              </Item>
            </Toolbar>

            <TreeList
              ref={gridRef}
              id="employees"
              dataSource={getUserTypeModuleDataList}
              // defaultExpandedRowKeys={expandedRowKeys}
              focusedRowEnabled={true}
              autoExpandAll={true}
              showRowLines={true}
              showBorders={true}
              columnAutoWidth={true}
              itemsExpr="child"
              dataStructure="tree"
              onFocusedRowChanged={onFocusedRowChanged}
              keyExpr="id"
            >
              {/* <Selection mode="single" /> */}
              <Column dataField="name" width={500} caption="Модуль нэр" />
              <Column dataField="code" width={200} caption="Модуль код" />
              {/* <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        /> */}
              <Column
                dataField="is_master"
                //format="fixedPoint"
                caption={"Мастер"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  // return

                  return (
                    <CheckBox
                      ref={CheckBoxRef}
                      onValueChanged={(args) => {
                        if (selectRowData !== null) {
                          // setCheckedValue(1);
                          // console.log(selectedRow);
                          const data = {
                            user_type_id: companyVal,
                            permission_id: selectRowData.id,
                            is_master: args.value ? 1 : 0,
                          };
                          console.log(data);
                          dispatch(setUserTypePermission(data));
                        }
                        //  console.log(checkedValue);
                      }}
                      style={{ border: "1px solid #3debd3ab" }}
                      defaultValue={v.value === 1 ? true : false}
                    />
                  );
                }}
              />
              <Column
                dataField="is_read"
                //format="fixedPoint"
                caption={"Харах"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  // return

                  return v.value !== null ? (
                    <CheckBox
                      ref={CheckBoxRef}
                      onValueChanged={(args) => {
                        if (selectRowData !== null) {
                          // setCheckedValue(1);
                          // console.log(selectedRow);
                          const data = {
                            user_type_id: companyVal,
                            permission_id: selectRowData.id,
                            is_read: args.value ? 1 : 0,
                          };
                          // console.log(data);
                          dispatch(setUserTypePermission(data));

                          dispatch(setUser(null));
                          dispatch(getUserData());
                        }
                        //  console.log(checkedValue);
                      }}
                      style={{ border: "1px solid #3debd3ab" }}
                      defaultValue={v.value === 1 ? true : false}
                    />
                  ) : (
                    ""
                  );
                }}
              />

              <Column
                dataField="is_create"
                format="fixedPoint"
                caption={"Үүсгэх"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  // return

                  return v.value !== null ? (
                    <CheckBox
                      ref={CheckBoxRef}
                      onValueChanged={(args) => {
                        if (selectRowData !== null) {
                          // setCheckedValue(1);
                          // console.log(selectedRow);
                          const data = {
                            user_type_id: companyVal,
                            permission_id: selectRowData.id,
                            is_create: args.value ? 1 : 0,
                          };
                          // console.log(data);
                          dispatch(setUserTypePermission(data));
                        }
                        //  console.log(checkedValue);
                      }}
                      style={{ border: "1px solid #3debd3ab" }}
                      defaultValue={v.value === 1 ? true : false}
                    />
                  ) : (
                    ""
                  );
                }}
              />
              <Column
                dataField="is_update"
                format="fixedPoint"
                caption={"Засах"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  // return

                  return v.value !== null ? (
                    <CheckBox
                      ref={CheckBoxRef}
                      onValueChanged={(args) => {
                        if (selectRowData !== null) {
                          // setCheckedValue(1);
                          // console.log(selectedRow);
                          const data = {
                            user_type_id: companyVal,
                            permission_id: selectRowData.id,
                            is_update: args.value ? 1 : 0,
                          };
                          // console.log(data);
                          dispatch(setUserTypePermission(data));
                        }
                        //  console.log(checkedValue);
                      }}
                      style={{ border: "1px solid #3debd3ab" }}
                      defaultValue={v.value === 1 ? true : false}
                    />
                  ) : (
                    ""
                  );
                }}
              />
              <Column
                dataField="is_delete"
                caption={"Устгах"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  // return

                  return v.value !== null ? (
                    <CheckBox
                      ref={CheckBoxRef}
                      onValueChanged={(args) => {
                        if (selectRowData !== null) {
                          // setCheckedValue(1);
                          // console.log(selectedRow);
                          const data = {
                            user_type_id: companyVal,
                            permission_id: selectRowData.id,
                            is_delete: args.value ? 1 : 0,
                          };
                          // console.log(data);
                          dispatch(setUserTypePermission(data));
                        }
                        //  console.log(checkedValue);
                      }}
                      style={{ border: "1px solid #3debd3ab" }}
                      defaultValue={v.value === 1 ? true : false}
                    />
                  ) : (
                    ""
                  );
                }}
              />
            </TreeList>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default EmployeeSettings;
