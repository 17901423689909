import axios from "../../api/axiosInctance";
import { guest } from "../../store/reducers/user/auth";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const violationDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    // console.log(newFilter);
    const getResponseData = await axios
      .post("/violation/list", newFilter)
      .then((res) => {
        //  console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");

        console.warn("error", err.response.data.status.message);
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getResponseData;
  } catch (error) {
    console.log(error);
  }
};
export const violationEditCall = async (data) => {
  try {
    const getResponseData = await axios
      .post("/violation/edit", data)
      .then((res) => {
        // console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");

        console.warn("error", err.response.data.status.message);
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getResponseData;
  } catch (error) {
    console.log(error);
  }
};
export const violationDeletetCall = async (data) => {
  try {
    const getResponseData = await axios
      .post("/violation/delete", data)
      .then((res) => {
        // console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");

        console.warn("error", err.response.data.status.message);
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getResponseData;
  } catch (error) {
    console.log(error);
  }
};
