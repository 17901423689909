import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";

import { getViolationDetectionReportFun } from "../../store/reducers/report";
import ViolationDetectionReportList from "../../components/reports/violation_detection_report/violation_detection_reportt_list";

const ViolationDetectionReport = () => {
  const filter = useSelector((state) => state.report.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const dispatch = useDispatch();

  return (
    <Box m="20px">
      <Header
        title="Зөрчлийн илрүүлэлт буюу мэдээллийн эх сурвалж тайлан"
        subtitle="Зөрчлийн илрүүлэлт буюу мэдээллийн эх сурвалж тайлан жагсаалт"
      />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <ViolationDetectionReportList
          //  porkurorReportListData={getViolationDetectionReportData}
          // comptData={compName}
          //  isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ViolationDetectionReport;
