import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../Header";
import { SelectBox } from "devextreme-react/select-box";
import { NumberBox } from "devextreme-react/number-box";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import { employeesList, employeesTasks } from "./customers";
import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../store/reducers/company";
import { Item, GroupItem } from "devextreme-react/form";
import DataGrid, {
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { DropDownBox } from "devextreme-react";
import { getCompanyTypeData } from "../../store/reducers/metaCrud";
const FilterBox = (props) => {
  // const getDisplayExpr = (item) => {
  //   return item ? `${item.name}` : "";
  // };
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const dispatch = useDispatch();
  const getCompanyList = useSelector((state) => state.company.companyData);
  const getCompanyTypeList = useSelector(
    (state) => state.metaCrud.companyTypeData
  );
  const filter = useSelector((state) => state.company.filter);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formData, setFormData] = useState([]);
  const [formDataType, setFormDataType] = useState([]);
  const logedUser = useSelector((state) => state.auth.user);

  const [compTypeGridBoxValue, setCompTypeGridBoxValue] = useState(0);
  const [compNameGridBoxValue, setCompNameGridBoxValue] = useState(0);
  const [isGridCompTypeBoxOpened, setIsGridCompTypeBoxOpened] = useState(false);
  const [isGridCompNameBoxOpened, setIsGridCompNameBoxOpened] = useState(false);
  const [user, setUser] = useState("");
  const gridColumns = ["name", "created_at"];

  useEffect(() => {
    if (getCompanyList == null) {
      dispatch(getCompany(filter));
    } else {
      setFormData(getCompanyList);
    }
  }, [getCompanyList, dispatch]);
  useEffect(() => {
    if (getCompanyTypeList == null) {
      dispatch(getCompanyTypeData());
    } else {
      setFormDataType(getCompanyTypeList);
    }
  }, [getCompanyTypeList, dispatch]);
  const dataCompTypeGridRender = () => {
    return (
      <DataGrid
        dataSource={formDataType}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={parseInt(compTypeGridBoxValue)}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowKeys);
          // if (e.selectedRowKeys !== null) {

          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          selValue[0] =
            selValue[0] !== null &&
            selValue[0] !== undefined &&
            selValue[0] !== ""
              ? parseInt(selValue[0])
              : null;
          setCompTypeGridBoxValue(parseInt(selValue[0]));

          // } else {
          //   setCompTypeGridBoxValue(null);
          // }

          // console.log(selValue[0]);
          setIsGridCompTypeBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={formData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={compNameGridBoxValue}
        onSelectionChanged={(e) => {
          props.filterCompany(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          setCompNameGridBoxValue(parseInt(selValue[0]));
          //  console.log(selValue[0]);
          setIsGridCompNameBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };

  return (
    <Box sx={{ p: 2 }} className="options">
      <div className="caption">Шүүлт</div>
      <Box
        display={
          logedUser !== null
            ? logedUser.role !== "user"
              ? "display"
              : "none"
            : "display"
        }
        className="option"
      >
        <div>ААНБ-н нэр</div>
        <DropDownBox
          value={compNameGridBoxValue}
          opened={isGridCompNameBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="ААНБ-н нэр..."
          showClearButton={true}
          dataSource={formData}
          onValueChanged={(e) => {
            setCompNameGridBoxValue(e.value);
            props.getFilters("company_id", e.value);
            if (e.value === null) {
              props.filterCompany(null);
            }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridCompNameBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataCompNameGridRender}
        />

        {/* <div>Компани төрөл</div>

        <DropDownBox
          value={compTypeGridBoxValue}
          opened={isGridCompTypeBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Компани төрөл..."
          showClearButton={true}
          dataSource={formDataType}
          onValueChanged={(e) => {
            setCompTypeGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("service_type_id", e.value);
          }}
          onOptionChanged={(e) => {
            if (e.name === "opened") {
              setIsGridCompTypeBoxOpened(e.value);
            }
          }}
          contentRender={dataCompTypeGridRender}
        /> */}
      </Box>

      <div className="option">
        <div>Үүсгэсэн огноо</div>

        <Stack direction={"row"} spacing={1}>
          <DateBox
            showClearButton={true}
            defaultValue={startDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              console.log(e.value);
              props.getFilters("start_date", e.value);
            }}
            type="date"
          />
          <HorizontalRule sx={{ fontSize: "14px", mt: 3 }} />
          <DateBox
            showClearButton={true}
            defaultValue={endDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              console.log(e.value);
              props.getFilters("end_date", e.value);
            }}
            type="date"
          />
        </Stack>
      </div>
      <div className="option">
        <div>Хайлт</div>

        <TextBox
          // ref={this.CarTextRef}
          placeholder="Хайлт...."
          showClearButton={true}
          onValueChanged={(e) => {
            // console.log([...e.value].length);
            if ([...e.value].length > 0) {
              props.getFilters("search_value", e.value);
            } else {
              props.getFilters("search_value", null);
            }
          }}
          onKeyUp={(e) => {
            if (e.event.keyCode === 13) {
              e.event.preventDefault();
              //  console.log(e.event.target.value);
            }
            // console.log(e.event.target.value);
          }}
        />
      </div>
    </Box>
  );
};

export default FilterBox;
