//import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { mockPieData as data } from "../data/mockData";
import { Box } from "devextreme-react";
import PieChart2, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Tooltip,
} from "devextreme-react/pie-chart";
import { areas, dataSource } from "../data/chartDataPie";
import { useEffect, useState } from "react";
import { Legend } from "devextreme-react/chart";
const DriverPieWomenChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  function customizeText(arg) {
    return `${arg.valueText} (${arg.percentText})`;
  }
  useEffect(() => {
    if (props != null) {
      setData(props.dashboardData);
    }
  }, [props]);
  function customizeTooltip(arg) {
    return {
      text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
    };
  }
  // console.log(data);
  return (
    <PieChart2
      id="pie"
      dataSource={data != null ? data.driver.female.age : []}
      palette="Bright"
      //type="doughnut"
      style={{
        height: "340px",
        margin: "1px",
      }}
      //title="Area of Countries"
      //onPointClick={this.pointClickHandler}
      // onLegendClick={this.legendClickHandler}
    >
      <Series argumentField="title" valueField="value">
        <Label visible={true}>
          <Connector visible={true} width={1} />
        </Label>
      </Series>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="right"
        rowCount={2}
      />
      <Size width={"50%"} />
      <Export enabled={false} />
      <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
    </PieChart2>
  );
};
export default DriverPieWomenChart;
