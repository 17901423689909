import { Box, Button, TextField, Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../../store/reducers/company";
import { DropDownBox } from "devextreme-react";
import { getFeedBackTypeList } from "../../../store/reducers/feedBack";
import { setFilter } from "../../../store/reducers/report";
import { getviolationServiceTypeCrudFun } from "../../../store/reducers/metaCrud";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };

  const violationServiceTypeCrudList = useSelector(
    (state) => state.metaCrud.violationServiceTypeCrud
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const logedUser = useSelector((state) => state.auth.user);
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState("");
  const [serviceTypeGridBoxValue, setServiceTypeGridBoxValue] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isGridServiceTypeBoxOpened, setIsGridServiceTypeBoxOpened] =
    useState(false);

  const [serviceTypeData, setServiceTypeData] = useState(null);

  const [typeDate, setTypeDate] = useState([
    {
      id: "year",
      name: "Оноор",
    },
    { id: "month", name: "Сараар" },
    { id: "day", name: "Өдрөөр" },
  ]);
  const gridColumns = ["name"];
  const filter = useSelector((state) => state.report.filter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (violationServiceTypeCrudList === null) {
      dispatch(getviolationServiceTypeCrudFun());
    } else {
      setServiceTypeData(violationServiceTypeCrudList);
    }
  }, [violationServiceTypeCrudList, dispatch]);
  // useEffect(() => {
  //   if (typeDateGridBoxValue === "") {
  //     setTypeDateGridBoxValue("month");
  //   }
  // }, [typeDateGridBoxValue]);

  // console.log(feedBackType);
  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={typeDate}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  const dataServiceTypeGridRender = () => {
    return (
      <DataGrid
        dataSource={serviceTypeData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={serviceTypeGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setServiceTypeGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridServiceTypeBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  return (
    <>
      <div style={{ margin: "10px" }} className="caption">
        Шүүлт
      </div>
      <Stack direction={"row"} spacing={1}>
        <Typography>Төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          value={typeDateGridBoxValue}
          opened={isGridBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Төрөл сонгох..."
          showClearButton={false}
          dataSource={typeDate}
          onValueChanged={(e) => {
            setTypeDateGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("date_type", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataCompNameGridRender}
        />
        <Typography>Үйлчилгээний төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          value={serviceTypeGridBoxValue}
          opened={isGridServiceTypeBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Төрөл сонгох..."
          showClearButton={true}
          dataSource={serviceTypeData}
          onValueChanged={(e) => {
            setServiceTypeGridBoxValue(e.value);
            //  console.log(e.value);
            props.getFilters("service_type_id", e.value);
            // if (e.value === null) {
            //   props.getFilters(null);
            // }
            // console.log(e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridServiceTypeBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataServiceTypeGridRender}
        />
        <Typography>Эхлэх:</Typography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          spacing={1}
          sx={{ mt: 1 }}
        >
          <DateBox
            height={30}
            defaultValue={startDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              //  console.log(e.value);
              props.getFilters("start_date", e.value);
            }}
            type="date"
          />
          <Typography>Дуусах:</Typography>
          <DateBox
            height={30}
            defaultValue={endDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              props.getFilters("end_date", e.value);
            }}
            type="date"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default FilterBox;
