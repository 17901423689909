import { Box, useTheme, Button as Btn } from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  LoadPanel,
  Texts,
  Editing,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";

import "./style.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGridConfig } from "../../config";
import UserEdit from "./userEdit";
import UserAdd from "./userAdd";
import { useDispatch } from "react-redux";
import { editUser } from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";

const UserList = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [displayMode, setDisplayMode] = useState("full");
  const [showPageSizeSelector, setShowPageSizeSelector] = useState(true);
  const [showInfo, setShowInfo] = useState("full");
  const [showNavButtons, setShowNavButtons] = useState("full");
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);

  const [formData, setFormData] = useState([]);
  const [testVal, setTestVal] = useState("test");
  const [formEditData, setFormEditData] = useState("");
  // console.log(props);
  const dispatch = useDispatch();
  useEffect(() => {
    gridRef.current.instance.beginCustomLoading();
    if (props.userList) {
      setFormData(props.userList);
      gridRef.current.instance.endCustomLoading();
    }
  }, [props]);

  const gridRef = useRef();
  const setStoreUser = (data) => {
    // dispatch(editCompany(data));
    // console.log(data);
    //window.location = "/employe";
    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowKeys);
    //console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };
  const hideInfo = () => {
    setIsPop(false);
    window.location = "/employe";
    // setFormEditData("");
  };

  //   const onSaving = (e) => {
  //     console.log(e.changes);
  //     //gridRef.current.instance.saveEditData();
  //   };
  function deleteRecords() {
    selectIndex.forEach((key) => {
      // console.log(key);
      //gridRef.current.instance.dataSource().store().remove(key);
      gridRef.current.instance.deleteRow(key);
    });
    // this.setState({
    //   selectedItemKeys: [],
    // });
    // gridRef.reload();
  }
  function validateRemove(e) {
    // console.log(e.key);
    const isCanceled = new Promise((resolve, reject) => {
      //console.log(e.key);
      const getSelectedData = gridRef.current.instance.getSelectedRowsData();
      if (getSelectedData.length > 0) {
        getSelectedData.forEach((key) => {
          console.log(key.id);
        });
      }
      //console.log(gridRef.current.instance.getSelectedRowsData());
      //   console.log(e.data);
      //   console.log(e.data.ID);
      resolve(true);
      // fetch(`https://url/to/your/validation/service/${e.key}`)
      //     .then((validationResult) => {
      //         if (validationResult.errorText) {
      //             reject(validationResult.errorText);
      //         } else {
      //             resolve(false);
      //         }
      //     });
    });
    e.cancel = isCanceled;
  }

  //   function updateRow(e) {
  //     const isCanceled = new Promise((resolve, reject) => {
  //       const promptPromise = confirm(
  //         "Та энэ үйлдлийг хййх үү?",
  //         "Тээврийн хэрэгсэл засах"
  //       );
  //       promptPromise.then((dialogResult) => {
  //         if (dialogResult) {
  //           console.log(dialogResult);
  //           console.log(e.oldData.ID);
  //           // gridRef.current.instance.cellValue(selectIndex, 2, e.newData);
  //           // gridRef.current.instance.saveEditData();
  //           console.log(e.newData);

  //           resolve(true);
  //           // let params = new HttpParams();
  //           //   for (let key in e.newData) {
  //           //     params = params.set(key, e.newData[key]);
  //           //   }
  //           //   fetch(`https://url/to/your/validation/service${params}`).then(
  //           //     (validationResult) => {
  //           //       if (validationResult.errorText) {
  //           //         reject(validationResult.errorText);
  //           //       } else {
  //           //         resolve(false);
  //           //       }
  //           //     }
  //           //   );
  //         } else {
  //           return resolve(true);
  //         }
  //       });
  //     });
  //     e.cancel = isCanceled;
  //   }
  const editUserFun = (data) => {
    dispatch(editUser(data));
    // console.log(data);

    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  const vehicleEditSearch = () => {
    setFormEditData({ ID: 777, register: "33333" });
  };

  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                gridRef.current.instance.refresh();
                console.log(gridRef.current.instance.getSelectedRowsData());
                // setIsPopUpEdit(true);
                // console.log(isPopUpEdit);
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              icon={"add"}
              onClick={() => {
                // console.log(props.isCompanyFilter);
                // if (logedUser.role !== "user") {
                if (props.isCompanyFilter > 0) {
                  setIsType(1);
                  setIsPop(true);
                } else {
                  notify(
                    {
                      message: "Аж ахуй нэгж сонгон уу!!!",
                      width: 450,
                    },
                    "error"
                  );
                }
                // } else {
                // setIsType(1);
                // setIsPop(true);
                // }

                //const dataSource = gridRef.current.instance.getDataSource();
                //   dataSource.filter(["register", ">", 18]);
                //   dataSource.load();
                // const store = dataSource.store();
                //  console.log(store);

                // setFormData([
                //   ...formData,
                //   {
                //     ID: 32,
                //     CompanyName: "test",
                //     Address: "test",
                //     City: "test",
                //     register: "test",
                //   },
                // ]);

                // console.log(...formData);
                // gridRef.current.instance.addRow();
                // gridRef.current.instance.deselectAll();
              }}
              type={"default"}
              text="Шинэ"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              icon={"edit"}
              onClick={() => {
                // if (selectIndex > -1) {
                //   gridRef.current.instance.editRow(selectIndex);
                // } else {
                //   console.log("ffff");
                // }
                const getSelectedData =
                  gridRef.current.instance.getSelectedRowsData();
                // console.log(getSelectedData.length);
                if (getSelectedData.length > 0 && getSelectedData.length < 2) {
                  if (formEditData === "" && isType === 1) {
                    alert("dfgdfg");
                  } else {
                    setIsType(2);
                    setIsPop(true);
                    console.log(isPop);
                  }
                } else {
                  alert("songonuu mur");
                }

                //gridRef.current.instance.instance.deselectAll();
              }}
              type={"success"}
              text="Засах"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              icon={"remove"}
              onClick={(e) => {
                // console.log(e.row);
                // console.log(selectIndex);
                // for (let i = 0; i < selectIndex.length; i++) {
                //   gridRef.current.instance.deleteRow(selectIndex[i]);
                // }
                gridRef.current.instance.deleteRow(selectIndex);
                //gridRef.current.instance.deselectAll();
              }}
              type={"danger"}
              text="Устгах"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box>
            {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        // showRowLines={true}
        remoteOperations={true}
        dataSource={formData}
        keyExpr="id"
        height={420}
        onRowDblClick={(row) => {
          if (isType === 2 && formEditData !== "") {
            setFormEditData(row.data);
            setIsPop(true);
          } else {
            alert("no");
          }
        }}
        onSelectionChanged={onSelectionChanged}
      >
        <Editing
          mode="popup"
          //  changes={changes}
          //  onChangesChange={setChanges}
        >
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Хэрэглэгч устгах"}
            saveRowChanges={"Хадгалах"}
            cancelRowChanges={"Цуцлах"}
          />
        </Editing>
        <Selection mode="multiple" />

        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        />

        <Column caption="Байгуулллага" fixed={true}>
          <Column
            dataField="register"
            width={130}
            format="fixedPoint"
            caption={"ААНБ-н регистер"}
          />
          <Column
            dataField="company_name"
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          />
        </Column>

        <Column caption="Хувийн мэдэээлэл">
          <Column dataField="lastname" format="fixedPoint" caption={"Овог"} />
          <Column dataField="firstname" format="fixedPoint" caption={"Нэр"} />
          <Column dataField="regnum" format="fixedPoint" caption={"Регистер"} />
          <Column
            dataField="branch_name"
            format="fixedPoint"
            caption={"Салбарын нэр"}
          />
          <Column
            dataField="job_title"
            format="fixedPoint"
            caption={"Албан тушаал"}
          />
          <Column
            dataField="user_type_name"
            format="fixedPoint"
            caption={"Хэрэгдэгчийн төрөл"}
          />
        </Column>

        <Column
          dataField="created_at"
          format="fixedPoint"
          caption={"Бүртгэсэн огноо"}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />
      </DataGrid>

      <Popup
        visible={isPop}
        onHiding={hideInfo}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        position={"center"}
        title={
          isType === 2 ? "Хэрэглэгчийн мэдээлэл засах" : "Хэрэглэгчийн бүртгэх"
        }
        showTitle={true}
        width={900}
        height={650}
      >
        {/* {formEditData ? formEditData.ID : "fff"} */}
        {isType === 1 ? (
          <UserAdd comptData={props.comptData} storeUser={setStoreUser} />
        ) : (
          <UserEdit
            userEditData={formEditData}
            userUpdate={editUserFun}
            searchVehBtn={vehicleEditSearch}
          />
        )}
      </Popup>
    </>
  );
};

export default UserList;
